/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CheckoutPayments from 'Component/CheckoutPayments';
import Popup from 'Component/Popup';
import { Addresstype } from 'Type/Account.type';
import { PaymentMethodsType } from 'Type/Checkout.type';

import { GIFT_CARD_PAYMENT_POPUP } from './GiftCardPaymentPopup.config';

import './GiftCardPaymentPopup.style';

/** @namespace Scandiweb/AmastyGiftCards/Component/GiftCardPaymentPopup/Component */
export class GiftCardPaymentPopupComponent extends PureComponent {
    static propTypes = {
        paymentMethods: PaymentMethodsType.isRequired,
        onPaymentMethodSelect: PropTypes.func.isRequired,
        shippingAddress: Addresstype.isRequired,
        onButtonClick: PropTypes.func.isRequired
    };

    state = {
        isOrderButtonEnabled: false
    };

    setOrderButtonEnableStatus = this._setOrderButtonEnableStatus.bind(this);

    _setOrderButtonEnableStatus() {
        this.setState({ isOrderButtonEnabled: true });
    }

    renderActions() {
        const { onButtonClick } = this.props;
        const { isOrderButtonEnabled } = this.state;

        return (
            <div block="GiftCardPaymentPopup" elem="Actions">
                <button
                  type="button"
                  block="Button"
                  disabled={ !isOrderButtonEnabled }
                  onClick={ onButtonClick }
                  mix={ { block: 'GiftCardPaymentPopup', elem: 'Button' } }
                >
                    { __('Complete order') }
                </button>
            </div>
        );
    }

    render() {
        const {
            paymentMethods,
            onPaymentMethodSelect,
            shippingAddress
        } = this.props;

        return (
            <Popup
              id={ GIFT_CARD_PAYMENT_POPUP }
              mix={ { block: 'GiftCardPaymentPopup' } }
            >
                <CheckoutPayments
                  paymentMethods={ paymentMethods }
                  onPaymentMethodSelect={ onPaymentMethodSelect }
                  billingAddress={ shippingAddress }
                  setOrderButtonEnableStatus={ this.setOrderButtonEnableStatus }
                />
                { this.renderActions() }
            </Popup>
        );
    }
}

export default GiftCardPaymentPopupComponent;
