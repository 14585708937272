/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

const addGiftAmountField = (args, callback) => ([
    ...callback(...args),
    'giftAmount',
    'giftCodes'
]
);

export default {
    'Query/Order/Query': {
        'member-function': {
            _getOrderItemTotalFields: addGiftAmountField
        }
    }
};
