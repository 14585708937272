/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'Util/Store';

import {
    GTM_EVENT_KEY_ADD_PAYMENT_INFO,
    GTM_EVENT_KEY_ADD_SHIPPING_INFO,
    GTM_EVENT_KEY_ADD_TO_CART,
    GTM_EVENT_KEY_ADD_TO_WISHLIST,
    GTM_EVENT_KEY_BEGIN_CHECKOUT,
    GTM_EVENT_KEY_FILTER_SELECT,
    GTM_EVENT_KEY_GENERAL,
    GTM_EVENT_KEY_GENERATE_LEAD,
    GTM_EVENT_KEY_LOGIN,
    GTM_EVENT_KEY_PURCHASE,
    GTM_EVENT_KEY_REMOVE_FROM_CART,
    GTM_EVENT_KEY_SEARCH,
    GTM_EVENT_KEY_SELECT_ITEM,
    GTM_EVENT_KEY_SELECT_PROMOTION,
    GTM_EVENT_KEY_SIGN_UP,
    GTM_EVENT_KEY_SORT_SELECT,
    GTM_EVENT_KEY_VIEW_CART,
    GTM_EVENT_KEY_VIEW_ITEM,
    GTM_EVENT_KEY_VIEW_ITEM_LIST,
    GTM_EVENT_KEY_VIEW_PROMOTION
} from './events';

/** @namespace Scandiweb/Gtm/Util/CheckIsEventEnable/checkIsEventEnable */
export const checkIsEventEnable = (event) => {
    const {
        events: {
            gtm_general_init: isGeneralInitEnable,
            gtm_view_item_list: isViewItemListEnable,
            gtm_select_item: isSelectItemEnable,
            gtm_view_item: isViewItemEnable,
            gtm_add_to_wishlist: isAddToWishlistEnable,
            gtm_add_to_cart: isAddToCartEnable,
            gtm_remove_from_cart: isRemoveFromCartEnable,
            gtm_view_cart: isViewCartEnable,
            gtm_begin_checkout: isBeginCheckoutEnable,
            gtm_add_shipping_info: isAddShippingInfoEnable,
            gtm_add_payment_info: isAddPaymentInfoEnable,
            gtm_purchase: isPurchaseEnable,
            gtm_search: isSearchEnable,
            gtm_view_promotion: isViewPromotionEnable,
            gtm_select_promotion: isSelectPromotionEnable,
            gtm_login: isLoginEnable,
            gtm_sign_up: isSignUpEnable,
            gtm_generate_lead: isGenerateLeadEnable,
            gtm_filter_select: isFilterSelectEnable,
            gtm_sort_select: isSortSelectEnable
        } = {}
        // vvv These values are injected using GTM
    } = getStore().getState().ConfigReducer.gtm;
    // * ^^^ It's only being used where ConfigReducer is updated, no need to wait for it
    const eventsMap = {
        [GTM_EVENT_KEY_GENERAL]: isGeneralInitEnable,
        [GTM_EVENT_KEY_VIEW_ITEM_LIST]: isViewItemListEnable,
        [GTM_EVENT_KEY_SELECT_ITEM]: isSelectItemEnable,
        [GTM_EVENT_KEY_VIEW_ITEM]: isViewItemEnable,
        [GTM_EVENT_KEY_ADD_TO_WISHLIST]: isAddToWishlistEnable,
        [GTM_EVENT_KEY_ADD_TO_CART]: isAddToCartEnable,
        [GTM_EVENT_KEY_REMOVE_FROM_CART]: isRemoveFromCartEnable,
        [GTM_EVENT_KEY_VIEW_CART]: isViewCartEnable,
        [GTM_EVENT_KEY_BEGIN_CHECKOUT]: isBeginCheckoutEnable,
        [GTM_EVENT_KEY_ADD_SHIPPING_INFO]: isAddShippingInfoEnable,
        [GTM_EVENT_KEY_ADD_PAYMENT_INFO]: isAddPaymentInfoEnable,
        [GTM_EVENT_KEY_PURCHASE]: isPurchaseEnable,
        [GTM_EVENT_KEY_SEARCH]: isSearchEnable,
        [GTM_EVENT_KEY_VIEW_PROMOTION]: isViewPromotionEnable,
        [GTM_EVENT_KEY_SELECT_PROMOTION]: isSelectPromotionEnable,
        [GTM_EVENT_KEY_LOGIN]: isLoginEnable,
        [GTM_EVENT_KEY_SIGN_UP]: isSignUpEnable,
        [GTM_EVENT_KEY_GENERATE_LEAD]: isGenerateLeadEnable,
        [GTM_EVENT_KEY_FILTER_SELECT]: isFilterSelectEnable,
        [GTM_EVENT_KEY_SORT_SELECT]: isSortSelectEnable
    };

    if ((typeof eventsMap[event]) === 'undefined') {
        return true;
    }
    // ^^^ In case the event is undefined we assume we need to push it.
    // e.g: gtm.js, gtm.load, gtm.dom

    return eventsMap[event];
};
