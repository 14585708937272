/**
 * Mageplaza Affiliate compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { SET_IS_AFFILIATE_CONFIG_LOADING, UPDATE_AFFILIATE_CONFIG } from './MyAffiliate.action';

/** @namespace Scandiweb/MageplazaAffiliate/Store/MyAffiliate/Reducer/getInitialState */
export const getInitialState = () => ({
    affiliateConfig: {},
    isAffiliateConfigLoading: false
});

/** @namespace Scandiweb/MageplazaAffiliate/Store/MyAffiliate/Reducer/MyAffiliateReducer */
export const MyAffiliateReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATE_AFFILIATE_CONFIG:
        const { affiliateConfig } = action;

        return {
            ...state,
            affiliateConfig
        };
    case SET_IS_AFFILIATE_CONFIG_LOADING:
        const { isLoading } = action;

        return {
            ...state,
            isAffiliateConfigLoading: isLoading
        };
    default:
        return state;
    }
};

export default MyAffiliateReducer;
