/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation } from 'Util/Request';

import RemoveGiftCardMutation from '../../query/RemoveGiftCard.mutation';
import AppliedGiftCards from './AppliedGiftCards.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Scandiweb/AmastyGiftCards/Component/AppliedGiftCards/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Scandiweb/AmastyGiftCards/Component/AppliedGiftCards/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateCart: async () => {
        CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
        );
    },
    showNotification: (status, message) => dispatch(showNotification(status, message))
});

/** @namespace Scandiweb/AmastyGiftCards/Component/AppliedGiftCards/Container */
export class AppliedGiftCardsContainer extends PureComponent {
    static propTypes = {
        updateCart: PropTypes.func.isRequired,
        code: PropTypes.string.isRequired,
        showNotification: PropTypes.func.isRequired,
        cartId: PropTypes.string.isRequired
    };

    containerFunctions = {
        handleRemove: this.handleRemove.bind(this)
    };

    containerProps() {
        const { code, cartId } = this.props;

        return { code, cartId };
    }

    async handleRemove() {
        const {
            updateCart,
            showNotification,
            code,
            cartId
        } = this.props;

        if (!cartId) {
            return;
        }

        const {
            removeGiftCard: {
                error,
                message
            }
        } = await fetchMutation(RemoveGiftCardMutation.removeGiftCard(code, cartId));

        if (!error) {
            updateCart();
            showNotification('success', message);

            return;
        }

        showNotification('error', message);
    }

    render() {
        return (
            <AppliedGiftCards
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapDispatchToProps, mapDispatchToProps)(AppliedGiftCardsContainer);
