import SourcePopup from 'SourceComponent/Popup/Popup.component';

/** @namespace Spinola/BaseTheme/Component/Popup/Component */
export class PopupComponent extends SourcePopup {
    /**
     * override to remove history.goBack()
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    hidePopupAndGoBack() {
        this.hidePopUp();
    }
}

export default PopupComponent;
