/**
 * Mageplaza Affiliate compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/MageplazaAffiliate/Query/MyAffiliate/Query */
export class MyAffiliateQuery {
    queryGetConfig() {
        return new Field('affiliateGetConfig')
            .setAlias('affiliateConfig')
            .addFieldList(this.getConfigFields());
    }

    getConfigFields() {
        return [
            'isSignedUp',
            'customerReferBy',
            'isEnableTermsAndConditions',
            'termsAndConditionsTitle',
            'termsAndConditionsHtml',
            'isCheckedEmailNotification',
            'termsAndConditionsCheckboxText',
            'defaultPage',
            'codeLength',
            'isActiveAffiliateAccount',
            'couponPrefix',
            'referringPage',
            'sharingUrl',
            'sharingParam',
            'sharingEmail',
            'sharingCode',
            'defaultEmailSubject',
            'emailContent',
            'availableBalance',
            'emailNotification',
            'isDiscountCodeFieldVisible',
            'affiliateEnableBanner',
            'isEnableReferHistory'
        ];
    }

    queryGetCampaignData() {
        return new Field('affiliateGetCampaignData')
            .setAlias('affiliateCampaignData')
            .addFieldList([
                'hasCouponCode',
                'couponPrefix',
                new Field('campaigns').addFieldList(
                    [
                        'commission',
                        'couponCode',
                        'description',
                        'discountDescription',
                        'fromDate',
                        'name',
                        'toDate'
                    ]
                )
            ]);
    }

    queryGetTransactionData(page = 1) {
        return new Field('affiliateGetTransactionData')
            .setAlias('affiliateTransactionData')
            .addArgument('page', 'Int', page)
            .addFieldList([
                'availableBalance',
                'holdingBalance',
                'totalPages',
                new Field('transactions').addFieldList(
                    [
                        'number',
                        'title',
                        'amount',
                        'customerOrdered',
                        'status',
                        'createdDate'
                    ]
                )
            ]);
    }

    queryGetReferHistoryData(page) {
        return new Field('affiliateGetReferHistoryData')
            .setAlias('affiliateReferHistoryData')
            .addArgument('page', 'Int', page)
            .addFieldList([
                'totalPages',
                new Field('items').addFieldList(
                    [
                        'id',
                        'customerName',
                        'customerEmailAddress',
                        'date'
                    ]
                )
            ]);
    }

    queryGetBannerData() {
        return new Field('affiliateGetBannerData')
            .setAlias('affiliateBannerData')
            .addFieldList([
                'isEnableBannerReport',
                new Field('banners').addFieldList(
                    [
                        'title',
                        'content',
                        'link',
                        'impressions',
                        'clicks',
                        'imageUrl',
                        'isRelNofollow'
                    ]
                )
            ]);
    }

    queryGetCustomerReferBy(affiliateKey) {
        return new Field('affiliateGetCustomerReferBy')
            .addArgument('affiliateKey', 'String!', affiliateKey)
            .setAlias('affiliateCustomerReferBy');
    }

    mutationSignUp(referBy = '') {
        return new Field('affiliateSignUp')
            .addArgument('referredBy', 'String', referBy)
            .addFieldList(this._getResultFields());
    }

    mutationUpdateCouponPrefix(couponPrefix = '') {
        return new Field('affiliateUpdateCouponPrefix')
            .addArgument('couponPrefix', 'String!', couponPrefix)
            .addFieldList(this._getResultFields());
    }

    mutationSendInvitation(emailList = '', subject = '', message = '', referUrl) {
        return new Field('affiliateSendInvitation')
            .addArgument('emailList', 'String!', emailList)
            .addArgument('subject', 'String!', subject)
            .addArgument('message', 'String!', message)
            .addArgument('referUrl', 'String', referUrl)
            .addFieldList(this._getResultFields());
    }

    mutationSaveSettings(emailNotification) {
        return new Field('affiliateSaveSettings')
            .addArgument('emailNotification', 'Boolean!', emailNotification)
            .addFieldList(this._getResultFields());
    }

    mutationApplyCouponCode(cartId, couponCode) {
        return new Field('affiliateApplyCouponCode')
            .addArgument('cartId', 'String!', cartId)
            .addArgument('couponCode', 'String!', couponCode)
            .addFieldList(this._getResultFields());
    }

    mutationRemoveCouponCode(cartId) {
        return new Field('affiliateRemoveCouponCode')
            .addArgument('cartId', 'String!', cartId)
            .addFieldList(this._getResultFields());
    }

    mutationProcessParams(code, key, source) {
        return new Field('affiliateProcessParams')
            .addArgument('code', 'String', code)
            .addArgument('key', 'String', key)
            .addArgument('source', 'String', source);
    }

    _getResultFields() {
        return [
            'message'
        ];
    }
}

export default new MyAffiliateQuery();
