/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyGiftCards/Query/AccountGiftCards/Query */
export class AccountGiftCardsQuery {
    getAccountGiftCardsField() {
        return new Field('myAccountCards')
            .addFieldList(this.getMyAccountFields());
    }

    getMyAccountFields = () => [
        'code',
        'status',
        'usage',
        'balance',
        'validTill'
    ];
}

export default new AccountGiftCardsQuery();
