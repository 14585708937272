/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { createRef } from 'react';
import { connect } from 'react-redux';
import { Subscribe } from 'unstated';

import {
    mapStateToProps as sourceMapStateToProps,
    ProductActionsContainer as SourceProductActionsContainer
} from 'Component/ProductActions/ProductActions.container';
import SharedTransitionContainer from 'Component/SharedTransition/SharedTransition.unstated';
import { ACCOUNT_LOGIN_URL } from 'Route/MyAccount/MyAccount.config';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { ONE_HOUR_IN_SECONDS } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';
import { getCurrentCurrency } from 'Util/Request';
import {
    onMyAccountButtonClick
} from 'Util/SpinolaWidget/CmsButtonCustomFunctionality';
import { appendWithStoreCode } from 'Util/Url';
import { validateGroup } from 'Util/Validator';

import { GIFTCARD_PREVIEW_POPUP_ID } from '../../config';
import { AmastyGiftCardsContext } from '../../context/AmGiftCards';
import GiftCardProductActions from './GiftCardProductActions.component';
import { GIFTCARD_OPTIONS_STORAGE_KEY, IMAGE_TYPE } from './GiftCardProductActions.config';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);
/** @namespace Scandiweb/AmastyGiftCards/Component/GiftCardProductActions/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    currency: state.ConfigReducer.currencyData.current_currency_code,
    formFields: state.ConfigReducer.AmastyGiftCardsConfig?.formFields,
    isImageUpload: state.ConfigReducer.AmastyGiftCardsConfig?.isImageUpload,
    emailTemplate: state.ConfigReducer.AmastyGiftCardsConfig?.emailTemplate
});
/** @namespace Scandiweb/AmastyGiftCards/Component/GiftCardProductActions/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    addProductToCart: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addProductToCart(dispatch, options)
    ),
    showError: (message) => dispatch(showNotification('error', message)),
    showPopup: (payload) => dispatch(showPopup(GIFTCARD_PREVIEW_POPUP_ID, payload))
});

/** @namespace Scandiweb/AmastyGiftCards/Component/GiftCardProductActions/Container */
export class GiftCardProductActionsContainer extends SourceProductActionsContainer {
    static propTypes = {
        productId: PropTypes.number
    };

    containerFunctions = {
        ...this.containerFunctions,
        getGiftCardPrices: this.getGiftCardPrices.bind(this),
        handlePreviewGiftCard: this.handlePreviewGiftCard.bind(this),
        handleInputChange: this.handleInputChange.bind(this),
        handleImageClick: this.handleImageClick.bind(this),
        handleCustomPrice: this.handleCustomPrice.bind(this),
        getCustomPricePlaceholder: this.getCustomPricePlaceholder.bind(this),
        validateCustomPrice: this.validateCustomPrice.bind(this),
        handlePriceSelect: this.handlePriceSelect.bind(this),
        handleGiftCardType: this.handleGiftCardType.bind(this),
        handleScheduleDelivery: this.handleScheduleDelivery.bind(this),
        handleScheduleDeliveryTimezone: this.handleScheduleDeliveryTimezone.bind(this),
        handleScheduleDeliveryDate: this.handleScheduleDeliveryDate.bind(this),
        validatePriceSelect: this.validatePriceSelect.bind(this),
        handleBackButton: this.handleBackButton.bind(this),
        resetForm: this.getGiftCardDefaultOptions.bind(this),
        handleLogin: this.handleLogin.bind(this),
        hasError: this.hasError.bind(this)
    };

    static contextType = AmastyGiftCardsContext;

    state = {
        ...this.state,
        giftCardData: {},
        giftCardChosenOptions: {},
        emailPreviewHtmlText: ''
    };

    __construct(props) {
        super.__construct(props);

        this.formRef = createRef();
        this.configFormRef = createRef();
    }

    componentDidMount() {
        super.componentDidMount();
        const {
            location: {
                state: {
                    fromLogin = false
                } = {}
            } = {}
        } = history;

        const storageSelectedOptions = BrowserDatabase.getItem(GIFTCARD_OPTIONS_STORAGE_KEY);

        if (fromLogin && storageSelectedOptions) {
            this.setState({ giftCardChosenOptions: storageSelectedOptions });
        }

        if (!fromLogin) {
            if (storageSelectedOptions) {
                BrowserDatabase.deleteItem(GIFTCARD_OPTIONS_STORAGE_KEY);
            }
            this.getGiftCardDefaultOptions();
        }

        this.getGiftCardData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currency !== this.props.currency) {
            this.getGiftCardData(GIFTCARD_OPTIONS_STORAGE_KEY);
        }
    }

    containerProps() {
        const {
            formFields,
            currency,
            isImageUpload
        } = this.props;
        const {
            giftCardData,
            giftCardChosenOptions = {},
            emailPreviewHtmlText
        } = this.state;
        const { activeImageIndex } = this.context;

        return {
            ...super.containerProps(),
            currency,
            formRef: this.formRef,
            configFormRef: this.configFormRef,
            giftCardData,
            formFields,
            giftCardChosenOptions,
            activeImageIndex,
            isImageUpload,
            emailPreviewHtmlText,
            giftCardImageSrc: this.getGiftCardImage()
        };
    }

    handleLogin(e) {
        const { device: { isMobile } } = this.props;
        const { location: { pathname } } = history;

        if (isMobile) {
            history.push({
                pathname: appendWithStoreCode(ACCOUNT_LOGIN_URL),
                state: { backPath: pathname }
            });
        }

        onMyAccountButtonClick(e);
    }

    getGiftCardImage() {
        const {
            product: {
                giftCardOptions: {
                    images = []
                } = {}
            } = {}
        } = this.props;

        const [{ src = '' } = {}] = images;

        return src;
    }

    handleBackButton() {
        history.goBack();
    }

    handleScheduleDeliveryTimezone(value) {
        const {
            giftCardChosenOptions = {}
        } = this.state;

        this.setState({
            giftCardChosenOptions: {
                ...giftCardChosenOptions,
                timezone: value
            }
        });

        this.setInputToStorage({ timezone: value });
    }

    handleScheduleDeliveryDate(event) {
        const {
            giftCardChosenOptions = {}
        } = this.state;
        const { value } = event.target;

        this.setState({
            giftCardChosenOptions: {
                ...giftCardChosenOptions,
                date: `${value}`
            }
        });

        this.setInputToStorage({ date: `${value}` });
    }

    handleScheduleDelivery(value) {
        const {
            giftCardChosenOptions = {}
        } = this.state;

        this.setState({
            giftCardChosenOptions: {
                ...giftCardChosenOptions,
                delivery: value
            }
        });

        this.setInputToStorage({ delivery: value });
    }

    setInputToStorage(newData) {
        const prevState = BrowserDatabase.getItem(GIFTCARD_OPTIONS_STORAGE_KEY);
        BrowserDatabase.setItem(
            {
                ...prevState,
                ...newData
            },
            GIFTCARD_OPTIONS_STORAGE_KEY,
            ONE_HOUR_IN_SECONDS
        );
    }

    removeStylesFromEmailHtml(emailPreviewHtmlText) {
        const re = /<style([\S\s]*?)>([\S\s]*?)<\/style>/g;

        return emailPreviewHtmlText.replace(re, '');
    }

    async handlePreviewGiftCard(e) {
        const { showPopup, showError, emailTemplate } = this.props;
        const {
            giftCardChosenOptions: {
                price,
                customPrice,
                recipientEmail,
                recipientName,
                recipientPhone,
                message,
                senderName
            } = {}
        } = this.state;
        const { activeImage } = this.context;

        e.preventDefault();

        if (this.hasError()) {
            return;
        }

        const body = {
            am_giftcard_sender_name: senderName,
            am_giftcard_recipient_name: recipientName,
            am_giftcard_recipient_email: recipientEmail,
            mobilenumber: recipientPhone || '',
            am_giftcard_message: message || '',
            am_giftcard_amount: price || customPrice,
            am_giftcard_image: activeImage,
            am_email_template: emailTemplate
        };
        const myHeaders = new Headers();
        const formData = new FormData();

        myHeaders.append('X-Requested-With', 'XMLHttpRequest');

        const currentCurrency = getCurrentCurrency();

        if (currentCurrency) {
            myHeaders.append('Content-Currency', currentCurrency);
        }

        Object.entries(body).forEach(([key, value]) => {
            formData.append(key, value);
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
            processData: false,
            contentType: false
        };

        try {
            const data = await fetch(
                appendWithStoreCode('/amgcard/preview/getPreviewContent/'),
                requestOptions
            );
            const emailPreviewHtmlText = await data.text();
            const emailPreviewTextWithoutStyles = this.removeStylesFromEmailHtml(emailPreviewHtmlText);

            this.setState({ emailPreviewHtmlText: emailPreviewTextWithoutStyles });
            showPopup();
        } catch {
            showError('Error');
        }
    }

    handleInputChange(value, fieldName) {
        const {
            giftCardChosenOptions = {}
        } = this.state;

        this.setState({
            giftCardChosenOptions: {
                ...giftCardChosenOptions,
                [fieldName]: value
            }
        });

        this.setInputToStorage({ [fieldName]: value });
    }

    handleImageClick(imageIndex) {
        const {
            setActiveImage,
            setIsImageChosen,
            setActiveImageIndex
        } = this.context;
        const { giftCardData: { images } } = this.state;

        if (!images) {
            return;
        }

        setActiveImageIndex(imageIndex);
        setActiveImage(images[imageIndex].id);
        setIsImageChosen(true);
    }

    validateCustomPrice(value) {
        const {
            giftCardData: {
                openAmountMin,
                openAmountMax,
                prices
            },
            giftCardChosenOptions: {
                customPrice
            }
        } = this.state;

        if (
            prices?.length
            && (!value
            || !customPrice
            || (!openAmountMin && !openAmountMax))
        ) {
            return true;
        }

        if (openAmountMin === null && openAmountMax !== null) {
            return +value < +openAmountMax;
        }

        return +value >= +openAmountMin && +value <= +openAmountMax;
    }

    getCustomPricePlaceholder(min, max) {
        const { giftCardData: { openAmountMin, openAmountMax } } = this.state;

        if (!openAmountMin && !openAmountMax) {
            return null;
        }

        // Not checking min amount as it is impossible to assign min value without max on BE
        if (!openAmountMin && openAmountMax) {
            return __('Max: %s', max);
        }

        return `${min} - ${max}`;
    }

    handleCustomPrice(event) {
        const {
            giftCardChosenOptions: { customPrice = 0 } = {},
            giftCardChosenOptions = {}
        } = this.state;
        const { target: { value } } = event;

        if (!value) {
            this.setState({
                giftCardChosenOptions: {
                    ...giftCardChosenOptions,
                    customPrice: null,
                    isCustomPriceValid: this.validateCustomPrice(value),
                    price: null,
                    isPriceRequired: true
                }
            });
        }

        if (customPrice !== value) {
            this.setState({
                giftCardChosenOptions: {
                    ...giftCardChosenOptions,
                    customPrice: value,
                    isCustomPriceValid: this.validateCustomPrice(value),
                    price: null,
                    isPriceRequired: false
                }
            });
        }
    }

    handlePriceSelect(value) {
        const {
            giftCardChosenOptions: { price = 0 } = {},
            giftCardChosenOptions = {}
        } = this.state;

        if (!value) {
            this.setState({
                giftCardChosenOptions: {
                    ...giftCardChosenOptions,
                    price: undefined,
                    customPrice: undefined,
                    isPriceRequired: true
                }
            });
        }

        if (price !== value) {
            this.setState({
                giftCardChosenOptions: {
                    ...giftCardChosenOptions,
                    price: value,
                    customPrice: undefined,
                    isPriceRequired: false
                }
            });
        }
    }

    handleGiftCardType(value) {
        const {
            giftCardChosenOptions: { type = '' } = {},
            giftCardChosenOptions = {}
        } = this.state;

        this.setState({
            giftCardChosenOptions: {
                ...giftCardChosenOptions,
                type: value || type
            }
        });

        this.setInputToStorage({ type: value });
    }

    processGiftCardImages(images) {
        const { setGiftCardImages } = this.context;
        const processedImages = images.map((image) => {
            const { id, src } = image;

            return {
                base: { url: src },
                id: `GiftCard-Image-${id}`,
                media_type: IMAGE_TYPE
            };
        });

        setGiftCardImages(processedImages);
    }

    validatePriceSelect(value) {
        const {
            giftCardChosenOptions: {
                isCustomPriceValid = false,
                customPrice,
                price
            }
        } = this.state;

        if (isCustomPriceValid && customPrice) {
            return true;
        }

        if (!value && !price) {
            return false;
        }

        return true;
    }

    getGiftCardData() {
        const {
            product: {
                giftCardOptions: {
                    am_allow_open_amount: allowOpenAmount,
                    images,
                    am_giftcard_prices: prices,
                    am_giftcard_type: type,
                    am_open_amount_max: openAmountMax,
                    am_open_amount_min: openAmountMin,
                    gift_message_available: isShowGiftMessage,
                    timezones
                }
            }
        } = this.props;
        const { setActiveImage } = this.context;

        this.setState({
            giftCardData: {
                allowOpenAmount,
                images,
                prices,
                type,
                openAmountMax,
                openAmountMin,
                isShowGiftMessage,
                timezones
            }
        });

        setActiveImage(images[0].id);
        this.processGiftCardImages(images);
    }

    getGiftCardDefaultOptions() {
        const {
            product: {
                giftCardOptions: {
                    am_allow_open_amount: allowOpenAmount,
                    am_giftcard_prices: prices,
                    am_giftcard_type: type
                }
            }
        } = this.props;
        const { value: defaultPrice = '' } = prices[0] || {};
        const chosenOption = {
            isPriceRequired: false,
            price: allowOpenAmount && prices.length < 2 ? defaultPrice : 0,
            type,
            delivery: 0
        };

        this.setState({
            giftCardChosenOptions: chosenOption
        });
        this.setInputToStorage(chosenOption);
    }

    getGiftCardPrices(prices) {
        if (prices.length < 1) {
            return [];
        }
        const { value: minValue } = prices[0];
        const { value: maxValue } = prices[prices.length - 1];

        return [minValue, maxValue];
    }

    // * vvv Overridden functions

    // vvv Add Gift Card form fields
    async addToCart(cartId) {
        this.updateSelectedValues();
        const { showError } = this.props;

        if (this.hasError()) {
            return;
        }

        const { giftCardData: { type: giftCardDataType } } = this.state;
        const {
            giftCardChosenOptions: {
                type = giftCardDataType,
                price = undefined,
                customPrice = undefined,
                senderName = undefined,
                recipientName = undefined,
                recipientEmail = undefined,
                recipientPhone = undefined,
                message = undefined,
                delivery = undefined,
                date = undefined,
                timezone = undefined
            } = {}
        } = this.state;
        const { addProductToCart } = this.props;
        const products = this.getMagentoProduct();
        const { activeImage } = this.context;
        const giftCardProduct = products.map((product) => ({
            ...product,
            giftCardOptions: {
                am_giftcard_type: `${type}` || '',
                am_giftcard_amount: price || '',
                am_giftcard_amount_custom: customPrice,
                am_giftcard_image: `${activeImage}` || '',
                am_giftcard_sender_name: senderName || '',
                am_giftcard_recipient_name: recipientName || '',
                am_giftcard_recipient_email: recipientEmail || '',
                mobilenumber: recipientPhone || '',
                am_giftcard_message: message || '',
                is_date_delivery: `${delivery}` || '',
                am_giftcard_date_delivery: date || '',
                am_giftcard_date_delivery_timezone: timezone || ''
            }
        }));

        // ^^^ Adding giftCardOptions alongside selected and entered options
        await addProductToCart({ products: giftCardProduct, cartId, isGiftCard: true })
            .catch(
                /** @namespace Scandiweb/AmastyGiftCards/Component/GiftCardProductActions/Container/GiftCardProductActionsContainer/addToCart/addProductToCart/catch */
                (error) => {
                    if (error) {
                        showError(error);
                    }
                }
            );
    }

    hasError() {
        const output = validateGroup(this.configFormRef.current);

        return output !== true;
    }

    render() {
        return (
            <Subscribe to={ [SharedTransitionContainer] }>
                { ({ registerSharedElementDestination }) => (
                    <GiftCardProductActions
                      registerSharedElementDestination={ registerSharedElementDestination }
                      { ...this.containerFunctions }
                      { ...this.containerProps() }
                    />
                ) }
            </Subscribe>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardProductActionsContainer);
