/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    Children,
    cloneElement,
    lazy,
    Suspense
} from 'react';

import Image from 'Component/Image';

const CartItemGiftCardOptions = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "amasty-gift-cards" */
    '../../component/GiftCardsOptions'
));

const mapStateToProps = (args, callback, _instance) => {
    const [state] = args;
    return {
        ...callback(...args),
        currency: state.ConfigReducer?.currencyData?.current_currency_code,
        showOptionsInCart: state.ConfigReducer?.AmastyGiftCardsConfig?.showOptionsInCart
    };
};

const addFormFieldsToContainerProps = (args, callback, instance) => {
    const { currency, showOptionsInCart } = instance.props;

    return {
        ...callback(args),
        currency,
        showOptionsInCart
    };
};

const addGiftCardOptions = (args, callback, instance) => {
    const RenderTitle = callback(...args);
    const children = Children.toArray(RenderTitle.props.children);
    const {
        item: { giftCardOptions = '' },
        currency,
        showOptionsInCart
    } = instance.props;

    if (!giftCardOptions || !showOptionsInCart) {
        return callback(...args);
    }

    const Options = JSON.parse(giftCardOptions);

    return (cloneElement(
        RenderTitle,
        RenderTitle.props,
        Children.map(children, (child, i) => {
            if (i !== 0) {
                return child;
            }

            return (
                <>
                    { child }
                    <Suspense
                      fallback={ <div /> }
                      // eslint-disable-next-line react/no-array-index-key
                      key={ `GiftCardCartItem-${i}` }
                    >
                        <CartItemGiftCardOptions options={ Options } currency={ currency } />
                    </Suspense>
                </>
            );
        })
    )
    );
};

const renderGiftCardImageElement = (args, callback, instance) => {
    const {
        item: {
            giftCardOptions = '',
            product: { name }
        },
        isProductInStock,
        isMobileLayout
    } = instance.props;

    if (!giftCardOptions) {
        return callback(...args);
    }

    const Options = JSON.parse(giftCardOptions);
    const { imageSrc: image } = Options;

    return (
        <>
            <Image
              src={ image }
              mix={ {
                  block: 'CartItem',
                  elem: 'Picture',
                  mods: {
                      isNotAvailable: !isProductInStock,
                      isMobileLayout
                  }
              } }
              ratio="custom"
              alt={ `Product ${name} thumbnail.` }
            />
            <img
              style={ { display: 'none' } }
              alt={ name }
              src={ image }
            />
        </>
    );
};

export default {
    'Component/CartItem/Component': {
        'member-function': {
            renderTitle: addGiftCardOptions,
            renderImageElement: renderGiftCardImageElement
        }
    },
    'Component/CartItem/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CartItem/Container': {
        'member-function': {
            containerProps: addFormFieldsToContainerProps
        }
    }
};
