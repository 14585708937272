/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'Util/Store';

import { getCheckoutEventData, getCheckoutOptionEventData } from '../data/checkout';
import {
    getCustomerData
} from '../data/customer';
import { getPurchaseEventData } from '../data/purchase';
import {
    GTM_EVENT_KEY_BEGIN_CHECKOUT,
    GTM_EVENT_KEY_CHECKOUT_OPTION,
    GTM_EVENT_KEY_PURCHASE
} from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback, waitForCallback } from '../util/wait';

export const fireCheckoutOptionEvent = debounceCallback(async (step, option) => {
    const { customerId } = await getCustomerData();

    pushToDataLayer({
        event: GTM_EVENT_KEY_CHECKOUT_OPTION,
        customerId,
        ...await getCheckoutOptionEventData(step, option)
    });
});

export const fireCheckoutEvent = debounceCallback(async (step) => {
    await waitForCallback(() => getStore().getState().CartReducer?.cartTotals?.id);

    const {
        cartTotals: {
            prices: {
                quote_currency_code,
                subtotal_including_tax: {
                    value
                } = {}
            } = {}
        } = {}
    } = getStore().getState().CartReducer;

    pushToDataLayer({
        event: GTM_EVENT_KEY_BEGIN_CHECKOUT,
        ...await getCheckoutEventData(step),
        ecommerce: {
            currency: quote_currency_code,
            value,
            ...(await getCheckoutEventData(step)).ecommerce
        }
    });
});

export const firePurchaseEvent = debounceCallback(async (orderId, guestQuoteId) => {
    pushToDataLayer({
        event: GTM_EVENT_KEY_PURCHASE,
        ...await getPurchaseEventData(orderId, guestQuoteId)
    });
});
