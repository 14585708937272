/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { isSignedIn } from 'Util/Auth';
import { Field } from 'Util/Query';

const removeSignInCheck = ([guestCartId, mutation, isGiftCard = false]) => {
    if ((guestCartId && !isSignedIn()) || (guestCartId && isGiftCard)) {
        mutation.addArgument('guestCartId', 'String!', guestCartId);
    }
};

const addGiftCardParameter = ([guestCartId, isGiftCard = false], _callback, instance) => {
    const query = new Field('getPaymentMethods')
        .addFieldList(instance._getPaymentMethodFields());

    instance._addGuestCartId(guestCartId, query, isGiftCard);

    return query;
};

export default {
    'Query/Checkout/Query': {
        'member-function': {
            _addGuestCartId: removeSignInCheck,
            getPaymentMethodsQuery: addGiftCardParameter
        }
    }
};
