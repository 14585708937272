/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field, Fragment } from 'Util/Query';

const getGifCardsField = () => new Field('giftCards')
    .addFieldList(['id', 'code']);

const getGiftCardQuoteField = () => [
    'giftCardAmountUsed',
    getGifCardsField()
];

const getGiftCardQuoteFields = () => new Field('appliedGiftCards')
    .addFieldList(getGiftCardQuoteField());

const addGiftCardOptionsToCarItems = (args, callback, _instance) => [
    ...callback(...args),
    new Fragment('GiftCardCartItem')
        .addFieldList(['giftCardOptions'])
];

const addGiftCardField = (args, callback) => ([
    ...callback(...args),
    getGiftCardQuoteFields()
]);

const addGiftCardArgument = (args, callback) => {
    const [isGiftCard] = args;
    const field = callback(...args);
    if (isGiftCard) {
        const input = {
            is_gift_card: isGiftCard
        };

        return field.addArgument('input', 'createEmptyCartInput', input);
    }

    return field;
};

export default {
    'Query/Cart/Query': {
        'member-function': {
            _getCartItemsFields: addGiftCardOptionsToCarItems,
            _getCartTotalsFields: addGiftCardField,
            getCreateEmptyCartMutation: addGiftCardArgument
        }
    }
};
