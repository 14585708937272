/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { fireViewCartEvent } from '../../event/cart';

const afterOpenMinicart = (args, callback, instance) => {
    callback(...args);

    const {
        cartTotals: {
            items,
            prices: {
                quote_currency_code,
                subtotal_including_tax: {
                    value
                } = {}
            } = {}
        } = {}
    } = instance.props;

    fireViewCartEvent(items, value, quote_currency_code, null);
};

export default {
    'Component/Header/Container': {
        'member-function': {
            openMiniCart: afterOpenMinicart
        }
    }
};
