import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import PopupHeaderSeparatorComponent from './PopupHeaderSeparator.component';

/** @namespace Spinola/BaseTheme/Component/PopupHeaderSeparator/Container */
export class PopupHeaderSeparatorContainer extends PureComponent {
    static propTypes = {
        separatorText: PropTypes.string
    };

    static defaultProps = {
        separatorText: ''
    };

    containerProps() {
        const {
            separatorText
        } = this.props;

        return {
            separatorText
        };
    }

    render() {
        return (
            <PopupHeaderSeparatorComponent
              { ...this.containerProps() }
            />
        );
    }
}

export default PopupHeaderSeparatorContainer;
