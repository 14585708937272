/* eslint-disable max-lines */
import CheckoutQuery from 'Query/Checkout.query';
import MaldoPayQuery from 'Query/MaldoPay.query';
import { PAYMENT_TOTALS } from 'Route/Checkout/Checkout.config';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteCartId, getCartId } from 'Util/Cart';
import {
    fetchMutation
} from 'Util/Request';

export const savePaymentMethodAndPlaceOrder = async (args, callback, instance) => {
    const [paymentInformation] = args;
    const {
        paymentMethod: {
            code,
            additional_data,
            purchase_order_number
        }
    } = paymentInformation;

    if (code !== MaldoPayQuery.getMaldoPayCode()) {
        callback(...args);
        return;
    }

    const {
        showErrorNotification,
        resetCart
    } = instance.props;
    const guest_cart_id = getCartId();
    instance.setState({ isLoading: true });

    try {
        if (!isSignedIn()) {
            showErrorNotification(__('Please sign in to complate the order'));
            return;
        }

        const maskedCartId = instance?.state?.cartId || getCartId();
        const _result = await fetchMutation(MaldoPayQuery.getCheckoutIntentMutation(maskedCartId));
        const result = _result.createMaldoPayCheckoutIntent ?? {};

        if (!result.redirect_url) {
            showErrorNotification(__('Internal server error (%s)', 'MP-JS-1'));
            return;
        }

        await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
            cart_id: guest_cart_id,
            payment_method: {
                code,
                [code]: additional_data,
                purchase_order_number
            }
        }));

        /* const orderData = */
        await fetchMutation(CheckoutQuery.getPlaceOrderMutation(guest_cart_id));

        instance.setState({ isMaldoPayCheckout: true });
        try {
            deleteCartId();
            BrowserDatabase.deleteItem(PAYMENT_TOTALS);
            await resetCart();
        } catch (e) {
            instance.setState({ isMaldoPayCheckout: false });
            throw e;
        }
        window.location = result.redirect_url;
    } catch (e) {
        instance.setState({ isLoading: false });
        instance._handleError(e);
    }
};

// we don't need all those redirects and baloon messages from parent before we make a redirect
// to the payment gateway
export const disabledOnCheckout = (args, callback, instance) => {
    const { isMaldoPayCheckout = false } = instance.state;
    return isMaldoPayCheckout ? null : callback(...args);
};

export const savePaymentMethodAndPlaceOrderForGiftCards = async (args, callback, instance) => {
    const [paymentInformation] = args;
    const { cartId } = instance.state;
    const {
        showError,
        resetForm,
        showErrorNotification
    } = instance.props;
    const {
        paymentMethod: {
            code,
            additional_data,
            purchase_order_number
        }
    } = paymentInformation;

    if (code !== MaldoPayQuery.getMaldoPayCode()) {
        callback(...args);
        return;
    }

    if (!isSignedIn() && !cartId) {
        return;
    }

    try {
        const _result = await fetchMutation(MaldoPayQuery.getCheckoutIntentMutation(cartId));
        const result = _result.createMaldoPayCheckoutIntent ?? {};

        if (!result.redirect_url) {
            showErrorNotification(__('Internal server error (%s)', 'MP-JS-2'));
            return;
        }

        await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
            cart_id: cartId,
            payment_method: {
                code,
                [code]: additional_data,
                purchase_order_number
            }
        }));

        await fetchMutation(CheckoutQuery.getPlaceOrderMutation(cartId));
        resetForm();
        window.location = result.redirect_url;
    } catch (e) {
        showError(e);
    } finally {
        instance.setState({ isLoading: false });
    }
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            savePaymentMethodAndPlaceOrder,
            handleRedirectIfNoItemsInCart: disabledOnCheckout,
            componentDidUpdate: disabledOnCheckout
        }
    },
    'Scandiweb/AmastyGiftCards/Component/GiftCardAddToCart/Container': {
        'member-function': {
            savePaymentMethodAndPlaceOrder: savePaymentMethodAndPlaceOrderForGiftCards,
            handleRedirectIfNoItemsInCart: disabledOnCheckout,
            componentDidUpdate: disabledOnCheckout
        }
    }
};
