/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { updateIsLoading } from 'Store/MyAccount/MyAccount.action.js';
import { fetchMutation } from 'Util/Request';

import SocialTokenQuery from '../../query/SocialToken.query';
import { AvailableSocialType } from '../../type/Social.type';
import { executeLoginFlow } from '../../util/login';
import SocialLoginButton from './SocialLoginButton.component';

/** @namespace Scandiweb/MageplazaSocialLogin/Component/SocialLoginButton/Container/mapStateToProps */
export const mapStateToProps = () => ({
});

/** @namespace Scandiweb/MageplazaSocialLogin/Component/SocialLoginButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setMyAccountLoading: (isLoading) => dispatch(updateIsLoading(isLoading))
});

/** @namespace Scandiweb/MageplazaSocialLogin/Component/SocialLoginButton/Container */
export class SocialLoginButtonContainer extends PureComponent {
    static propTypes = {
        social: AvailableSocialType.isRequired,
        setMyAccountLoading: PropTypes.func.isRequired
    };

    containerFunctions = {
        onClick: this.onClick.bind(this)
    };

    onClick() {
        const { setMyAccountLoading } = this.props;

        this.openSocialsPopup();
        setMyAccountLoading(true);
    }

    openSocialsPopup() {
        const { social: { login_url, type } } = this.props;

        const currentTime = Date.now().toString();

        const popup = window.open(`${login_url}?${currentTime}`, type, this.getPopupDimensions());

        const CLOSED_CHECK_INTERVAL = 1000;

        const timer = setInterval(() => {
            if (popup.closed) {
                clearInterval(timer);
                this.handleUnload();
            }
        }, CLOSED_CHECK_INTERVAL);
    }

    handleUnload = async () => {
        const { social: { type }, setMyAccountLoading } = this.props;

        try {
            const { socialToken: { token } } = await fetchMutation([
                SocialTokenQuery.getQuery({ type })
            ]);

            await executeLoginFlow(token);
        } catch (error) {
            console.error(error, error.stack);
        }

        setMyAccountLoading(false);
    };

    getPopupDimensions = () => {
        const width = 500;
        const height = 420;

        const {
            screenLeft, screenTop, outerWidth, outerHeight
        } = window;

        const left = parseInt(screenLeft + ((outerWidth - width) / 2), 10);
        // eslint-disable-next-line no-magic-numbers
        const top = parseInt(screenTop + ((outerHeight - height) / 2.5), 10);

        return Object.entries({
            width,
            height,
            left,
            top
        }).map(
            ([key, val]) => `${key}=${val}`
        ).join(',');
    };

    /**
     * Added icon prop
     */
    containerProps() {
        const { social: { label, type, icon } } = this.props;

        return {
            label,
            type,
            icon
        };
    }

    render() {
        return (
            <SocialLoginButton
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialLoginButtonContainer);
