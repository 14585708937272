/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { createContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { ChildrenType } from 'Type/Common.type';
import {
    fetchMutation,
    fetchQuery
} from 'Util/Request';

import AccountGiftCardsQuery from '../query/AccountGiftCards.query';
import AddToAccountMutation from '../query/AddToAccount.mutation';
import RemoveFromAccountMutation from '../query/RemoveFromAccount.mutation';

export const AmastyGiftCardsContext = createContext({
    activeImage: 0
});

/** @namespace Scandiweb/AmastyGiftCards/Context/AmGiftCards/AmastyGiftCardsProvider */
export const AmastyGiftCardsProvider = ({ children }) => {
    const [activeImage, setActiveImage] = useState();
    const [activeImageIndex, setActiveImageIndex] = useState(0);
    const [isImageChosen, setIsImageChosen] = useState(null);
    const [giftCardImages, setGiftCardImages] = useState([]);
    const [myAccountCards, setMyAccountCards] = useState([]);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');
    const dispatch = useDispatch();

    const fetchMyAccountCards = async () => {
        const query = AccountGiftCardsQuery.getAccountGiftCardsField();
        const {
            myAccountCards
        } = await fetchQuery(query);

        setMyAccountCards(myAccountCards);
    };

    const handleCardRemove = async (code) => {
        const {
            removeAccountGiftCard: {
                error,
                message
            }
        } = await fetchMutation(RemoveFromAccountMutation.removeFromAccount(code));

        if (!error) {
            await fetchMyAccountCards();
            dispatch(showNotification('success', message));
            return;
        }

        dispatch(showNotification('error', message));
    };

    const handleAddToAccount = async (chosenInput) => {
        const {
            addAccountGiftCard: {
                error,
                message
            }
        } = await fetchMutation(AddToAccountMutation.addToAccount(chosenInput));

        if (!error) {
            await fetchMyAccountCards();
            dispatch(showNotification('success', message));
            setIsButtonLoading(false);
            return;
        }

        dispatch(showNotification('error', message));
        setIsButtonLoading(false);
    };

    const state = {
        activeImage,
        setActiveImage,
        activeImageIndex,
        setActiveImageIndex,
        isImageChosen,
        setIsImageChosen,
        giftCardImages,
        setGiftCardImages,
        fetchMyAccountCards,
        setMyAccountCards,
        myAccountCards,
        handleCardRemove,
        handleAddToAccount,
        isButtonLoading,
        setIsButtonLoading,
        error,
        setError,
        message,
        setMessage
    };

    return (
        <AmastyGiftCardsContext.Provider value={ state }>
            { children }
        </AmastyGiftCardsContext.Provider>
    );
};

AmastyGiftCardsContext.displayName = 'AmastyGiftCardsContext';

AmastyGiftCardsProvider.displayName = 'AmastyGiftCardsProvider';

AmastyGiftCardsProvider.propTypes = {
    children: ChildrenType.isRequired
};
