/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/Gtm/Query/Config/Query */
export class ConfigQuery {
    _getEventFields() {
        return [
            'gtm_general_init',
            'gtm_view_item_list',
            'gtm_view_item_list_batch_limit',
            'gtm_select_item',
            'gtm_view_item',
            'gtm_add_to_wishlist',
            'gtm_add_to_cart',
            'gtm_remove_from_cart',
            'gtm_view_cart',
            'gtm_begin_checkout',
            'gtm_add_shipping_info',
            'gtm_add_payment_info',
            'gtm_purchase',
            'gtm_search',
            'gtm_view_promotion',
            'gtm_select_promotion',
            'gtm_login',
            'gtm_sign_up',
            'gtm_generate_lead',
            'gtm_filter_select',
            'gtm_sort_select'
        ];
    }

    _getEventField() {
        return new Field('events')
            .addFieldList(this._getEventFields());
    }

    _getConfigFields() {
        return [
            'enabled',
            'gtm_id',
            'googleOptimizeEnabled',
            'googleOptimizeId',
            'googleOptimizeAsyncEnabled',
            'antiFlickerEnabled',
            this._getEventField()
        ];
    }

    getGtmField() {
        return new Field('getGtm')
            .setAlias('gtm')
            .addFieldList(this._getConfigFields());
    }
}

export default new ConfigQuery();
