/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const AM_GIFT_CARD_TYPE_PHYSICAL = 2;
export const AM_GIFT_CARD_TYPE_BOTH = 3;
export const IMAGE_TYPE = 'image';
export const AM_GIFTCARD_CAROUSEL_LENGTH = 5;
export const AM_GIFTCARD_PRODUCT_TYPE = 'amgiftcard';
export const AM_GIFTCARD_SENDER_NAME = 'am_giftcard_sender_name';
export const AM_GIFTCARD_RECIPIENT_NAME = 'am_giftcard_recipient_name';
export const AM_GIFTCARD_MOBILE = 'mobilenumber';
export const AM_GIFTCARD_MESSAGE = 'am_giftcard_message';
export const GIFTCARD_OPTIONS_STORAGE_KEY = 'giftCardChosenOptions';
