/**
 * Amasty Special Promotions Pro compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import InformationIcon from 'Component/InformationIcon';
import { formatPrice } from 'Util/Price';

import './DiscountRule.style';

/** @namespace Scandiweb/AmastySpecialPromotionsPro/Component/DiscountRule/Component */
export class DiscountRuleComponent extends PureComponent {
    static propTypes = {
        price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        currency: PropTypes.string,
        itemsQty: PropTypes.number,
        title: PropTypes.string.isRequired,
        coupon_code: PropTypes.string,
        amastyDiscounts: PropTypes.arrayOf(
            PropTypes.shape({
                rule_id: PropTypes.number.isRequired,
                discount_amount: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired
            })
        ),
        isOpen: PropTypes.bool.isRequired,
        expandableContentHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        expandableRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({
                current: PropTypes.instanceOf(Element)
            })
        ]).isRequired,
        isSuccessPage: PropTypes.bool.isRequired
    };

    static defaultProps = {
        itemsQty: 0,
        coupon_code: '',
        currency: 'USD',
        amastyDiscounts: []
    };

    renderTitle() {
        const { title, isSuccessPage } = this.props;

        return (
            <p block="AmastyDiscount" elem="Title" aria-hidden>
                { title }
                { !isSuccessPage && <InformationIcon isWhite /> }
            </p>
        );
    }

    renderPrice() {
        const { price, currency } = this.props;

        return (
            <div block="AmastyDiscount" elem="Price">
                { formatPrice(price, currency) }
            </div>
        );
    }

    render() {
        const {
            price,
            itemsQty
        } = this.props;

        if (!itemsQty && !price) {
            return null;
        }

        if (price === 0 && !itemsQty) {
            return null;
        }

        return (
            <li block="AmastyDiscount" elem="Main">
                { this.renderTitle() }
                { this.renderPrice() }
            </li>
        );
    }
}

export default DiscountRuleComponent;
