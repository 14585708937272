/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyGiftCards/Query/AddToAccount/Mutation */
export class AddToAccountMutation {
    addToAccount(giftCardCode) {
        return new Field('addAccountGiftCard')
            .addArgument('giftCardCode', 'String!', giftCardCode)
            .addFieldList(this.getResponse());
    }

    getResponse() {
        return [
            'message',
            'error'
        ];
    }
}

export default new AddToAccountMutation();
