/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

import '../style/main.scss';

const getGiftCardFields = () => [
    'generalActive',
    'displayOptionsImageUploadTooltip',
    'accountCheckoutViewType',
    'allowAssignToCustomer',
    'formFields',
    'isImageUpload',
    'allowedProductTypes',
    'showOptionsInCart',
    'emailTemplate'
];

const addAmastyGiftCards = () => new Field('AmastyGiftCardsConfig')
    .addFieldList(getGiftCardFields());

const addGiftCardConfigFields = (args, callback) => ([
    ...callback(...args),
    addAmastyGiftCards()

]
);

export default {
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields: addGiftCardConfigFields
        }
    }
};
