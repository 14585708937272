/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PRODUCT_TYPE from 'Component/Product/Product.config';

import {
    GTM_EVENT_KEY_SELECT_ITEM,
    GTM_EVENT_KEY_VIEW_ITEM_LIST
} from '../../util/events';
import { pushToDataLayer } from '../../util/push';

const viewItemList = (args, callback) => {
    const gameData = args[0];
    const {
        config: {
            currencyCode,
            data
        } = {}
    } = gameData || {};

    if (!data || !data.length) {
        return callback(...args);
    }

    const items = data.map((item, index) => {
        const {
            lottery: {
                brandLotteryId,
                name
            } = {}
        } = item;

        return {
            item_id: brandLotteryId.toString(),
            item_name: name,
            item_category: PRODUCT_TYPE.lottery,
            index
        };
    });

    pushToDataLayer({
        event: GTM_EVENT_KEY_VIEW_ITEM_LIST,
        ecommerce: {
            item_list_name: 'Results Widget',
            item_list_id: 'results_widget',
            currency: currencyCode,
            items
        }
    });

    return callback(...args);
};

const selectItem = (args, callback) => {
    const brandLotteryId = args[0];
    const item_name = args[1];
    const currency = args[2];

    pushToDataLayer({
        event: GTM_EVENT_KEY_SELECT_ITEM,
        ecommerce: {
            item_list_name: 'Results Widget',
            item_list_id: 'results_widget',
            currency,
            items: [{
                item_id: brandLotteryId.toString(),
                item_name,
                item_category: PRODUCT_TYPE.lottery
            }]
        }
    });

    return callback(...args);
};

export default {
    'Component/ResultsWidget/Container': {
        'member-function': {
            _customizeGameConfig: viewItemList,
            onGameListItemClick: selectItem
        }
    }
};
