/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { UPDATE_PRODUCT_LIST_INFO } from 'Store/ProductListInfo/ProductListInfo.action';

import { handleProductFilterEvents } from '../../event/filter';

const addProductFilterEventHandler = (args, callback) => {
    const [state, action] = args;

    if (action.type !== UPDATE_PRODUCT_LIST_INFO) {
        return callback(...args);
    }

    const { selectedFilter: { customFilters: oldCustomFilters = {} } = {} } = state;
    const {
        selectedFilter: { customFilters },
        products: { filters: filtersDetails },
        customerId
    } = action;

    handleProductFilterEvents(oldCustomFilters, customFilters, filtersDetails, customerId);

    return callback(...args);
};

export default {
    'Store/ProductListInfo/Reducer/ProductListReducer': {
        function: addProductFilterEventHandler
    }
};
