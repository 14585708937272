/**
 * Mageplaza Affiliate compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const UPDATE_AFFILIATE_CONFIG = 'UPDATE_AFFILIATE_CONFIG';
export const SET_IS_AFFILIATE_CONFIG_LOADING = 'SET_IS_AFFILIATE_CONFIG_LOADING';

/** @namespace Scandiweb/MageplazaAffiliate/Store/MyAffiliate/Action/updateAffiliateConfig */
export const updateAffiliateConfig = (affiliateConfig) => ({
    type: UPDATE_AFFILIATE_CONFIG,
    affiliateConfig
});

/** @namespace Scandiweb/MageplazaAffiliate/Store/MyAffiliate/Action/setIsAffiliateConfigLoading */
export const setIsAffiliateConfigLoading = (isLoading) => ({
    type: SET_IS_AFFILIATE_CONFIG_LOADING,
    isLoading
});
