/**
 * Mageplaza Affiliate compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

const _getStoreConfigFields = (args, callback) => ([
    ...callback(...args),
    'affiliate_use_code_as_coupon'
]);

export default {
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields
        }
    }
};
