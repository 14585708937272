/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import Image from 'Component/Image';

import GiftCardPLPPrice from '../../component/GiftCardPLPPrice';
import { AM_GIFTCARD_PRODUCT_TYPE } from '../../component/GiftCardProductActions/GiftCardProductActions.config';

const renderGiftCardPicture = (args, callback, instance) => {
    const { mix } = args;
    const {
        product: {
            id,
            name,
            type_id,
            giftCardOptions: {
                images = []
            } = {}
        }
    } = instance.props;

    if (
        type_id !== AM_GIFTCARD_PRODUCT_TYPE
        || !images.length
    ) {
        return callback(...args);
    }

    const { src: thumbnail } = images[0];

    // eslint-disable-next-line no-param-reassign
    instance.sharedComponent = (
        <Image
          imageRef={ instance.imageRef }
          src={ thumbnail }
          alt={ name }
          ratio="custom"
          mix={ { block: 'ProductCard', elem: 'Picture', mix } }
          isPlaceholder={ !id }
        />
    );

    return (
        <>
            { instance.sharedComponent }
            <img
              style={ { display: 'none' } }
              alt={ name }
              src={ thumbnail }
            />
        </>
    );
};

const renderGiftCardPrice = (args, callback, instance) => {
    const {
        product: {
            type_id,
            giftCardOptions: {
                am_giftcard_prices: prices = [],
                am_allow_open_amount: allowOpenAmount = false,
                am_open_amount_max: openAmountMax,
                am_open_amount_min: openAmountMin
            } = {}
        }
    } = instance.props;

    if (type_id !== AM_GIFTCARD_PRODUCT_TYPE) {
        return callback(...args);
    }

    return (
        <div
          block={ instance.className }
          elem="PriceWrapper"
        >
            <GiftCardPLPPrice
              prices={ prices }
              openAmount={ {
                  allowOpenAmount,
                  openAmountMin,
                  openAmountMax
              } }
              mix={ { block: instance.className, elem: 'Price' } }
            />
        </div>
    );
};

const requireGiftCardConfig = (args, callback, instance) => {
    const { product: { type_id: type } } = instance.props;

    if (!type || type !== AM_GIFTCARD_PRODUCT_TYPE) {
        return callback(...args);
    }

    return true;
};

export default {
    'Component/ProductCard/Component': {
        'member-function': {
            renderPrice: renderGiftCardPrice,
            renderPicture: renderGiftCardPicture,
            requiresConfiguration: requireGiftCardConfig
        }
    }
};
