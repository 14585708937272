/**
 * Mageplaza Affiliate compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable react/boolean-prop-naming */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { withReducers } from 'Util/DynamicReducer';
import { fetchMutation, getErrorMessage } from 'Util/Request';

import MyAffiliateQuery from '../../query/MyAffiliate.query';
import MyAffiliateReducer from '../../store/MyAffiliate/MyAffiliate.reducer';
import { AffiliateConfigType } from '../../type/MyAffiliate.type';
import MyAffiliateDiscountCode from './MyAffiliateDiscountCode.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Scandiweb/MageplazaAffiliate/Component/MyAffiliateDiscountCode/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    cartId: state.CartReducer?.cartTotals?.id,
    affiliateDiscount: state.CartReducer?.cartTotals?.prices?.affiliate_discount_data?.discount_amount,
    affiliateCouponCode: state.CartReducer?.cartTotals?.prices?.affiliate_discount_data?.coupon_code,
    currency: state.ConfigReducer.currencyData,
    affiliateConfig: state.MyAccountReducer.customer.affiliate_config,
    affiliateUseCodeAsCoupon: state.ConfigReducer.affiliate_use_code_as_coupon
});

/** @namespace Scandiweb/MageplazaAffiliate/Component/MyAffiliateDiscountCode/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateCart: async () => {
        CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
        );
    },
    showNotification: (status, message) => dispatch(showNotification(status, message))
});

/** @namespace Scandiweb/MageplazaAffiliate/Component/MyAffiliateDiscountCode/Container */
export class MyAffiliateDiscountCodeContainer extends PureComponent {
    static propTypes = {
        updateCart: PropTypes.func.isRequired,
        cartId: PropTypes.string.isRequired,
        showNotification: PropTypes.func.isRequired,
        currency: PropTypes.shape({
            current_currency_code: PropTypes.string
        }).isRequired,
        affiliateDiscount: PropTypes.number,
        affiliateCouponCode: PropTypes.number,
        affiliateConfig: AffiliateConfigType.isRequired,
        affiliateUseCodeAsCoupon: PropTypes.bool.isRequired
    };

    static defaultProps = {
        affiliateDiscount: 0,
        affiliateCouponCode: ''
    };

    containerFunctions = {
        inputHandler: this.inputHandler.bind(this),
        handleApply: this.handleApply.bind(this)
    };

    state = {
        isLoading: false,
        couponCodeValue: ''
    };

    componentDidMount() {
        const {
            affiliateCouponCode
        } = this.props;

        if (affiliateCouponCode) {
            this.setState({
                couponCodeValue: affiliateCouponCode
            });
        }
    }

    handleApply() {
        const {
            couponCodeValue = '',
            isLoading
        } = this.state;
        const {
            updateCart,
            showNotification,
            cartId,
            affiliateDiscount
        } = this.props;

        this.setState({
            isLoading: true
        });

        if (isLoading) {
            return;
        }

        if (!affiliateDiscount) {
            fetchMutation(MyAffiliateQuery.mutationApplyCouponCode(cartId, couponCodeValue.trim())).then(
                /** @namespace Scandiweb/MageplazaAffiliate/Component/MyAffiliateDiscountCode/Container/MyAffiliateDiscountCodeContainer/handleApply/catch/finally/then/catch/fetchMutation/then */
                ({ affiliateApplyCouponCode }) => {
                    const { message } = affiliateApplyCouponCode;

                    showNotification('success', message);
                    updateCart();
                }
            ).catch(
                /** @namespace Scandiweb/MageplazaAffiliate/Component/MyAffiliateDiscountCode/Container/MyAffiliateDiscountCodeContainer/handleApply/catch/finally/then/catch */
                (error) => {
                    showNotification('error', getErrorMessage(error));
                    this.clearInput();
                }
            ).finally(
                /** @namespace Scandiweb/MageplazaAffiliate/Component/MyAffiliateDiscountCode/Container/MyAffiliateDiscountCodeContainer/handleApply/catch/finally */
                () => {
                    this.setState({
                        isLoading: false
                    });
                }
            );
        } else {
            fetchMutation(MyAffiliateQuery.mutationRemoveCouponCode(cartId)).then(
                /** @namespace Scandiweb/MageplazaAffiliate/Component/MyAffiliateDiscountCode/Container/MyAffiliateDiscountCodeContainer/handleApply/catch/finally/then/catch/fetchMutation/then */
                ({ affiliateRemoveCouponCode }) => {
                    const { message } = affiliateRemoveCouponCode;

                    showNotification('success', message);
                    updateCart();
                    this.clearInput();
                }
            ).catch(
                /** @namespace Scandiweb/MageplazaAffiliate/Component/MyAffiliateDiscountCode/Container/MyAffiliateDiscountCodeContainer/handleApply/catch/finally/then/catch */
                (error) => {
                    showNotification('error', getErrorMessage(error));
                }
            ).finally(
                /** @namespace Scandiweb/MageplazaAffiliate/Component/MyAffiliateDiscountCode/Container/MyAffiliateDiscountCodeContainer/handleApply/catch/finally */
                () => {
                    this.setState({
                        isLoading: false
                    });
                }
            );
        }
    }

    clearInput() {
        this.setState({
            couponCodeValue: ''
        });
    }

    inputHandler(event) {
        const {
            target: { value }
        } = event;

        this.setState({ couponCodeValue: value });
    }

    containerProps() {
        const {
            couponCodeValue,
            isLoading
        } = this.state;
        const {
            cartId,
            affiliateDiscount,
            affiliateCouponCode,
            affiliateConfig,
            affiliateUseCodeAsCoupon
        } = this.props;

        return {
            couponCodeValue,
            cartId,
            isLoading,
            affiliateCouponCode,
            isApplied: !!affiliateDiscount,
            affiliateConfig,
            affiliateUseCodeAsCoupon
        };
    }

    render() {
        return (
            <MyAffiliateDiscountCode
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default withReducers({
    MyAffiliateReducer
})(connect(mapStateToProps, mapDispatchToProps)(MyAffiliateDiscountCodeContainer));
