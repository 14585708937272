/**
 * Mageplaza Affiliate compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { formatPrice } from 'Util/Price/Price';

function renderAffiliateDiscountCouponRow(instance) {
    const { props } = instance;
    const {
        order: {
            total: {
                affiliate_discount_data,
                subtotal: {
                    currency = ''
                } = {}
            } = {}
        } = {}
    } = props;
    const { discount_amount, coupon_code } = affiliate_discount_data || {};

    if (!discount_amount) {
        return null;
    }

    const label = `${__('Affiliate Discount Coupon')} (${ coupon_code })`;
    const formattedAmount = `-${formatPrice(discount_amount, currency)}`;

    return instance.renderExtendedItemRow(label, formattedAmount);
}

const renderDiscountCouponRow = (args, callback, instance) => (
    <>
        { callback(...args) }
        { renderAffiliateDiscountCouponRow(instance) }
    </>
);

export default {
    'Component/MyAccountOrderTableRow/Component': {
        'member-function': {
            renderDiscountCouponRow
        }
    }
};
