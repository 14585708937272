/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { lazy, Suspense } from 'react';

const CheckoutOrderSummaryPriceLine = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "amasty-gift-cards" */
    'Component/CheckoutOrderSummaryPriceLine'
));

const renderLabel = (giftcards = []) => giftcards?.reduce((
    label,
    { code },
    idx
) => `${label + code }${idx !== giftcards.length - 1 ? ',\n' : ''}`, '');

const addAppliedGiftCardsAfterShipping = (args, callback, instance) => {
    const {
        totals: { appliedGiftCards = {},
            appliedGiftCards: { giftCards = {}, giftCardAmountUsed },
            prices: {
                quote_currency_code = null
            } = {}
        }
    } = instance.props;
    const label = __('Gift Card %s', renderLabel(giftCards));
    const discountAmount = -Math.abs(giftCardAmountUsed);

    if (!giftCards?.length) {
        return callback(...args);
    }

    return (
        <>
            { callback(...args) }
            <Suspense
            fallback={ <div /> }
            >
                <CheckoutOrderSummaryPriceLine
                    price={ discountAmount }
                    currency={ quote_currency_code }
                    title={ label }
                />
            </Suspense>
        </>
    );
};

export default {
    'Component/CheckoutOrderSummary/Component': {
        'member-function': {
            renderShipping: addAppliedGiftCardsAfterShipping
        }
    }
};
