import { Field } from 'Util/Query';

/** @namespace BridgerpayPayments/Query/BridgerPayKeys/Query */
export class BridgerPayKeysQuery {
    getCashierKeysMutation(cartId) {
        return new Field('generateBridgerPayToken')
            .addArgument('cartId', 'String', cartId)
            .addArgument('force', 'Boolean', true)
            .addFieldList(this.getBridgerPayKeysFields());
    }

    getBridgerPayKeysFields() {
        return [
            'bridgerPayCashierKey',
            'bridgerPayCashierToken',
            'message',
            'status'
        ];
    }
}

export default new BridgerPayKeysQuery();
