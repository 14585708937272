import SourceApp from 'SourceComponent/App/App.component';

/** @namespace Spinola/BaseTheme/Component/App/Component */
export class AppComponent extends SourceApp {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    injectComment() {
        // do nothing, don't inject anything
    }
}

export default AppComponent;
