/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps, mapStateToProps as sourceMapStateToProps,
    ProductPriceContainer as SourcePriceContainer
} from 'Component/ProductPrice/ProductPrice.container';

import GiftCardPLPPrice from './GiftCardPLPPrice.component';

/** @namespace Scandiweb/AmastyGiftCards/Component/GiftCardPLPPrice/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    am_currency: state.ConfigReducer.currencyData.current_currency_code
});

/** @namespace Scandiweb/AmastyGiftCards/Component/GiftCardPLPPrice/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace Scandiweb/AmastyGiftCards/Component/GiftCardPLPPrice/Container */
export class GiftCardPLPPriceContainer extends SourcePriceContainer {
    static propTypes = {
    };

    containerFunctions = {
        ...this.containerFunctions
    };

    containerProps() {
        const {
            prices,
            am_currency,
            openAmount: {
                allowOpenAmount,
                openAmountMin,
                openAmountMax
            }
        } = this.props;

        return {
            prices,
            am_currency,
            openAmount: {
                allowOpenAmount,
                openAmountMin,
                openAmountMax
            }
        };
    }

    render() {
        return (
            <GiftCardPLPPrice
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardPLPPriceContainer);
