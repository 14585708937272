/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import MyAccountLoader from './MyAccountLoader.component';

/** @namespace Scandiweb/MageplazaSocialLogin/Component/MyAccountLoader/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isLoading: state.MyAccountReducer.isLoading
});

/** @namespace Scandiweb/MageplazaSocialLogin/Component/MyAccountLoader/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({
});

/** @namespace Scandiweb/MageplazaSocialLogin/Component/MyAccountLoader/Container */
export class MyAccountLoaderContainer extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired
    };

    containerFunctions = {
    };

    containerProps() {
        const { isLoading } = this.props;

        return {
            isLoading
        };
    }

    render() {
        return (
            <MyAccountLoader
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountLoaderContainer);
