/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import CodeStatusChecker from '../component/CodeStatusChecker';
import { CODE_STATUS_CHECKER_CMS_TYPE } from '../component/CodeStatusChecker/CodeStatusChecker.config';

export const addCodeStatusCheckerToWidgets = (originalMembers) => ({
    ...originalMembers,
    [CODE_STATUS_CHECKER_CMS_TYPE]: {
        component: CodeStatusChecker
    }
});

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            renderMap: addCodeStatusCheckerToWidgets
        }
    }
};
