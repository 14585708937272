/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable no-unused-vars */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    CHECKOUT_ORDER_POPUP,
    CHECKOUT_ORDER_POPUP_TYPES
} from 'Component/CheckoutOrderPopup/CheckoutOrderPopup.config';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import CartQuery from 'Query/Cart.query';
import CheckoutQuery from 'Query/Checkout.query';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import {
    CheckoutContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountDispatcher
} from 'Route/Checkout/Checkout.container';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { MixType } from 'Type/Common.type';
import { LayoutType } from 'Type/Layout.type';
import { ProductType } from 'Type/ProductList.type';
import {
    trimCheckoutCustomerAddress
} from 'Util/Address';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { ADD_TO_CART } from 'Util/Product';
import {
    getMaxQuantity, getMinQuantity, getName, getProductInStock
} from 'Util/Product/Extract';
import { magentoProductTransform } from 'Util/Product/Transform';
import { fetchMutation, fetchQuery, getErrorMessage } from 'Util/Request';

import { GIFT_CARD_PAYMENT_POPUP } from '../GiftCardPaymentPopup/GiftCardPaymentPopup.config';
import GiftCardAddToCart from './GiftCardAddToCart.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Scandiweb/AmastyGiftCards/Component/GiftCardAddToCart/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace Scandiweb/AmastyGiftCards/Component/GiftCardAddToCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    fallbackAddToCart: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addProductToCart(dispatch, options)
    ),
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    ),
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
    showCheckoutPopup: (
        type = CHECKOUT_ORDER_POPUP_TYPES.success,
        orderId = '',
        isGiftCardPurchase = true
    ) => dispatch(showPopup(
        CHECKOUT_ORDER_POPUP,
        { type, orderId, isGiftCardPurchase }
    )),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    showError: (error) => dispatch(showNotification(
        'error',
        typeof error === 'string' ? error : getErrorMessage(error)
    ))
});

/** @namespace Scandiweb/AmastyGiftCards/Component/GiftCardAddToCart/Container */
export class GiftCardAddToCartContainer extends CheckoutContainer {
    static propTypes = {
        ...this.propTypes,
        product: ProductType,
        quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.objectOf(PropTypes.number)]),
        cartId: PropTypes.string,
        showNotification: PropTypes.func.isRequired,
        showCheckoutPopup: PropTypes.func.isRequired,
        addToCart: PropTypes.func,
        fallbackAddToCart: PropTypes.func.isRequired,
        isDisabled: PropTypes.bool,
        updateSelectedValues: PropTypes.func,
        withLink: PropTypes.bool,
        isIconEnabled: PropTypes.bool,
        mix: MixType,
        hasError: PropTypes.func.isRequired,
        layout: LayoutType,
        showError: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        showOverlay: PropTypes.func.isRequired,
        requestCustomerData: PropTypes.func.isRequired
    };

    static defaultProps = {
        quantity: 1,
        cartId: '',
        mix: {},
        layout: GRID_LAYOUT,
        isIconEnabled: true,
        isDisabled: false,
        addToCart: null,
        updateSelectedValues: null,
        withLink: false,
        product: {}
    };

    state = {
        ...this.state,
        isLoading: false,
        isAdding: false,
        paymentMethod: '',
        cartId: ''
    };

    containerFunctions = {
        addProductToCart: this.addProductToCart.bind(this),
        handleButtonClick: this.handleButtonClick.bind(this),
        onPopupButtonClick: this.onPopupButtonClick.bind(this),
        onPaymentMethodSelect: this.onPaymentMethodSelect.bind(this)
    };

    globalValidationMap = [
        this.validateStock.bind(this),
        this.validateQuantity.bind(this)
    ];

    componentDidMount() {
        const {
            customer,
            requestCustomerData,
            isSignedIn
        } = this.props;

        if (!Object.keys(customer).length && isSignedIn) {
            requestCustomerData();
        }
    }

    componentDidUpdate() {
    }

    componentWillUnmount() {
    }

    containerProps() {
        const {
            isDisabled,
            isIconEnabled,
            mix,
            layout,
            label
        } = this.props;

        const {
            isAdding,
            paymentMethods,
            isLoading,
            orderId
        } = this.state;

        return {
            isDisabled,
            isIconEnabled,
            isLoading,
            mix,
            layout,
            isAdding,
            orderId,
            label,
            paymentMethods,
            shippingAddress: this._getAddress()
        };
    }

    async createEmptyCart() {
        const { showError } = this.props;
        try {
            const { createEmptyCart: cartId } = await fetchMutation(
                CartQuery.getCreateEmptyCartMutation(true)
            );

            this.setState({ cartId });
        } catch (e) {
            this.setState({ isLoading: false });
            showError(e);
        }
    }

    async handleButtonClick(e) {
        const {
            withLink,
            showOverlay,
            hasError
        } = this.props;

        // Prevent container Link from triggering redirect
        if (!withLink) {
            e.stopPropagation();
            e.preventDefault();
        }

        if (hasError()) {
            return;
        }

        this.setState({ isLoading: true });
        await this.createEmptyCart();
        await this.addProductToCart();
        const paymentMethods = await this.getPaymentMethods();

        if (paymentMethods?.length > 1) {
            this.setState({ isLoading: false });
            showOverlay(GIFT_CARD_PAYMENT_POPUP);
            return;
        }

        this.setState({ isLoading: true });
        await this.saveGuestEmail();
        const paymentMethod = paymentMethods.length === 1 && paymentMethods[0]?.code;

        this.processPayment(paymentMethod);
    }

    async addProductToCart() {
        const {
            product,
            addToCart,
            updateSelectedValues,
            showError
        } = this.props;
        const { cartId } = this.state;

        if (updateSelectedValues) {
            await updateSelectedValues();
        }

        if ((!product || Object.keys(product).length === 0) && !addToCart) {
            return;
        }

        if (!this.validate()) {
            return;
        }

        if (typeof addToCart === 'function') {
            try {
                await addToCart(cartId);
            } catch (e) {
                showError(e);
                this.setState({ isLoading: false });
            }
        } else {
            const {
                quantity,
                fallbackAddToCart
            } = this.props;
            const magentoProduct = magentoProductTransform(ADD_TO_CART, product, quantity);

            try {
                await fallbackAddToCart({
                    products: magentoProduct,
                    cartId
                });
            } catch (e) {
                showError(e);
                this.setState({ isLoading: false });
            }
        }
    }

    processPayment(preselectedPaymentMethod = null) {
        const {
            paymentMethod: statePaymentMethod
        } = this.state;
        const address = this._getAddress();
        const paymentMethodCode = preselectedPaymentMethod || statePaymentMethod;
        this.setState({ isLoading: true });
        this.savePaymentInformation({
            billing_address: address,
            paymentMethod: { code: paymentMethodCode }
        });
    }

    async saveBillingAddress(paymentInformation) {
        const { showError } = this.props;
        const { cartId } = this.state;
        const isCustomerSignedIn = isSignedIn();

        if (!isCustomerSignedIn && !cartId) {
            return;
        }

        const { billing_address, same_as_shipping } = paymentInformation;
        const {
            shippingAddress: {
                id: shippingAddressId = null
            } = {}
        } = this.state;
        const billingAddress = {
            address: this.trimAddressMagentoStyle(billing_address)
        };

        if (same_as_shipping && shippingAddressId) {
            billingAddress.customer_address_id = shippingAddressId;
        }

        try {
            await fetchMutation(CheckoutQuery.getSetBillingAddressOnCart({
                cart_id: cartId,
                billing_address: billingAddress
            }));
        } catch (e) {
            this.setState({ isLoading: false });
            showError(e);
        }
    }

    async savePaymentMethodAndPlaceOrder(paymentInformation) {
        const { cartId } = this.state;
        const {
            showCheckoutPopup,
            showError,
            resetForm
        } = this.props;
        const {
            paymentMethod: { code, additional_data, purchase_order_number }
        } = paymentInformation;
        const isCustomerSignedIn = isSignedIn();

        if (!isCustomerSignedIn && !cartId) {
            return;
        }

        try {
            await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
                cart_id: cartId,
                payment_method: {
                    code,
                    [code]: additional_data,
                    purchase_order_number
                }
            }));

            const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(cartId));
            const { placeOrder: { order: { order_id } } } = orderData;
            this.setState({ orderID: order_id });
            resetForm();
            history.replace('/');
            showCheckoutPopup(CHECKOUT_ORDER_POPUP_TYPES.sucess, order_id);
        } catch (e) {
            showError(e);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    async saveGuestEmail() {
        const {
            customer: {
                email = ''
            } = {},
            showError
        } = this.props;
        const { cartId } = this.state;
        const mutation = CheckoutQuery.getSaveGuestEmailMutation(email, cartId);

        try {
            await fetchMutation(mutation);
        } catch (e) {
            showError(e);
        }
    }

    onPaymentMethodSelect(paymentMethod) {
        this.setState({ paymentMethod });
    }

    async onPopupButtonClick() {
        const { hideActiveOverlay } = this.props;

        hideActiveOverlay();
        await this.saveGuestEmail();
        this.processPayment();
    }

    validate() {
        // eslint-disable-next-line fp/no-let
        let isValid = true;

        this.globalValidationMap.forEach((step) => {
            if (!step()) {
                isValid = false;
            }
        });

        return isValid;
    }

    validateStock() {
        const { product, showNotification } = this.props;
        const inStock = getProductInStock(product);

        if (!inStock) {
            const name = getName(product);

            showNotification('info', __('Sorry! The product %s is out of stock!', name));
        }

        return inStock;
    }

    validateQuantity() {
        const {
            product, quantity, showNotification, product: { type_id: typeId }
        } = this.props;
        const minQty = getMinQuantity(product);
        const maxQty = getMaxQuantity(product);
        const inRange = quantity >= minQty && quantity <= maxQty;
        const isValid = typeId === PRODUCT_TYPE.grouped || inRange;

        if (!isValid) {
            if (minQty > maxQty) {
                showNotification('info', __('The requested qty is not available!'));
            } else if (quantity < minQty) {
                showNotification('info', __('Sorry! Minimum quantity for this product is %s!', minQty));
            } else if (quantity > maxQty) {
                showNotification('info', __('Sorry! Maximum quantity for this product is %s!', maxQty));
            }

            this.setState({ isAdding: false });

            return false;
        }

        return isValid;
    }

    _getAddress() {
        const {
            customer: {
                addresses = [],
                default_shipping = ''
            } = {}
        } = this.props;
        const address = addresses.find(({ id }) => id === Number(default_shipping)) || {};

        return trimCheckoutCustomerAddress(address);
    }

    async getPaymentMethods() {
        const { showError } = this.props;
        const { cartId } = this.state;

        try {
            const { getPaymentMethods: paymentMethods } = await fetchQuery(CheckoutQuery.getPaymentMethodsQuery(
                cartId, true
            ));

            this.setState({ paymentMethods });

            return paymentMethods;
        } catch (e) {
            this.setState({ isLoading: false });
            showError(e);
            return null;
        }
    }

    setDetailsStep(orderId = null) {
        const { showCheckoutPopup } = this.props;

        if (orderId) {
            this.setState({ orderId });
        }
        history.replace('/');
        showCheckoutPopup(CHECKOUT_ORDER_POPUP_TYPES.success, orderId);
        this.setState({ isLoading: false });
    }

    determineCheckoutStepFromUrl() {
        return null;
    }

    render() {
        return (
            <GiftCardAddToCart
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardAddToCartContainer);
