/**
 * Mageplaza Affiliate compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import CheckoutOrderSummaryPriceLine from 'Component/CheckoutOrderSummaryPriceLine';

function renderAffiliateDiscount(instance) {
    const { props } = instance;
    const {
        totals: {
            prices: {
                quote_currency_code = null,
                affiliate_discount_data
            } = {}
        }
    } = props;
    const { discount_amount = 0 } = affiliate_discount_data || {};

    if (!discount_amount) {
        return null;
    }

    return (
        <CheckoutOrderSummaryPriceLine
          price={ -discount_amount }
          currency={ quote_currency_code }
          title={ __('Affiliate Discount') }
        />
    );
}

const renderDiscount = (args, callback, instance) => (
    <>
        { callback(...args) }
        { renderAffiliateDiscount(instance) }
    </>
);

export default {
    'Component/CheckoutOrderSummary/Component': {
        'member-function': {
            renderDiscount
        }
    }
};
