/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/** @namespace Scandiweb/AmastyGiftCards/Util/GetPrice/getPriceForPDP */
export const getPriceForPDP = (prices, allowCustomAmount, minPrice = 1, maxPrice) => {
    if (prices.length < 1) {
        return [minPrice, maxPrice];
    }
    const { value: minValue } = prices[0];
    const { value: maxValue } = prices[prices.length - 1];

    if (allowCustomAmount > 0 && +minPrice < +minValue && +maxPrice > +maxValue) {
        return [+minPrice || 1, +maxPrice];
    }

    if (allowCustomAmount > 0 && +minPrice > +minValue && +maxPrice > +maxValue) {
        return [+minValue, +maxPrice];
    }

    if (allowCustomAmount > 0 && +minPrice < +minValue && +maxPrice < +maxValue) {
        return [+minPrice || 1, +maxValue];
    }

    // Default - No open amount
    return [minValue, maxValue];
};

/** @namespace Scandiweb/AmastyGiftCards/Util/GetPrice/getPriceForPLP */
export const getPriceForPLP = (prices, allowCustomAmount, minPrice = 0) => {
    if (prices.length < 1) {
        return { label: __('From  '), value: minPrice || 1 };
    }

    const firstPrice = prices[0].value;

    if (allowCustomAmount < 1 && !prices) {
        return { label: __('From  '), value: 1 };
    }

    if (allowCustomAmount < 1 && prices.length > 1) {
        return { label: __('From  '), value: firstPrice };
    }

    if (allowCustomAmount > 0 && !minPrice) {
        return { label: __('From  '), value: 1 };
    }

    if (allowCustomAmount > 0 && firstPrice > minPrice) {
        return { label: __('From  '), value: minPrice };
    }

    if (allowCustomAmount > 0 && firstPrice < minPrice) {
        return { label: __('From  '), value: firstPrice };
    }

    // Default - No custom amount enabled and single price
    return { label: '', value: firstPrice };
};
