/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { DL_VAL_CHECKOUT_BILLING_STEP } from '../../data/checkout';
import { fireCheckoutEvent } from '../../event/checkout';
import { GTM_EVENT_KEY_ADD_PAYMENT_INFO } from '../../util/events';
import { pushToDataLayer } from '../../util/push';

const addFireCheckoutOptionEventForBilling = (args, callback, instance) => {
    callback(...args);

    const { paymentMethod } = instance.state;
    const {
        totals: {
            prices: {
                quote_currency_code,
                subtotal_including_tax: {
                    value
                } = {}
            } = {}
        } = {}
    } = instance.props;

    pushToDataLayer({
        event: GTM_EVENT_KEY_ADD_PAYMENT_INFO,
        ecommerce: {
            currency: quote_currency_code,
            value,
            payment_type: paymentMethod
        }
    });
};

const addFireCheckoutEventForBilling = (args, callback) => {
    callback(...args);
    fireCheckoutEvent(DL_VAL_CHECKOUT_BILLING_STEP);
};

export default {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            onBillingSuccess: addFireCheckoutOptionEventForBilling,
            componentDidMount: addFireCheckoutEventForBilling
        }
    }
};
