/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { lazy } from 'react';

import GiftCardProductActions from '../../component/GiftCardProductActions';
import { AM_GIFTCARD_PRODUCT_TYPE } from '../../component/GiftCardProductActions/GiftCardProductActions.config';
import { AmastyGiftCardsProvider } from '../../context/AmGiftCards';

export const GiftCardProductGallery = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "amasty-gift-cards" */
    '../../component/GiftCardProductGallery'
));

const renderGiftCardProductContent = (args, callback, instance) => {
    const {
        getLink,
        dataSource,
        areDetailsLoaded,
        activeProduct: {
            type_id: typeId,
            id: productId
        },
        setActiveProduct,
        parameters
    } = instance.props;

    if (typeId !== AM_GIFTCARD_PRODUCT_TYPE) {
        return callback(...args);
    }

    return (
        <div block="ProductPage" elem="GiftCardPageWrapper">
            <GiftCardProductActions
              getLink={ getLink }
              product={ dataSource }
              parameters={ parameters }
              areDetailsLoaded={ areDetailsLoaded }
              setActiveProduct={ setActiveProduct }
              productId={ productId }
            />
        </div>
    );
};

const renderNull = (args, callback, instance) => {
    const {
        activeProduct: {
            type_id: typeId
        }
    } = instance.props;

    if (typeId !== AM_GIFTCARD_PRODUCT_TYPE) {
        return callback(...args);
    }

    return null;
};

const addAmastyGiftCardsContextProvider = (member) => [
    (children) => (
         <AmastyGiftCardsProvider>
             { children }
         </AmastyGiftCardsProvider>
    ),
    ...member
];

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            renderProductPageContent: renderGiftCardProductContent,
            renderProductTabs: renderNull
        }
    },
    'Component/App/Component': {
        'member-property': {
            contextProviders: addAmastyGiftCardsContextProvider
        }
    }
};
