/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { fetchQuery } from 'Util/Request';

import CheckStatusQuery from '../../query/CheckStatus.query';
import CodeStatusChecker from './CodeStatusChecker.component';

/** @namespace Scandiweb/AmastyGiftCards/Component/CodeStatusChecker/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Scandiweb/AmastyGiftCards/Component/CodeStatusChecker/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showError: (message) => dispatch(showNotification('error', message))
});

/** @namespace Scandiweb/AmastyGiftCards/Component/CodeStatusChecker/Container */
export class CodeStatusCheckerContainer extends PureComponent {
    static propTypes = {
        showError: PropTypes.func.isRequired
    };

    containerFunctions = {
        handleClick: this.handleClick.bind(this),
        inputHandler: this.inputHandler.bind(this)
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            chosenInput: ''
        };
    }

    containerProps() {
        const { checkGiftCard } = this.state;

        return { checkGiftCard };
    }

    inputHandler(event) {
        const {
            target: { value }
        } = event;

        this.setState({ chosenInput: value });
    }

    async handleClick() {
        const { chosenInput = '' } = this.state;
        const { showError } = this.props;

        try {
            const {
                checkGiftCard
            } = await fetchQuery(CheckStatusQuery.checkGiftCardStatus(chosenInput.trim()));

            if (!checkGiftCard) {
                return;
            }

            this.setState({ checkGiftCard });
        } catch (error) {
            const {
                debugMessage = ''
            } = error?.length ? error[0] : error || {};

            showError(debugMessage);
        }
    }

    render() {
        return (
            <CodeStatusChecker
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CodeStatusCheckerContainer);
