/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import MyAccountLoader from '../component/MyAccountLoader';
import SocialLoginButtons from '../component/SocialLoginButtons';

const renderSocialAuth = (args, callback, instance) => {
    const { isCheckout } = instance.props;

    if (!isCheckout) {
        return callback(...args);
    }

    return (
        <>
            { callback(...args) }
            <SocialLoginButtons />
        </>
    );
};

const addMyAccountLoaderToRender = (args, callback, _instance) => (
    <>
        { callback(...args) }
        <MyAccountLoader />
    </>
);

export const config = {
    'Component/MyAccountSignIn/Component': {
        'member-function': {
            renderSocialAuth,
            render: addMyAccountLoaderToRender
        }
    }
};

export default config;
