/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import MyAccountLoader from '../component/MyAccountLoader';
import SocialLoginButtons from '../component/SocialLoginButtons';

const addSocialButtonsToRender = () => (
    <SocialLoginButtons />
);

const addMyAccountLoaderToRender = (args, callback, _instance) => (
    <>
        { callback(...args) }
        <MyAccountLoader />
    </>
);

export const config = {
    'Component/MyAccountOverlay/Component': {
        'member-function': {
            renderSocialIcons: addSocialButtonsToRender,
            render: addMyAccountLoaderToRender
        }
    }
};

export default config;
