/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import AmastyGiftCardProductQuery from '../../query/AmastyGiftCardProduct.query';

export const addGiftCardFormFieldsToProductList = (args, callback, _instance) => {
    const item = callback(...args);

    item.addField(AmastyGiftCardProductQuery.getAmGiftCardFragment());

    return item;
};

export default {
    'Query/ProductList/Query': {
        'member-function': {
            _getItemsField: addGiftCardFormFieldsToProductList
        }
    }
};
