/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable no-unused-vars */

import { sha256 } from 'js-sha256';

import { roundPrice } from 'Util/Price';
import { fetchQuery } from 'Util/Request';
import getStore from 'Util/Store';

import PurchaseQuery from '../query/Purchase.query';
import { waitForCallback } from '../util/wait';
import { DL_VAL_NOT_APPLICABLE } from './common';
import {
    DL_VAL_EXISTING_CUSTOMER,
    getCustomerData, NEW_CUSTOMER,
    USER_EXISTING_CUSTOMER
} from './customer';
import { getProductDimensionsData } from './product';

/** @namespace Scandiweb/Gtm/Data/Purchase/getPurchaseProductsData */
export const getPurchaseProductsData = async (purchase) => {
    const { products } = purchase;

    return Promise.all(products.map(async (product) => {
        const {
            category,
            id,
            name,
            price,
            quantity
        } = product;
        const dimensions = await getProductDimensionsData(product);

        const idWithoutVariant = id.includes('-') ? id.split('-')[0] : id;

        return {
            item_id: idWithoutVariant,
            item_name: name,
            price: Number(price),
            quantity,
            item_brand: category,
            ...(Object.keys(dimensions).length > 0 ? dimensions : undefined)
        };
    }));
};

/** @namespace Scandiweb/Gtm/Data/Purchase/getPurchaseShippingData */
export const getPurchaseShippingData = (purchase) => {
    const { shippingAddress, additional_data } = purchase;

    if (!additional_data || !shippingAddress) {
        return {};
    }

    const {
        city,
        postcode,
        region,
        region_id,
        street
    } = shippingAddress;

    return {
        shipping_city: city,
        shipping_region: region,
        shipping_country_id: region_id,
        shipping_street: street.replace(/\r?\n|\r/g, ' '),
        shipping_postcode: postcode
    };
};

/** @namespace Scandiweb/Gtm/Data/Purchase/getPurchaseCustomerData */
export const getPurchaseCustomerData = async (purchase) => {
    const { additional_data } = purchase;

    if (!additional_data) {
        return {};
    }

    return {
        ...await getCustomerData(),
        shipping_email: getStore().getState().CheckoutReducer?.email || ''
    };
};

/** @namespace Scandiweb/Gtm/Data/Purchase/getPurchaseEventData */
export const getPurchaseEventData = async (orderId, guestQuoteId) => {
    // vvv Wait for currency code
    await waitForCallback(() => getStore().getState().ConfigReducer?.currencyData?.current_currency_code);

    const currencyCode = getStore().getState().ConfigReducer?.currencyData?.current_currency_code;

    const query = PurchaseQuery.getPurchaseField(orderId, guestQuoteId);
    const { purchase } = await fetchQuery(query);
    const {
        orderPaymentMethod,
        orderShippingMethod,
        revenue,
        tax,
        shipping,
        coupon,
        discount_amount
    } = purchase;

    const { customerId, userExistingCustomer, customerEmail } = await getCustomerData();

    return {
        ecommerce: {
            order_payment_method: orderPaymentMethod,
            discount_amount,
            customer_email: (customerEmail === DL_VAL_NOT_APPLICABLE) ? customerEmail : sha256(customerEmail),
            customer_id: customerId,
            customer_status: userExistingCustomer === DL_VAL_EXISTING_CUSTOMER ? USER_EXISTING_CUSTOMER : NEW_CUSTOMER,
            currency: currencyCode,
            transaction_id: orderId,
            value: Number(revenue),
            tax: Number(tax),
            shipping: Number(shipping),
            coupon: coupon === null ? '' : coupon,
            items: await getPurchaseProductsData(purchase)
        }
    };
};
