/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

const renderGiftCardPrice = (args, _callback, instance) => {
    const [price, label] = args;
    const {
        discountPercentage
    } = instance.props;
    const {
        value: priceValue,
        valueFormatted: priceFormatted = 0
    } = price;

    const { itemProp = null, content = null } = instance.getCurrentPriceSchema();

    // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
    const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';

    if ((!priceValue && priceValue !== 0)) {
        return null;
    }

    return (
        <PriceSemanticElementName block="ProductPrice" elem="Price">
            { instance.renderPriceBadge(label) }
            <span
              itemProp={ itemProp }
              content={ content }
              block="ProductPrice"
              elem="PriceValue"
            >
                { priceFormatted }
            </span>
        </PriceSemanticElementName>
    );
};

export default {
    'Component/ProductPrice/Component': {
        'member-function': {
            renderPrice: renderGiftCardPrice
        }
    }

};
