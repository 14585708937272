/**
 * Mageplaza Affiliate compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import MyAffiliateDiscountCodeComponent from '../component/MyAffiliateDiscountCode';

const addAffiliateCouponCode = (args, callback) => (
    <>
        { callback(...args) }
        <MyAffiliateDiscountCodeComponent />
    </>
);

export default {
    'Route/Checkout/Component': {
        'member-function': {
            renderDiscountCode: addAffiliateCouponCode
        }
    }
};
