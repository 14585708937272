/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

const addGiftCardsToPriceLine = (args, callback, instance) => {
    const { total: { giftAmount } } = instance.props;
    const discountedGiftAmount = -Math.abs(giftAmount);

    if (!+giftAmount) {
        return callback(...args);
    }

    return (
        <>
            { instance.renderPriceLine(__('Gift Card'), discountedGiftAmount) }
            { callback(...args) }
        </>
    );
};

export default {
    'Component/MyAccountOrderTotals/Component': {
        'member-function': {
            renderBaseGrandTotal: addGiftCardsToPriceLine
        }
    }
};
