/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation, fetchQuery } from 'Util/Request';

import { GIFT_CARDS_TAB } from '../../config';
import { AmastyGiftCardsContext } from '../../context/AmGiftCards';
import ApplyGiftCardMutation from '../../query/ApplyGiftCard.mutation';
import CheckStatusQuery from '../../query/CheckStatus.query';
import GiftCardInput from './GiftCardInput.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Scandiweb/AmastyGiftCards/Component/GiftCardInput/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    appliedGiftCards: state.CartReducer?.cartTotals?.appliedGiftCards?.giftCards,
    isAssignedToCustomer: state.ConfigReducer?.AmastyGiftCardsConfig?.allowAssignToCustomer,
    isSignedIn: state.MyAccountReducer.isSignedIn,
    cartId: state.CartReducer?.cartTotals?.id,
    currency: state.ConfigReducer.currencyData
});

/** @namespace Scandiweb/AmastyGiftCards/Component/GiftCardInput/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateCart: async () => {
        CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
        );
    },
    showError: (message) => dispatch(showNotification('error', message)),
    showNotification: (status, message) => dispatch(showNotification(status, message))

});

/** @namespace Scandiweb/AmastyGiftCards/Component/GiftCardInput/Container */
export class GiftCardInputContainer extends PureComponent {
    static propTypes = {
        updateCart: PropTypes.func.isRequired,
        appliedGiftCards: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string,
                id: PropTypes.number
            })
        ),
        isAssignedToCustomer: PropTypes.bool.isRequired,
        isSignedIn: PropTypes.bool.isRequired,
        cartId: PropTypes.string.isRequired,
        page: PropTypes.string.isRequired,
        showNotification: PropTypes.func.isRequired,
        showError: PropTypes.func.isRequired,
        mix: PropTypes.shape({
            block: PropTypes.string,
            elem: PropTypes.string
        }),
        isExpandable: PropTypes.bool,
        currency: PropTypes.shape({
            current_currency_code: PropTypes.string
        }).isRequired
    };

    static defaultProps = {
        appliedGiftCards: [],
        isExpandable: false,
        mix: {}
    };

    inputFieldRef = createRef();

    static contextType = AmastyGiftCardsContext;

    containerFunctions = {
        inputHandler: this.inputHandler.bind(this),
        handleUnFocus: this.handleUnFocus.bind(this),
        handleFocus: this.handleFocus.bind(this),
        handleClick: this.handleClick.bind(this),
        handleApply: this.handleApply.bind(this)
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            myAccountCards: [],
            isLoading: true,
            chosenInput: '',
            isFocused: false
        };

        this.fetchMyAccountCards();
    }

    async handleApply() {
        const { chosenInput = '' } = this.state;
        const { handleAddToAccount, setIsButtonLoading } = this.context;
        const {
            updateCart,
            page,
            showNotification,
            cartId
        } = this.props;
        const { current = {} } = this.inputFieldRef;

        setIsButtonLoading(true);

        if (page === GIFT_CARDS_TAB) {
            handleAddToAccount(chosenInput.trim());
            this.setState({ chosenInput: '' });
            current.value = '';

            return;
        }

        const {
            applyGiftCard: { error, message }
        } = await fetchMutation(ApplyGiftCardMutation.applyGiftCard(chosenInput.trim(), cartId));

        this.setState({ chosenInput: '' });
        current.value = '';

        if (!error) {
            await updateCart();
            showNotification('success', message);
            setIsButtonLoading(false);

            return;
        }

        showNotification('error', message);
        setIsButtonLoading(false);
    }

    inputHandler(event) {
        const {
            target: { value }
        } = event;

        this.setState({ chosenInput: value });
    }

    handleUnFocus() {
        this.setState({ isFocused: false });
    }

    handleFocus() {
        this.setState({ isFocused: true });
    }

    async handleClick() {
        const { chosenInput = '' } = this.state;
        const { showError } = this.props;

        try {
            const {
                checkGiftCard
            } = await fetchQuery(CheckStatusQuery.checkGiftCardStatus(chosenInput.trim()));

            if (!checkGiftCard) {
                return;
            }

            this.setState({ checkGiftCard });
        } catch (error) {
            const {
                message = ''
            } = error?.length ? error[0] : error || {};

            showError(message);
        }
    }

    async fetchMyAccountCards() {
        const { fetchMyAccountCards } = this.context;

        fetchMyAccountCards();
    }

    containerProps() {
        const {
            chosenInput,
            isFocused,
            checkGiftCard
        } = this.state;
        const {
            myAccountCards,
            isButtonLoading
        } = this.context;
        const {
            appliedGiftCards,
            isAssignedToCustomer,
            isSignedIn,
            cartId,
            page,
            mix,
            isExpandable
        } = this.props;

        return {
            myAccountCards,
            chosenInput,
            appliedGiftCards,
            isAssignedToCustomer,
            isSignedIn,
            cartId,
            isFocused,
            page,
            mix,
            isExpandable,
            checkGiftCard,
            isButtonLoading,
            inputFieldRef: this.inputFieldRef
        };
    }

    render() {
        return (
            <GiftCardInput
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardInputContainer);
