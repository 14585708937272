/**
 * Amasty Special Promotions Pro compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastySpecialPromotionsPro/Query/AmastySpecialPromotionsPro/Query */
export class AmastySpecialPromotionsProQuery {
    getDiscounts() {
        return new Field('amasty_discounts')
            .addFieldList(this._getDiscountsFieldList());
    }

    _getDiscountsFieldList() {
        return [
            'rule_id',
            'name',
            'discount_amount'
        ];
    }
}

export default new AmastySpecialPromotionsProQuery();
