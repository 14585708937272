/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getCartId } from 'Util/Cart';

import { firePurchaseEvent } from '../../event/checkout';

const addFirePurchaseEvent = (args, callback) => {
    const [orderId] = args;
    const cartId = getCartId();
    // ^^^ getting this here, as it will soon be reset
    firePurchaseEvent(orderId, cartId);
    // vvv This must be called after guest quote id is obtained
    callback(...args);
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            setDetailsStep: addFirePurchaseEvent
        }
    }
};
