/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import GiftCardExpandable from '../../component/GiftCardExpandable';
import GiftCardInput from '../../component/GiftCardInput';
import { CART_PAGE } from '../../config';

const mapStateToProps = (args, callback, _instance) => {
    const [state] = args;

    return {
        ...callback(...args),
        giftCardCheckoutViewType: state.ConfigReducer?.AmastyGiftCardsConfig?.accountCheckoutViewType,
        giftCardGeneralActive: state.ConfigReducer?.AmastyGiftCardsConfig?.generalActive,
        allowedProductTypes: state.ConfigReducer?.AmastyGiftCardsConfig?.allowedProductTypes
    };
};

const addViewTypeToContainerProps = (args, callback, instance) => {
    const {
        giftCardCheckoutViewType,
        giftCardGeneralActive,
        allowedProductTypes
    } = instance.props;

    return {
        ...callback(args),
        giftCardCheckoutViewType,
        giftCardGeneralActive,
        allowedProductTypes
    };
};

const addGiftCardBeforeDiscount = (args, callback, instance) => {
    const {
        device: { isMobile },
        giftCardGeneralActive,
        totals: { items },
        allowedProductTypes
    } = instance.props;

    if (!giftCardGeneralActive || items.length === 0) {
        return callback(...args);
    }

    const itemTypes = items.map(({ product: { type_id: type } }) => type);
    const allowedProductTypesArray = allowedProductTypes.split(',');
    const isAllowedProductType = itemTypes.map((type) => allowedProductTypesArray.indexOf(type));

    if (isAllowedProductType.indexOf(-1) > -1) {
        return callback(...args);
    }

    if (isMobile) {
        return (
            <>
                <GiftCardExpandable page={ CART_PAGE } mix={ { block: 'CartPage', elem: 'GiftCard' } } />
                { callback(...args) }
            </>
        );
    }

    return (
        <>
            <GiftCardInput page={ CART_PAGE } mix={ { block: 'GiftCardInput', elem: 'CartPage' } } />
            { callback(...args) }
        </>
    );
};

export default {
    'Route/CartPage/Component': {
        'member-function': {
            renderDiscountCode: addGiftCardBeforeDiscount
        }
    },
    'Route/CartPage/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/CartPage/Container': {
        'member-function': {
            containerProps: addViewTypeToContainerProps
        }
    }
};
