/**
 * Mageplaza Affiliate compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

export const addAffiliateDiscountDataField = (args, callback) => ([
    ...callback(...args),
    new Field('affiliate_discount_data')
        .addFieldList([
            'discount_amount',
            'coupon_code'
        ])
]);

export default {
    'Query/Cart/Query': {
        'member-function': {
            _getPricesFields: addAffiliateDiscountDataField
        }
    }
};
