/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    GTM_EVENT_KEY_SELECT_ITEM
} from '../../util/events';
import { pushToDataLayer } from '../../util/push';

const selectItem = (args, callback, instance) => {
    const {
        currentCurrencyCode,
        item: {
            sku,
            title
        } = {}
    } = instance.props;

    if (sku) {
        pushToDataLayer({
            event: GTM_EVENT_KEY_SELECT_ITEM,
            ecommerce: {
                item_list_name: 'Menu',
                item_list_id: 'menu',
                currency: currentCurrencyCode,
                items: [{
                    item_id: sku,
                    item_name: title
                }]
            }
        });
    }

    return callback(...args);
};

export default {
    'Component/MenuItem/Container': {
        'member-function': {
            onItemClick: selectItem
        }
    }
};
