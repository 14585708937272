/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */

import FIELD_TYPE from '@scandipwa/scandipwa/src/component/Field/Field.config';
import PropTypes from 'prop-types';
import { createRef } from 'react';

import CarouselScroll from 'Component/CarouselScroll';
import Field from 'Component/Field';
import Image from 'Component/Image';
import {
    ProductActionsComponent as SourceProductActionsComponent
} from 'Component/ProductActions/ProductActions.component';
import ProductGalleryThumbnailImage from 'Component/ProductGalleryThumbnailImage';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { isSignedIn } from 'Util/Auth';
import { isCrawler, isSSR } from 'Util/Browser';
import { formatPrice } from 'Util/Price';
import { VALIDATION_INPUT_TYPE_INPUT } from 'Util/Validator/Config';

import { getPriceForPDP } from '../../util/GetPrice';
import GiftCardAddToCart from '../GiftCardAddToCart';
import GiftCardEmailPreview from '../GiftCardEmailPreview/GiftCardEmailPreview.component';
import {
    AM_GIFT_CARD_TYPE_BOTH, AM_GIFT_CARD_TYPE_PHYSICAL,
    AM_GIFTCARD_MESSAGE, AM_GIFTCARD_MOBILE, AM_GIFTCARD_RECIPIENT_NAME, AM_GIFTCARD_SENDER_NAME
} from './GiftCardProductActions.config';

import './GiftCardProductActions.style';

/** @namespace Scandiweb/AmastyGiftCards/Component/GiftCardProductActions/Component */
export class GiftCardProductActionsComponent extends SourceProductActionsComponent {
    static propTypes = {
        giftCardData: PropTypes.shape({
            allowOpenAmount: PropTypes.string,
            images: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number,
                    src: PropTypes.string
                })
            ),
            prices: PropTypes.arrayOf(
                PropTypes.shape({
                    price_id: PropTypes.string,
                    attribute_id: PropTypes.string,
                    value: PropTypes.string
                })
            ),
            type: PropTypes.string,
            openAmountMax: PropTypes.string,
            openAmountMin: PropTypes.string,
            isShowGiftMessage: PropTypes.string
        }),
        hasError: PropTypes.func.isRequired,
        formFields: PropTypes.string,
        giftCardChosenOptions: PropTypes.shape({}),
        handleScheduleDelivery: PropTypes.func.isRequired,
        handlePreviewGiftCard: PropTypes.func.isRequired,
        handleInputChange: PropTypes.func.isRequired,
        handleImageClick: PropTypes.func.isRequired,
        handleCustomPrice: PropTypes.func.isRequired,
        handlePriceSelect: PropTypes.func.isRequired,
        handleGiftCardType: PropTypes.func.isRequired,
        handleScheduleDeliveryTimezone: PropTypes.func.isRequired,
        handleScheduleDeliveryDate: PropTypes.func.isRequired,
        validatePriceSelect: PropTypes.func.isRequired,
        handleBackButton: PropTypes.func.isRequired,
        registerSharedElementDestination: PropTypes.func.isRequired,
        handleLogin: PropTypes.func.isRequired
    };

    static defaultProps = {
        giftCardData: {
            allowOpenAmount: false,
            images: [],
            prices: [],
            type: null,
            openAmountMax: null,
            openAmountMin: null,
            isShowGiftMessage: null
        },
        formFields: ''
    };

    imageRef = createRef();

    componentDidMount() {
        this.updateSharedDestinationElement();
    }

    componentDidUpdate(prevProps) {
        const {
            productId
        } = this.props;

        const {
            productId: prevProductId
        } = prevProps;

        if (productId !== prevProductId) {
            this.updateSharedDestinationElement();
        }
    }

    updateSharedDestinationElement() {
        const { registerSharedElementDestination } = this.props;

        registerSharedElementDestination(this.imageRef);
    }

    // vvv Amasty Gift Card functions

    renderAmGifCardType() {
        const {
            giftCardData: { type },
            handleGiftCardType
        } = this.props;

        if (type !== AM_GIFT_CARD_TYPE_BOTH) {
            return null;
        }

        const label = __('Want to send a physical gift card or prefer to keep things digital?');
        const options = [
            {
                id: 1,
                label: __('Physical Gift Card'),
                value: 2
            },
            {
                id: 2,
                label: __('e-Gift Card'),
                value: 1
            },
            {
                id: 3,
                label: __('Both'),
                value: 3
            }
        ];

        return (
            <Field
              type={ FIELD_TYPE.select }
              options={ options }
              events={ { onChange: (e) => handleGiftCardType(e) } }
              label={ label }
              addRequiredTag
              validateOn={ ['onChange'] }
              validationRule={ { isRequired: true } }
              mix={ { block: 'GiftCardActions', elem: 'GiftCardType' } }
            />
        );
    }

    renderAmGiftCardPriceSelect() {
        const {
            giftCardData: { prices },
            giftCardChosenOptions: {
                isCustomPriceValid = false,
                price
            },
            currency,
            handlePriceSelect,
            validatePriceSelect
        } = this.props;

        if (prices?.length < 2 || prices === undefined) {
            return null;
        }

        const isPriceRequired = !isCustomPriceValid && !price;
        const label = __('Card Value in %s', currency);
        const options = prices.map((price) => {
            const { value, price_id: id } = price;
            const formattedValue = formatPrice(value, currency);

            return { id: +id, value, label: formattedValue };
        });

        return (
            <Field
              type={ FIELD_TYPE.select }
              options={ options }
              attr={ {
                  selectPlaceholder: __('Select price...'),
                  value: price || 1
              } }
              validateOn={ ['onChange', 'onSubmit'] }
              validationRule={ {
                  isRequired: isPriceRequired,
                  match: validatePriceSelect.bind(this)
              } }
              events={ {
                  onChange: (val) => handlePriceSelect(val)
              } }
              label={ label }
              addRequiredTag
              mix={ { block: 'GiftCardActions', elem: 'PredefinedPrices' } }
            />
        );
    }

    renderAmGiftCardCustomPrice() {
        const {
            giftCardData: {
                openAmountMin,
                openAmountMax,
                allowOpenAmount
            },
            currency,
            handleCustomPrice,
            validateCustomPrice,
            getCustomPricePlaceholder
        } = this.props;

        if (allowOpenAmount === '0') {
            return null;
        }

        const formattedMinPrice = formatPrice(openAmountMin, currency);
        const formattedMaxPrice = formatPrice(openAmountMax, currency);
        const placeholder = getCustomPricePlaceholder(formattedMinPrice, formattedMaxPrice);

        return (
            <Field
              type={ FIELD_TYPE.number }
              value={ 0 }
              attr={ { placeholder } }
              events={ { onChange: handleCustomPrice } }
              validateOn={ ['onSubmit'] }
              validationRule={ {
                  match: (value) => validateCustomPrice(value)
              } }
              label={ __('Other amount:') }
              mix={ { block: 'GiftCardActions', elem: 'CustomPrice' } }
            />
        );
    }

    renderAmGiftCardCustomPriceField() {
        const {
            giftCardData: { allowOpenAmount }
        } = this.props;

        if (!allowOpenAmount) {
            return null;
        }

        return this.renderAmGiftCardCustomPrice();
    }

    renderAdditionalPicture(media, index) {
        const { id, src } = media;

        return (
            <ProductGalleryThumbnailImage
              key={ index }
              media={ {
                  id,
                  label: `AmGiftCardImage-${id}`,
                  media_type: 'image',
                  thumbnail: { url: src }
              } }
            />
        );
    }

    renderAmGiftCardCardDesign() {
        const {
            giftCardData: { images = [] },
            handleImageClick,
            activeImageIndex
        } = this.props;

        if (!images || images.length < 2) {
            return null;
        }

        return (
            <div
              block="GiftCardActions"
              elem="CarouselScroll"
              mods={ { isImageZoomPopupActive: true } }
            >
                <div
                  block="GiftCardActions"
                  elem="CardDesignLabel"
                >
                    { __('Choose card design') }
                </div>
                <CarouselScroll
                  activeItemId={ activeImageIndex }
                  onChange={ handleImageClick }
                  showedItemCount={ 5 }
                  isImageZoomPopupActive={ false }
                  isFromActions
                >
                    { images.map(this.renderAdditionalPicture.bind(this)) }
                </CarouselScroll>
            </div>
        );
    }

    renderSenderName() {
        const {
            formFields,
            handleInputChange,
            giftCardChosenOptions: {
                type = null,
                senderName = ''
            }
        } = this.props;

        if (!formFields.split(',').includes(AM_GIFTCARD_SENDER_NAME)) {
            return null;
        }

        // eslint-disable-next-line eqeqeq
        const isSingle = type == AM_GIFT_CARD_TYPE_PHYSICAL;
        return (
            <Field
              attr={ {
                  placeholder: __('Enter Your Name'),
                  value: senderName
              } }
              type={ FIELD_TYPE.text }
              events={ { onChange: (event) => handleInputChange(event.target.value, 'senderName') } }
              label={ __('Your Name') }
              addRequiredTag
              validateOn={ ['onChange'] }
              validationRule={ { isRequired: true } }
              mix={ { block: 'GiftCardActions', elem: 'SenderName', mods: { isSingle } } }
            />
        );
    }

    renderRecipientName() {
        const {
            formFields,
            handleInputChange,
            giftCardChosenOptions: {
                type = null,
                recipientName = ''
            } = {}
        } = this.props;

        if (
            !formFields.split(',').includes(AM_GIFTCARD_RECIPIENT_NAME)
            // eslint-disable-next-line eqeqeq
            || type == AM_GIFT_CARD_TYPE_PHYSICAL
        ) {
            return null;
        }

        return (
            <Field
              attr={ {
                  placeholder: __('Enter Recipient Name'),
                  value: recipientName
              } }
              type={ FIELD_TYPE.text }
              events={ {
                  onChange: (event) => handleInputChange(event.target.value, 'recipientName')
              } }
              label={ __('Recipient Name') }
              addRequiredTag
              validateOn={ ['onChange'] }
              validationRule={ { isRequired: true } }
              mix={ { block: 'GiftCardActions', elem: 'RecipientName' } }
            />
        );
    }

    renderRecipientEmail() {
        const {
            handleInputChange,
            giftCardChosenOptions: {
                type = null,
                recipientEmail = ''
            } = {}
        } = this.props;

        if (
            type === null
            // eslint-disable-next-line eqeqeq
            || type == AM_GIFT_CARD_TYPE_PHYSICAL
        ) {
            return null;
        }

        return (
            <Field
              attr={ {
                  placeholder: __('Enter Recipient Email'),
                  value: recipientEmail
              } }
              type={ FIELD_TYPE.email }
              events={ {
                  onChange: (event) => handleInputChange(event.target.value, 'recipientEmail')
              } }
              label={ __('Recipient Email') }
              addRequiredTag
              validateOn={ ['onChange'] }
              validationRule={ { isRequired: true, inputType: VALIDATION_INPUT_TYPE_INPUT.email } }
              mix={ { block: 'GiftCardActions', elem: 'RecipientEmail' } }
            />
        );
    }

    renderRecipientPhone() {
        const {
            formFields,
            handleInputChange,
            giftCardChosenOptions: {
                type,
                recipientPhone = ''
            } = {}
        } = this.props;

        if (
            !formFields.split(',').includes(AM_GIFTCARD_MOBILE)
            // eslint-disable-next-line eqeqeq
            || type == AM_GIFT_CARD_TYPE_PHYSICAL
        ) {
            return null;
        }

        return (
            <Field
              attr={ {
                  placeholder: __('Recipient Mobile Number'),
                  value: recipientPhone
              } }
              type={ FIELD_TYPE.tel }
              validateOn={ ['onChange'] }
              validationRule={ { inputType: 'numeric' } }
              events={ { onChange: (event) => handleInputChange(event.target.value, 'recipientPhone') } }
              label={ __('Recipient Phone') }
              subLabel={ __('Enter Number With Country code & without any sign. e.g:-(91xxxxxxx09)') }
              mix={ { block: 'GiftCardActions', elem: 'RecipientPhone' } }
            />
        );
    }

    renderMessage() {
        const {
            formFields,
            handleInputChange,
            giftCardChosenOptions: {
                message = ''
            } = {}
        } = this.props;

        if (!formFields.split(',').includes(AM_GIFTCARD_MESSAGE)) {
            return null;
        }

        return (
            <Field
              type={ FIELD_TYPE.textarea }
              attr={ {
                  rows: 5,
                  placeholder: __('Enter your message'),
                  value: message
              } }
              events={ { onChange: (event) => handleInputChange(event.target.value, 'message') } }
              label={ __('Message') }
              mix={ { block: 'GiftCardActions', elem: 'Message' } }
            />
        );
    }

    renderAmGiftCardDataFields() {
        return (
            <div mix={ { block: 'GiftCardActions', elem: 'GiftCardFields' } }>
                <div mix={ { block: 'GiftCardActions', elem: 'GiftCardNameFields' } }>
                    { this.renderSenderName() }
                    { this.renderRecipientName() }
                </div>
                { this.renderRecipientEmail() }
                { this.renderRecipientPhone() }
                { this.renderMessage() }
            </div>
        );
    }

    renderAmGiftCardDelivery() {
        const {
            formFields,
            handleScheduleDelivery,
            giftCardChosenOptions: {
                delivery = ''
            } = {}
        } = this.props;

        if (!formFields.split(',').includes('am_giftcard_date_delivery')) {
            return null;
        }

        const options = [
            { id: 1, label: __('Send now'), value: '0' },
            { id: 2, label: __('Send later'), value: '1' }
        ];

        return (
            <Field
              type={ FIELD_TYPE.select }
              options={ options }
              attr={ {
                  value: delivery
              } }
              events={ { onChange: (value) => handleScheduleDelivery(value) } }
              label={ __('Schedule delivery') }
              validateOn={ ['onChange'] }
              validationRule={ { isRequired: true } }
              addRequiredTag
              mix={ { block: 'GiftCardActions', elem: 'DeliveryField' } }
            />
        );
    }

    renderAmGiftCardDeliveryFields() {
        const {
            giftCardChosenOptions: {
                delivery = 1,
                date = '',
                timezone = ''
            } = {},
            giftCardData: { timezones },
            handleScheduleDeliveryTimezone,
            handleScheduleDeliveryDate
        } = this.props;

        if (delivery !== '1' || !timezones) {
            return null;
        }

        const options = JSON.parse(timezones).map((zone) => {
            const { label, value } = zone;

            return { label, value, id: value };
        });

        return (
            <>
                <Field
                  type={ FIELD_TYPE.date }
                  attr={ {
                      value: date
                  } }
                  events={ { onChange: (value) => handleScheduleDeliveryDate(value) } }
                  label={ __('Delivery Date') }
                  mix={ { block: 'GiftCardActions', elem: 'DeliveryDate' } }
                />
                 <Field
                   type={ FIELD_TYPE.select }
                   attr={ {
                       value: timezone
                   } }
                   options={ options }
                   events={ { onChange: (value) => handleScheduleDeliveryTimezone(value) } }
                   label={ __('Timezone') }
                   mix={ { block: 'GiftCardActions', elem: 'Timezone' } }
                 />
            </>
        );
    }

    renderGiftCardPreviewButton() {
        const {
            handlePreviewGiftCard,
            giftCardChosenOptions: { type }
        } = this.props;

        // eslint-disable-next-line eqeqeq
        if (!type || type == AM_GIFT_CARD_TYPE_PHYSICAL) {
            return null;
        }

        return (
            <button
              block="GiftCardActions"
              elem="LinkButton"
              onClick={ handlePreviewGiftCard }
              mix={ { block: 'Button' } }
            >
                { __('Preview') }
            </button>
        );
    }

    renderGiftCardEmailPreview() {
        const { formRef, emailPreviewHtmlText } = this.props;

        return (
            <GiftCardEmailPreview
              formRef={ formRef }
              content={ emailPreviewHtmlText }
            />
        );
    }

    renderGiftCardForm() {
        const { configFormRef = createRef() } = this.props;

        if (!isSignedIn()) {
            return this.renderSignInMessage();
        }

        return (
            <form
              ref={ configFormRef }
              mix={ { block: 'GiftCardActions', elem: 'GiftCardForm' } }
            >
                { this.renderAmGifCardType() }
                { this.renderAmGiftCardPriceSelect() }
                { this.renderAmGiftCardCustomPriceField() }
                { this.renderAmGiftCardCardDesign() }
                { this.renderAmGiftCardDataFields() }
                { this.renderGiftCardEmailPreview() }
                { this.renderAmGiftCardDelivery() }
                { this.renderAmGiftCardDeliveryFields() }
                { this.renderGiftCardPreviewButton() }
            </form>
        );
    }

    renderSignInMessage() {
        const { handleLogin } = this.props;

        return (
            <div
              block="GiftCardActions"
              elem="SignInWrapper"
            >
                <p
                  block="GiftCardActions"
                  elem="SignInMessage"
                >
                    { __('Please login to purchase a giftcard') }
                </p>
                <button
                  block="Button"
                  mix={ { block: 'GiftCardActions', elem: 'LoginButton' } }
                  onClick={ handleLogin }
                >
                    { __('Login') }
                </button>
            </div>
        );
    }

    renderPriceBadge(label) {
        if (!label) {
            return null;
        }

        return <span mix={ { block: 'ProductPrice', elem: 'PriceBadge' } }>{ label }</span>;
    }

    // vvv From ProductPrice component
    renderRegularPrice(value) {
        if (!value || value <= 0) {
            return null;
        }

        const { currency } = this.props;
        const formattedValue = formatPrice(value, currency);

        return (
            <span block="ProductPrice" elem="HighPrice">{ formattedValue }</span>
        );
    }

    // vvv From ProductPrice component
    renderBundlePrice(minValue, maxValue) {
        return (
            <>
                <div block="ProductPrice" elem="BundleFrom">
                    { minValue > 0 && this.renderPriceBadge(__('From')) }
                    { this.renderRegularPrice(minValue || 1) }
                </div>
                <div block="ProductPrice" elem="BundleTo">
                    { maxValue > 0 && this.renderPriceBadge(__('To')) }
                    { this.renderRegularPrice(maxValue) }
                </div>
            </>
        );
    }

    // vvv Overridden functions

    renderPrice() {
        const {
            giftCardData: {
                prices = [],
                allowOpenAmount,
                openAmountMin,
                openAmountMax
            }
            = {},
            giftCardChosenOptions: {
                price: chosenPrice,
                customPrice: chosenCustomPrice
            }
        } = this.props;
        const isRenderChosenPrice = chosenPrice || chosenCustomPrice;
        const [minValue, maxValue] = getPriceForPDP(prices, allowOpenAmount, openAmountMin, openAmountMax);

        if (
            !isRenderChosenPrice
            && allowOpenAmount < 1
            && minValue === maxValue
        ) {
            return (
                <div
                  block="GiftCardActions"
                  elem="PriceWrapper"
                >
                    { this.renderRegularPrice(minValue) }
                </div>
            );
        }

        if (!isRenderChosenPrice && prices.length < 1) {
            return (
                <div
                  block="GiftCardActions"
                  elem="PriceWrapper"
                >
                    { this.renderBundlePrice(minValue, maxValue) }
                </div>
            );
        }

        if (
            !isRenderChosenPrice
            && (prices.length >= 1
                 || (allowOpenAmount
                    && (openAmountMin || openAmountMax)))
        ) {
            return (
                <div
                  block="GiftCardActions"
                  elem="PriceWrapper"
                >
                    { this.renderBundlePrice(minValue, maxValue) }
                </div>
            );
        }

        return (
            <div
              block="GiftCardActions"
              elem="PriceWrapper"
            >
               { this.renderRegularPrice(chosenPrice || chosenCustomPrice) }
            </div>
        );
    }

    renderPriceWithSchema() {
        const {
            giftCardData: { prices = [] },
            getGiftCardPrices
        } = this.props;

        const [
            minFinalPrice = 0,
            maxFinalPrice = 0
        ] = getGiftCardPrices(prices);

        return (
            <div
              block="GiftCardActions"
              elem="PriceWrapper"
            >
                { this.renderSchema() }
                <meta
                  itemProp="highPrice"
                  content={ (minFinalPrice === maxFinalPrice) ? minFinalPrice : maxFinalPrice }
                />
                { this.renderPrice(minFinalPrice, maxFinalPrice) }
            </div>
        );
    }

    /**
     * Overridden to add label prop
     */
    renderAddToCartButton(layout = GRID_LAYOUT) {
        const {
            addToCart,
            inStock,
            quantity,
            getActiveProduct,
            updateSelectedValues,
            resetForm,
            hasError
        } = this.props;

        return (
            <GiftCardAddToCart
              mix={ { block: this.className, elem: 'AddToCart' } }
              addToCart={ addToCart }
              resetForm={ resetForm }
              isDisabled={ !inStock || !isSignedIn() }
              isIconEnabled={ false }
              hasError={ hasError }
              isGiftCard
              layout={ layout }
              updateSelectedValues={ updateSelectedValues }
              quantity={ quantity }
              product={ getActiveProduct() }
              label={ __('Pay Now') }
            />
        );
    }

    renderTitle() {
        return (
            <h2 block="GiftCardActions" elem="ActionsTitle">
                { __('Send your Winning Gift Today') }
            </h2>
        );
    }

    /**
     * Overridden to remove wishlist, compare and quantity buttons
     */
    renderAddToCartActionBlock() {
        return (
            <div
              block="ProductActions"
              elem="AddToCartWrapper"
              mods={ { isPrerendered: isSSR() || isCrawler() } }
            >
                { this.renderAddToCartButton() }
            </div>
        );
    }

    renderGiftCardImage() {
        const { giftCardImageSrc } = this.props;

        return (
            <div block="ProductGallery" elem="GiftCardImage" ref={ this.imageRef }>
                <Image
                  src={ giftCardImageSrc }
                  title="Gift Card Image"
                  alt="Gift Card Image"
                  isPlain
                />
            </div>
        );
    }

    renderTotalPrice() {
        return (
            <div block="GiftCardActions" elem="TotalPriceWrapper">
                <span
                  block="GiftCardActions"
                  elem="TotalText"
                >
                    { __('Total') }
                </span>
                <span
                  block="GiftCardActions"
                  elem="TotalValue"
                >
                    { this.renderPriceWithSchema() }
                </span>
            </div>
        );
    }

    renderBackButton() {
        const { handleBackButton } = this.props;

        return (
            <button
              block="GiftCardActions"
              elem="BackButton"
              mix={ { block: 'Button' } }
              onClick={ handleBackButton }
            >
                { __('Back') }
            </button>
        );
    }

    renderActions() {
        return (
            <div block="GiftCardActions" elem="ActionsWrapper">
                { this.renderBackButton() }
                { this.renderAddToCartActionBlock() }
            </div>
        );
    }

    renderText() {
        return (
            <span block="GiftCardActions" elem="TotalText">
                { __('Your gift card will be send upon Completion of your purchase') }
            </span>
        );
    }

    renderMobile() {
        return (
            <>
                <div block="GiftCardActions" elem="ImageWrapper">
                    { this.renderGiftCardImage() }
                </div>
                <div block="GiftCardActions" elem="FormWrapper">
                    { this.renderTitle() }
                    { this.renderProductAlerts() }
                    { this.renderGiftCardForm() }
                </div>
                <div block="GiftCardActions" elem="TotalWrapper">
                    { this.renderTotalPrice() }
                    { this.renderActions() }
                    { this.renderText() }
                </div>
            </>
        );
    }

    renderDesktop() {
        return (
            <>
                <div block="GiftCardActions" elem="FormWrapper">
                    { this.renderTitle() }
                    { this.renderProductAlerts() }
                    { this.renderGiftCardForm() }
                </div>
                <div block="GiftCardActions" elem="ImageWrapper">
                    { this.renderGiftCardImage() }
                    <div block="GiftCardActions" elem="TotalWrapper">
                        { this.renderTotalPrice() }
                        { this.renderActions() }
                        { this.renderText() }
                    </div>
                </div>
            </>
        );
    }

    render() {
        const { device: { isMobile = false } = {}, setValidator } = this.props;

        if (isMobile) {
            return (
                <article block="GiftCardActions" ref={ (elem) => setValidator(elem) }>
                    { this.renderMobile() }
                </article>
            );
        }

        return (
            <article block="GiftCardActions" ref={ (elem) => setValidator(elem) }>
                { this.renderDesktop() }
            </article>
        );
    }
}

export default GiftCardProductActionsComponent;
