/* eslint-disable react/no-unknown-property */
/* eslint-disable react/boolean-prop-naming */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './PopupHeaderSeparator.style';

/** @namespace Spinola/BaseTheme/Component/PopupHeaderSeparator/Component */
export class PopupHeaderSeparatorComponent extends PureComponent {
    static propTypes = {
        separatorText: PropTypes.string
    };

    static defaultProps = {
        separatorText: ''
    };

    renderSeparator() {
        const {
            separatorText
        } = this.props;

        if (separatorText) {
            return (
                <>
                    <span block="PopupHeaderSeparator" elem="SeparatorLine" />
                    <span block="PopupHeaderSeparator" elem="SeparatorText">
                        { separatorText }
                    </span>
                    <span block="PopupHeaderSeparator" elem="SeparatorLine" />
                </>
            );
        }

        return <span block="PopupHeaderSeparator" elem="SeparatorLine" />;
    }

    render() {
        return (
            <div block="PopupHeaderSeparator">
                { this.renderSeparator() }
            </div>
        );
    }
}

export default PopupHeaderSeparatorComponent;
