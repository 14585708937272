/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { UPDATE_PRODUCT_LIST_ITEMS } from 'Store/ProductList/ProductList.action';

import { fireProductSortEvent } from '../../event/sort';

const addFireProductSortEvent = (args, callback) => {
    const [state, action] = args;

    if (action.type !== UPDATE_PRODUCT_LIST_ITEMS) {
        return callback(...args);
    }

    const { currentArgs: { sort: oldSort } } = state;

    if (!oldSort) {
        return callback(...args);
    }
    // ^^^ skip if it's initial

    const { args: { sort }, customerId } = action;

    if (
        oldSort.sortDirection !== sort.sortDirection
        || oldSort.sortKey !== sort.sortKey
    ) {
        fireProductSortEvent(sort, customerId);
    }

    return callback(...args);
};

export default {
    'Store/ProductList/Reducer/ProductListReducer': {
        function: addFireProductSortEvent
    }
};
