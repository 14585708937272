/**
 * Mageplaza Affiliate compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    MY_AFFILIATE_BANNERS,
    MY_AFFILIATE_HOME,
    MY_AFFILIATE_REFER_HISTORY,
    MY_AFFILIATE_SIGNUP
} from '../config';

/* eslint-disable max-len */

const renderSubTabsItem = (args, callback, instance) => {
    const { props } = instance;
    const {
        affiliateConfig: {
            isSignedUp = false,
            isActiveAffiliateAccount = false,
            affiliateEnableBanner = false,
            isEnableReferHistory = false
        } = {}
    } = props;
    const tab = (args[0] || [])[0] || '';

    if (tab.includes('my-affiliate')) {
        if ((!isSignedUp || !isActiveAffiliateAccount) && tab !== MY_AFFILIATE_HOME && tab !== MY_AFFILIATE_SIGNUP) {
            return null;
        }

        if (isSignedUp && tab === MY_AFFILIATE_SIGNUP) {
            return null;
        }

        if (!affiliateEnableBanner && tab == MY_AFFILIATE_BANNERS) {
            return null;
        }

        if (!isEnableReferHistory && tab === MY_AFFILIATE_REFER_HISTORY) {
            return null;
        }
    }

    return (
        <>
            { callback(...args) }
        </>
    );
};

const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        affiliateConfig: state.MyAccountReducer.customer.affiliate_config,
        currencyData: state.ConfigReducer.currencyData
    };
};

const containerProps = (args, callback, instance) => {
    const { props } = instance;
    const {
        affiliateConfig,
        currencyData
    } = props;

    return {
        ...callback(args),
        affiliateConfig,
        currencyData
    };
};

export default {
    'Spinola/BaseTheme/Component/MyAccountTabListItem/Container': {
        'member-function': {
            containerProps
        }
    },
    'Spinola/BaseTheme/Component/MyAccountTabListItem/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Spinola/BaseTheme/Component/MyAccountTabListItem/Component': {
        'member-function': {
            renderSubTabsItem
        }
    }
};
