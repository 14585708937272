/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'Util/Store';

import { waitForCallback } from '../util/wait';
import { getProductClickPageData } from './page';
import { getProductListEntryData } from './product';

/** @namespace Scandiweb/Gtm/Data/List/getProductImpressionsData */
export const getProductImpressionsData = async (
    products,
    positions,
    forcedList,
    urlPathname,
    urlRewriteData,
    category
) => {
    // vvv Wait for currency code
    await waitForCallback(() => getStore().getState().ConfigReducer?.currencyData?.current_currency_code);

    const { page_type: list } = await getProductClickPageData(
        urlPathname,
        urlRewriteData,
        category
    );
    // ^^^ Reuse page data as list information
    const currencyCode = getStore().getState().ConfigReducer?.currencyData?.current_currency_code;

    const batchedImpressions = await products.map(async (product, index) => {
        // vvv To remove variant from productData
        // eslint-disable-next-line no-unused-vars
        const { variant, ...productData } = await getProductListEntryData(product, positions[index]);

        return {
            ...productData,
            list: forcedList || list
        };
    });

    return {
        ecommerce: {
            currency: currencyCode,
            items: await Promise.all(batchedImpressions)
        }
    };
};

/** @namespace Scandiweb/Gtm/Data/List/getProductClickData */
export const getProductClickData = async (
    product,
    position,
    forcedList,
    urlPathname,
    urlRewriteData,
    category
) => {
    const { page_type: list } = await getProductClickPageData(
        urlPathname,
        urlRewriteData,
        category
    );
    // ^^^ Reuse page data as list information
    // vvv To remove variant from productData
    // eslint-disable-next-line no-unused-vars
    const { variant, ...productData } = await getProductListEntryData(product, position,);

    return {
        ecommerce: {
            item_list_name: forcedList || list,
            items: [productData]
        }
    };
};
