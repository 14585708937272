/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * GA4 events
 */
export const GTM_EVENT_KEY_GENERAL = 'general';
export const GTM_EVENT_KEY_VIEW_ITEM_LIST = 'view_item_list';
export const GTM_EVENT_KEY_SELECT_ITEM = 'select_item';
export const GTM_EVENT_KEY_VIEW_ITEM = 'view_item';
export const GTM_EVENT_KEY_ADD_TO_WISHLIST = 'add_to_wishlist';
export const GTM_EVENT_KEY_ADD_TO_CART = 'add_to_cart';
export const GTM_EVENT_KEY_REMOVE_FROM_CART = 'remove_from_cart';
export const GTM_EVENT_KEY_VIEW_CART = 'view_cart';
export const GTM_EVENT_KEY_BEGIN_CHECKOUT = 'begin_checkout';
export const GTM_EVENT_KEY_ADD_SHIPPING_INFO = 'add_shipping_info';
export const GTM_EVENT_KEY_ADD_PAYMENT_INFO = 'add_payment_info';
export const GTM_EVENT_KEY_PURCHASE = 'purchase';
export const GTM_EVENT_KEY_SEARCH = 'search';
export const GTM_EVENT_KEY_VIEW_PROMOTION = 'view_promotion';
export const GTM_EVENT_KEY_SELECT_PROMOTION = 'select_promotion';
export const GTM_EVENT_KEY_LOGIN = 'login';
export const GTM_EVENT_KEY_SIGN_UP = 'sign_up';
export const GTM_EVENT_KEY_GENERATE_LEAD = 'generate_lead';
export const GTM_EVENT_KEY_FILTER_SELECT = 'filter_select';
export const GTM_EVENT_KEY_SORT_SELECT = 'sort_select';

/**
 * Keeping legacy events here in case they are needed in the future
 */
// vvv Event/plugin "notFound"
export const GTM_EVENT_KEY_NOT_FOUND = 'notFound';
// vvv Event/plugin "user"
export const GTM_EVENT_KEY_USER_LOGIN = 'userLogin';
export const GTM_EVENT_KEY_USER_LOGIN_ATTEMPT = 'userLoginAttempt';
export const GTM_EVENT_KEY_USER_REGISTER = 'userRegister';
export const GTM_EVENT_KEY_USER_REGISTER_ATTEMPT = 'userRegisterAttempt';
export const GTM_EVENT_KEY_USER_LOGOUT = 'userLogout';
// vvv Event/plugin "search"
export const GTM_EVENT_KEY_SEARCH_STARTED = 'siteSearchStarted';
// vvv Event/plugin "list"
export const GTM_EVENT_KEY_IMPRESSIONS = 'impression';
export const GTM_EVENT_KEY_PRODUCT_CLICK = 'productClick';
// vvv Event/plugin "product"
export const GTM_EVENT_KEY_PRODUCT_DETAIL = 'productDetail';
// vvv Event/plugin "checkout"
export const GTM_EVENT_KEY_CHECKOUT = 'checkout';
export const GTM_EVENT_KEY_CHECKOUT_OPTION = 'checkoutOption';
// vvv Event/plugin "sort"
export const GTM_EVENT_KEY_SORT = 'sort';
// vvv Event/plugin "wishlist"
export const GTM_EVENT_KEY_REMOVE_FROM_WISHLIST = 'removeFromWishlist';
export const GTM_EVENT_KEY_REMOVE_ALL_ITEMS_FROM_WISHLIST = 'removeAllItemsFromWishlist';
// vvv Event/plugin "errorMessage"
export const GTM_EVENT_KEY_ERROR_MESSAGE = 'errorMessage';
// vvv Event/plugin "pageReload"
export const GTM_EVENT_KEY_PAGE_RELOAD = 'pageReload';
// vvv Event/plugin "filter"
export const GTM_EVENT_KEY_FILTER_APPLIED = 'filterApplied';
export const GTM_EVENT_KEY_FILTER_REMOVED = 'filterRemoved';
export const GTM_EVENT_KEY_ALL_FILTERS_REMOVED = 'allFiltersRemoved';
