/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyGiftCards/Query/ApplyGiftCard/Mutation */
export class ApplyGiftCardMutation {
    applyGiftCard(giftCardCode, cartId) {
        return new Field('applyGiftCard')
            .addArgument('giftCardCode', 'String!', giftCardCode)
            .addArgument('cartId', 'String!', cartId)
            .addFieldList(this.getResponse());
    }

    getResponse() {
        return [
            'message',
            'error'
        ];
    }
}

export default new ApplyGiftCardMutation();
