/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyGiftCards/Query/CheckStatus/Query */
export class CheckStatusQuery {
    checkGiftCardStatus(giftCardCode) {
        return new Field('checkGiftCard')
            .addArgument('giftCardCode', 'String!', giftCardCode)
            .addFieldList(this.getStatusFields());
    }

    getStatusFields() {
        return [
            'code',
            'status',
            'currentBalance',
            'validTill'
        ];
    }
}

export default new CheckStatusQuery();
