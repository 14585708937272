/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';

import '../GiftCardInput/GiftCardInput.style.scss';
import './CodeStatusChecker.style.scss';

/** @namespace Scandiweb/AmastyGiftCards/Component/CodeStatusChecker/Component */
export class CodeStatusCheckerComponent extends PureComponent {
    static propTypes = {
        inputHandler: PropTypes.func.isRequired,
        checkGiftCard: PropTypes.shape({
            code: PropTypes.string,
            currentBalance: PropTypes.string,
            status: PropTypes.string,
            validTill: PropTypes.string
        }),
        handleClick: PropTypes.func.isRequired
    };

    static defaultProps = {
        checkGiftCard: {}
    };

    renderInputField() {
        const {
            inputHandler
        } = this.props;

        return (
            <div className="Form">
                <div>
                    <Field
                      attr={ {
                          mix: { block: 'Input' },
                          placeholder: __('Enter your Code')
                      } }
                      events={ {
                          onChange: (event) => inputHandler(event)
                      } }
                    />
                </div>
            </div>
        );
    }

    renderCardStatus() {
        const { checkGiftCard } = this.props;

        if (!Object.keys(checkGiftCard).length) {
            return null;
        }

        const {
            code,
            status,
            currentBalance,
            validTill
        } = checkGiftCard;

        return (
            <div
              block="CodeStatusChecker"
              elem="TableWrapper"
              mix={ { block: 'GiftCardInput', elem: 'TableWrapper' } }
            >
                <table
                  block="CodeStatusChecker"
                  elem="Table"
                  mix={ { block: 'MyDownloadable' } }
                >
                    <thead>
                        <tr>
                            <th>{ __('Code') }</th>
                            <th>{ __('Status') }</th>
                            <th>{ __('Current Balance') }</th>
                            <th>{ __('Valid Till') }</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr key={ `${code}` }>
                            <td>{ code }</td>
                            <td>{ status }</td>
                            <td>{ currentBalance }</td>
                            <td>{ validTill }</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    renderCheckStatus() {
        const { handleClick } = this.props;

        return (
            <div
              block="CodeStatusChecker"
              elem="CardStatus"
              mix={ { block: 'GiftCardInput', elem: 'CardStatus' } }
            >
                <button block="GiftCardInput" elem="LinkButton" onClick={ handleClick }>
                    { __('Check Status') }
                </button>
                { this.renderCardStatus() }
            </div>
        );
    }

    renderInput() {
        return (
            <div
              block="CodeStatusChecker"
              elem="Input"
              mods={ { block: 'GiftCardInput', elem: 'Input' } }
            >
                { this.renderInputField() }
                { this.renderCheckStatus() }
            </div>
        );
    }

    render() {
        return (
            <div block="CodeStatusChecker">
               { this.renderInput() }
            </div>
        );
    }
}

export default CodeStatusCheckerComponent;
