/**
 * Mageplaza Affiliate compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/boolean-prop-naming */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Loader from 'Component/Loader';

import './MyAffiliateDiscountCode.style';

/** @namespace Scandiweb/MageplazaAffiliate/Component/MyAffiliateDiscountCode/Component */
export class MyAffiliateDiscountCodeComponent extends PureComponent {
    static propTypes = {
        handleApply: PropTypes.func.isRequired,
        inputHandler: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        couponCodeValue: PropTypes.string.isRequired,
        isApplied: PropTypes.bool.isRequired,
        affiliateUseCodeAsCoupon: PropTypes.bool.isRequired
    };

    renderInputField() {
        const {
            inputHandler,
            isApplied,
            couponCodeValue,
            isLoading
        } = this.props;

        return (
            <div className="Form">
                <div block="MyAffiliateDiscountCode" elem="Field">
                    <Field
                      attr={ {
                          mix: { block: 'Input' },
                          list: 'giftCardInputFields',
                          name: 'giftCardInputField',
                          id: 'giftCardInput',
                          placeholder: __('Apply Affiliate Discount Code'),
                          readOnly: isApplied || isLoading,
                          value: couponCodeValue
                      } }
                      events={ {
                          onChange: (event) => inputHandler(event)
                      } }
                    />
                </div>
                { this.renderButton() }
            </div>
        );
    }

    renderButton() {
        const {
            handleApply,
            isLoading,
            couponCodeValue,
            isApplied
        } = this.props;
        const disableCondition = !couponCodeValue && !isApplied;

        return (
            <button
              block="MyAffiliateDiscountCode"
              elem="Button"
              type={ FIELD_TYPE.button }
              mods={ { isHollow: true, isInvisible: disableCondition } }
              onClick={ handleApply }
              disabled={ isLoading || disableCondition }
            >
              { isApplied ? __('Cancel') : __('Redeem') }
            </button>
        );
    }

    renderInput() {
        return (
            <div block="MyAffiliateDiscountCode" elem="Input">
                { this.renderInputField() }
            </div>
        );
    }

    render() {
        const {
            isLoading,
            affiliateUseCodeAsCoupon
        } = this.props;

        if (!affiliateUseCodeAsCoupon) {
            return null;
        }

        return (
            <div block="MyAffiliateDiscountCode">
                <Form
                  returnAsObject
                >
                    <Loader isLoading={ isLoading } />
                    { this.renderInput() }
                </Form>
            </div>
        );
    }
}

export default MyAffiliateDiscountCodeComponent;
