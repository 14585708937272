/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getStore } from 'SourceUtil/Store';

import GiftCardExpandable from '../../component/GiftCardExpandable';
import GiftCardInput from '../../component/GiftCardInput';
import { BILLING_STEP, CHECKOUT_VIEW_DROPDOWN } from '../../config';

const mapStateToProps = (args, callback, _instance) => {
    const [state] = args;

    return {
        ...callback(...args),
        giftCardCheckoutViewType: state.ConfigReducer?.AmastyGiftCardsConfig?.accountCheckoutViewType,
        giftCardGeneralActive: state.ConfigReducer?.AmastyGiftCardsConfig?.generalActive,
        allowedProductTypes: state.ConfigReducer?.AmastyGiftCardsConfig?.allowedProductTypes
    };
};

const addViewTypeToContainerProps = (args, callback, instance) => {
    const {
        giftCardCheckoutViewType,
        giftCardGeneralActive,
        allowedProductTypes
    } = instance.props;

    return {
        ...callback(args),
        giftCardCheckoutViewType,
        giftCardGeneralActive,
        allowedProductTypes
    };
};

const addGiftCardAfterDiscount = (args, callback, instance) => {
    const {
        giftCardCheckoutViewType: viewType,
        giftCardGeneralActive,
        checkoutStep,
        allowedProductTypes,
        checkoutTotals: { items }
    } = instance.props;

    if (!giftCardGeneralActive || checkoutStep !== BILLING_STEP) {
        return callback(...args);
    }

    const itemTypes = items.map(({ product: { type_id: type } }) => type);
    const allowedProductTypesArray = allowedProductTypes.split(',');
    const isAllowedProductType = itemTypes.map((type) => allowedProductTypesArray.indexOf(type));

    if (isAllowedProductType.indexOf(-1) > -1) {
        return callback(...args);
    }

    if (viewType === CHECKOUT_VIEW_DROPDOWN) {
        return (
            <>
                { callback(...args) }
                <GiftCardExpandable mix={ { block: 'CartPage', elem: 'GiftCard' } } />
            </>
        );
    }

    return (
        <>
            { callback(...args) }
            <GiftCardInput mix={ { block: 'GiftCardInput', elem: 'Billing' } } />
        </>
    );
};

const addGiftCardBeforeMobileDiscount = (args, callback, _instance) => {
    const { generalActive } = getStore().getState().ConfigReducer.AmastyGiftCardsConfig;

    if (!generalActive) {
        return callback(...args);
    }

    return (
        <>
            { <GiftCardExpandable mix={ { block: 'CartPage', elem: 'BillingMobile' } } /> }
            { callback(...args) }
        </>
    );
};

export default {
    'Route/Checkout/Component': {
        'member-function': {
            renderDiscountCode: addGiftCardAfterDiscount
        }
    },
    'Component/CheckoutOrderSummary/Component': {
        'member-function': {
            renderMobileDiscount: addGiftCardBeforeMobileDiscount
        }
    },
    'Route/Checkout/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/Checkout/Container': {
        'member-function': {
            containerProps: addViewTypeToContainerProps
        }
    }
};
