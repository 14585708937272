/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field, Fragment } from 'Util/Query';

/** @namespace Scandiweb/AmastyGiftCards/Query/AmastyGiftCardProduct/Query */
export class AmastyGiftCardProductQuery {
    getAmGiftCardFragment() {
        return new Fragment('AmastyGiftCard')
            .addField(this.getGiftCardProductOptions());
    }

    getAmGiftCardPriceField() {
        return new Field('am_giftcard_prices')
            .addFieldList(this.getPriceFields());
    }

    getPriceFields() {
        return [
            'price_id',
            'attribute_id',
            'value'
        ];
    }

    getGiftCardImageFieldOptions() {
        return [
            'id',
            'src'
        ];
    }

    getAmGiftCardImagesField() {
        return new Field('images')
            .addFieldList(this.getGiftCardImageFieldOptions());
    }

    getGiftCardOptionsFields() {
        return [
            this.getAmGiftCardPriceField(),
            'am_allow_open_amount',
            'am_open_amount_min',
            'am_open_amount_max',
            'am_giftcard_type',
            'am_giftcard_code_image',
            'gift_message_available',
            'timezones',
            this.getAmGiftCardImagesField()
        ];
    }

    getGiftCardProductOptions() {
        return new Field('giftCardOptions')
            .addFieldList(this.getGiftCardOptionsFields());
    }
}

export default new AmastyGiftCardProductQuery();
