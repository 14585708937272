/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';
import Popup from 'Component/Popup';

import { GIFTCARD_PREVIEW_POPUP_ID } from '../../config';

import './GiftCardEmailPreview.style';

/** @namespace Scandiweb/AmastyGiftCards/Component/GiftCardEmailPreview/Component */
export class GiftCardEmailPreviewComponent extends PureComponent {
    static propTypes = {
        payload: PropTypes.shape({
            text: PropTypes.string
        }),
        content: PropTypes.string.isRequired
    };

    static defaultProps={
        payload: {
            text: ''
        }
    };

    render() {
        const { content } = this.props;

        return (
            <Popup
              id={ GIFTCARD_PREVIEW_POPUP_ID }
              isCloseOnOutsideClick={ false }
              mix={ { block: 'GiftCardEmailPreview' } }
            >
               <Html
                 content={ content }
               />
            </Popup>
        );
    }
}

export default GiftCardEmailPreviewComponent;
