/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    AddToCartComponent
} from 'Component/AddToCart/AddToCart.component';
import Loader from 'Component/Loader';

import GiftCardPaymentPopup from '../GiftCardPaymentPopup';

/** @namespace Scandiweb/AmastyGiftCards/Component/GiftCardAddToCart/Component */
export class GiftCardAddToCartComponent extends AddToCartComponent {
    render() {
        const {
            paymentMethods = [],
            shippingAddress = {},
            onPopupButtonClick,
            onPaymentMethodSelect,
            mix,
            layout,
            isDisabled,
            isAdding,
            handleButtonClick,
            label,
            isLoading = false
        } = this.props;

        return (
            <>
                <button
                  onClick={ handleButtonClick }
                  block="Button AddToCart"
                  mix={ mix }
                  mods={ { layout } }
                  disabled={ isDisabled || isAdding }
                >
                    { this.renderCartIcon() }
                    <span>{ label }</span>
                    <Loader isLoading={ isLoading } />
                </button>
                <GiftCardPaymentPopup
                  paymentMethods={ paymentMethods }
                  shippingAddress={ shippingAddress }
                  onButtonClick={ onPopupButtonClick }
                  onPaymentMethodSelect={ onPaymentMethodSelect }
                />
            </>
        );
    }
}

export default GiftCardAddToCartComponent;
