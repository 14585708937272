import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    CheckoutPaymentsContainer as SourceCheckoutPaymentsContainer,
    mapDispatchToProps, mapStateToProps
} from 'SourceComponent/CheckoutPayments/CheckoutPayments.container';

export * from 'SourceComponent/CheckoutPayments/CheckoutPayments.container';

/** @namespace Spinola/BaseTheme/Component/CheckoutPayments/Container */
export class CheckoutPaymentsContainer extends SourceCheckoutPaymentsContainer {
    static propTypes = {
        ...this.propTypes,
        isWalletWithdrawalPopup: PropTypes.bool
    };

    static defaultProps = {
        isWalletWithdrawalPopup: false
    };

    // if one payment method only exists then just pre-select it by default
    static getDerivedStateFromProps(props) {
        const { paymentMethods, onPaymentMethodSelect, setOrderButtonEnableStatus } = props;

        if (paymentMethods?.length === 1) {
            // Enable order button if only 1 payment method available
            // Since payment method is automatically pre-selected in such cases
            setOrderButtonEnableStatus(true);
            onPaymentMethodSelect(paymentMethods?.[0]?.code);

            return {
                selectedPaymentCode: paymentMethods?.[0]?.code
            };
        }

        return null;
    }

    /**
     * Overridden to set order button status disabled by default when there is 2 or more payment options
     */
    componentDidMount() {
        super.componentDidMount();

        const { paymentMethods, setOrderButtonEnableStatus } = this.props;

        if (paymentMethods?.length > 1) {
            setOrderButtonEnableStatus(false);
        }
    }

    /** Overriden to add in wallet withdraw */
    containerProps() {
        const {
            billingAddress,
            paymentMethods,
            setOrderButtonEnableStatus,
            showError,
            isWalletWithdrawalPopup
        } = this.props;

        const { selectedPaymentCode } = this.state;

        return {
            billingAddress,
            paymentMethods,
            selectedPaymentCode,
            setOrderButtonEnableStatus,
            showError,
            isWalletWithdrawalPopup
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPaymentsContainer);
