/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    getAddToCartData,
    getRemoveFromCartData,
    getViewCartData
} from '../data/cart';
import {
    GTM_EVENT_KEY_ADD_TO_CART,
    GTM_EVENT_KEY_REMOVE_FROM_CART,
    GTM_EVENT_KEY_VIEW_CART
} from '../util/events';
import { pushToDataLayer } from '../util/push';

/** @namespace Scandiweb/Gtm/Event/Cart/fireAddToCartEvent */
export const fireAddToCartEvent = async (item, currencyCode, _customerId) => {
    // ^^^ not using debounce, as many cart edits may happen at once
    pushToDataLayer({
        event: GTM_EVENT_KEY_ADD_TO_CART,
        ...await getAddToCartData(item, currencyCode)
    });
};

/** @namespace Scandiweb/Gtm/Event/Cart/fireRemoveFromCartEvent */
export const fireRemoveFromCartEvent = async (item, currencyCode, _customerId) => {
    // ^^^ not using debounce, as many cart edits may happen at once
    pushToDataLayer({
        event: GTM_EVENT_KEY_REMOVE_FROM_CART,
        ...await getRemoveFromCartData(item, currencyCode)
    });
};

/** @namespace Scandiweb/Gtm/Event/Cart/fireViewCartEvent */
export const fireViewCartEvent = async (items, cartSubtotal, currencyCode, _customerId) => {
    // ^^^ not using debounce, as many cart edits may happen at once
    pushToDataLayer({
        event: GTM_EVENT_KEY_VIEW_CART,
        ecommerce: {
            currency: currencyCode,
            value: cartSubtotal,
            ...await getViewCartData(items, currencyCode)
        }
    });
};
