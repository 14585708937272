/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/*
 * disabled multiple eslint rules to match coding style of ScandiPWA Core
 * vvv
*/
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */

import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { withStoreRegex } from 'Component/Router/Router.component';

import { GIFT_CARDS, GIFTCARD_PURCHASES, RECIEVED_GIFTCARDS } from '../../config/index';

export const MyAccount = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account" */
    'Route/MyAccount'
));

export const PayPalReviewPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "paypal" */
    '../../component/MyAccountGiftCards'
));

const SWITCH_ITEMS_TYPE = (originalMember) => [
    ...originalMember,
    {
        component: (
            <Route
              path={ withStoreRegex('/customer/account/gift-cards') }
              render={ (props) => (
                    <MyAccount
                      { ...props }
                      selectedTab={ GIFT_CARDS }
                    />
              ) }
            />
        ),
        position: 150
    },
    {
        component: (
            <Route
              path={ withStoreRegex('/customer/account/purchased-gift-cards') }
              render={ (props) => (
                    <MyAccount
                      { ...props }
                      selectedTab={ GIFTCARD_PURCHASES }
                    />
              ) }
            />
        ),
        position: 51
    },
    {
        component: (
            <Route
              path={ withStoreRegex('/customer/account/recieved-gift-cards') }
              render={ (props) => (
                    <MyAccount
                      { ...props }
                      selectedTab={ RECIEVED_GIFTCARDS }
                    />
              ) }
            />
        ),
        position: 52
    }
];

export default {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE
        }
    }
};
