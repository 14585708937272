/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import NewsletterSubscriptionQuery from 'Query/NewsletterSubscription.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation, getErrorMessage } from 'Util/Request';

import { fireNewsletterSubscriptionEvent } from '../../event/newsletter';

const aroundSubscribeToNewsletter = async (functionArgs) => {
    const [dispatch, email] = functionArgs;

    return fetchMutation(NewsletterSubscriptionQuery.getSubscribeToNewsletterMutation(email)).then(
        /** @namespace Store/NewsletterSubscription/Dispatcher/NewsletterSubscriptionDispatcher/subscribeToNewsletter/fetchMutation/then */
        ({ subscribeEmailToNewsletter: { status } }) => {
            // `NOT_ACTIVE` response status corresponds to `newsletter_subscription_confirm` magento setting
            const message = status === 'NOT_ACTIVE'
                ? __('Confirmation request has been sent.')
                : __('Thank you for your subscription.');

            fireNewsletterSubscriptionEvent(email);

            return dispatch(showNotification('success', message));
        },
        /** @namespace Store/NewsletterSubscription/Dispatcher/NewsletterSubscriptionDispatcher/subscribeToNewsletter/fetchMutation/then/dispatch/catch */
        (error) => dispatch(showNotification('error', getErrorMessage(error)))
    );
};

export default {
    'Store/NewsletterSubscription/Dispatcher': {
        'member-function': {
            subscribeToNewsletter: aroundSubscribeToNewsletter
        }
    }
};
