/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ClickOutside from 'Component/ClickOutside';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Loader from 'Component/Loader';

import { GIFT_CARDS_TAB } from '../../config';
import AppliedGiftCards from '../AppliedGiftCards';

import './GiftCardInput.style';

/** @namespace Scandiweb/AmastyGiftCards/Component/GiftCardInput/Component */
export class GiftCardInputComponent extends PureComponent {
    static propTypes = {
        appliedGiftCards: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string,
                id: PropTypes.number
            })
        ),
        checkGiftCard: PropTypes.shape({
            code: PropTypes.string,
            currentBalance: PropTypes.string,
            status: PropTypes.string,
            validTill: PropTypes.string
        }),
        handleApply: PropTypes.func.isRequired,
        handleClick: PropTypes.func.isRequired,
        handleFocus: PropTypes.func.isRequired,
        handleUnFocus: PropTypes.func.isRequired,
        inputHandler: PropTypes.func.isRequired,
        isButtonLoading: PropTypes.bool.isRequired,
        isFocused: PropTypes.bool.isRequired,
        isSignedIn: PropTypes.bool.isRequired,
        cartId: PropTypes.string.isRequired,
        chosenInput: PropTypes.string.isRequired,
        myAccountCards: PropTypes.arrayOf(
            PropTypes.shape({
                code: PropTypes.string,
                status: PropTypes.string,
                usage: PropTypes.string,
                balance: PropTypes.string,
                validTill: PropTypes.string
            })
        ),
        page: PropTypes.string.isRequired,
        mix: PropTypes.shape({
            block: PropTypes.string,
            elem: PropTypes.string
        }),
        isExpandable: PropTypes.bool
    };

    static defaultProps = {
        myAccountCards: [],
        appliedGiftCards: [],
        checkGiftCard: {},
        mix: {},
        isExpandable: false

    };

    renderAppliedGiftCardOptions() {
        const { myAccountCards = [], page } = this.props;

        if (!myAccountCards?.length) {
            return null;
        }

        if (page === GIFT_CARDS_TAB) {
            return null;
        }

        return myAccountCards.map(this.renderGiftCards);
    }

    renderGiftCards = ({ code, id }, index) => (
            <option
              label={ code }
              value={ code }
              key={ index }
              id={ `Option-${id}` }
              mix={ { block: 'GiftCardInput', elem: 'DataListOption' } }
            />
    );

    renderAppliedGiftCardCodes() {
        const { appliedGiftCards } = this.props;

        return (
            <div>
                { appliedGiftCards?.map(this.renderAppliedCodes) }
            </div>
        );
    }

    renderInputField() {
        const {
            inputHandler,
            handleUnFocus,
            handleFocus,
            inputFieldRef
        } = this.props;

        return (
            <div className="Form">
                <ClickOutside onClick={ handleUnFocus }>
                    <div block="GiftCardInput" elem="Field">
                        <Field
                          attr={ {
                              mix: { block: 'Input' },
                              list: 'giftCardInputFields',
                              name: 'giftCardInputField',
                              id: 'giftCardInput',
                              placeholder: __('Apply Gift Card')
                          } }
                          elemRef={ inputFieldRef }
                          events={ {
                              onChange: (event) => inputHandler(event),
                              onClick: (event) => handleFocus(event)
                          } }
                        />
                    </div>
                </ClickOutside>
                { this.renderButton() }
            </div>
        );
    }

    renderButton() {
        const {
            handleApply,
            isButtonLoading,
            chosenInput
        } = this.props;

        return (
            <button
              block="GiftCardInput"
              elem="Button"
              type={ FIELD_TYPE.button }
              mods={ { isHollow: true, isInvisible: !chosenInput } }
              onClick={ handleApply }
              disabled={ isButtonLoading || !chosenInput }
            >
              { __('Redeem') }
            </button>
        );
    }

    renderAppliedCodes = ({ code }, index) => {
        const { page, cartId } = this.props;

        if (page === GIFT_CARDS_TAB) {
            return null;
        }

        return (
            <AppliedGiftCards
              key={ index }
              code={ code }
              cartId={ cartId }
            />
        );
    };

    renderCardStatus() {
        const { checkGiftCard } = this.props;

        if (!Object.keys(checkGiftCard).length) {
            return null;
        }

        const {
            code,
            status,
            currentBalance,
            validTill
        } = checkGiftCard;

        return (
            <div block="GiftCardInput" elem="TableWrapper">
                <table
                  block="GiftCardInput"
                  elem="Table"
                  mix={ { block: 'MyDownloadable' } }
                >
                    <thead>
                        <tr>
                            <th>{ __('Code') }</th>
                            <th>{ __('Status') }</th>
                            <th>{ __('Current Balance') }</th>
                            <th>{ __('Valid Till') }</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr key={ `${code}` }>
                            <td>{ code }</td>
                            <td>{ status }</td>
                            <td>{ currentBalance }</td>
                            <td>{ validTill }</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    renderCheckStatus() {
        const {
            handleClick,
            page,
            chosenInput
        } = this.props;

        if (page === GIFT_CARDS_TAB || !chosenInput) {
            return null;
        }

        return (
            <div block="GiftCardInput" elem="CardStatus">
                <button block="GiftCardInput" elem="LinkButton" onClick={ handleClick }>
                    { __('Check Status') }
                </button>
                { this.renderCardStatus() }
            </div>
        );
    }

    renderMessages() {
        const {
            isSignedIn,
            isFocused,
            myAccountCards,
            page
        } = this.props;

        if (page === GIFT_CARDS_TAB) {
            return null;
        }

        if (!isFocused) {
            return null;
        }

        if (isSignedIn && myAccountCards?.length < 1) {
            return (
                <div block="GiftCardInput" elem="Message">
                    { __('You have no active Gift Card Codes added to your customer account.') }
                </div>
            );
        }

        if (isSignedIn) {
            return null;
        }

        return (
            <div block="GiftCardInput" elem="Message">
                { /* eslint-disable-next-line max-len */ }
                { __('Please login or register as a customer to add Gift Card Codes to your customer account and display here.') }
            </div>
        );
    }

    renderInput() {
        return (
            <div block="GiftCardInput" elem="Input">
                { this.renderAppliedGiftCardCodes() }
                { this.renderInputField() }
                <datalist id="giftCardInputFields" mix={ { block: 'GiftCardInput', elem: 'DataList' } }>
                    { this.renderAppliedGiftCardOptions() }
                </datalist>
                { this.renderMessages() }
                { this.renderCheckStatus() }
            </div>
        );
    }

    render() {
        const { isButtonLoading, mix } = this.props;

        return (
            <div block="GiftCardInput" mix={ mix }>
                <Form
                  returnAsObject
                >
                    <Loader isLoading={ isButtonLoading } />
                    { this.renderInput() }
                </Form>
            </div>
        );
    }
}

export default GiftCardInputComponent;
