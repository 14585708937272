/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

import {
    ProductPrice as SourcePriceComponent
} from 'Component/ProductPrice/ProductPrice.component';
import { formatPrice } from 'Util/Price';

import { getPriceForPLP } from '../../util/GetPrice';

/** @namespace Scandiweb/AmastyGiftCards/Component/GiftCardPLPPrice/Component */
export class GiftCardPLPPriceComponent extends SourcePriceComponent {
    static propTypes = {
        prices: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string
        })).isRequired
    };

    renderGiftCardPrices() {
        const {
            prices,
            am_currency,
            openAmount: {
                allowOpenAmount,
                openAmountMin,
                openAmountMax
            }
        } = this.props;
        const { label, value } = getPriceForPLP(prices, allowOpenAmount, openAmountMin, openAmountMax);
        const price = {
            value,
            valueFormatted: formatPrice(value, am_currency)
        };

        return this.renderPrice(price, label);
    }

    render() {
        const {
            isPreview,
            discountPercentage,
            mix
        } = this.props;

        return (
            <div
              block="ProductPrice"
              mods={ { hasDiscount: discountPercentage !== 0, isPreview } }
              mix={ mix }
              aria-label="GiftCardProductPrice"
            >
                { this.renderGiftCardPrices() }
            </div>
        );
    }
}

export default GiftCardPLPPriceComponent;
