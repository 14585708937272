/* eslint-disable max-lines */
import {
    CHECKOUT_ORDER_POPUP_TYPES
} from 'Component/CheckoutOrderPopup/CheckoutOrderPopup.config';
import CheckoutQuery from 'Query/Checkout.query';
import { isSignedIn } from 'Util/Auth';
import { getCartId } from 'Util/Cart';
import history from 'Util/History';
import {
    fetchMutation,
    getErrorMessage
} from 'Util/Request';

import BridgerPayKeysQuery from '../../query/BridgerPayKeys.query';
import { getSearchParam } from '../../util/search-param';

import './BridgerPay.style';

const ESCAPE_KEY_CODE = 27;
export const BRIDGER_PAY_PAYMENT_CODE = 'bridgerpay_iframe';

export const savePaymentMethod = async (paymentInformation, instance) => {
    const { paymentMethod: { code, additional_data, purchase_order_number } } = paymentInformation;
    const isCustomerSignedIn = isSignedIn();
    const guest_cart_id = instance?.state?.cartId || getCartId();

    if (!isCustomerSignedIn && !guest_cart_id) {
        return;
    }

    try {
        await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
            cart_id: guest_cart_id,
            payment_method: {
                code,
                [code]: additional_data,
                purchase_order_number
            }
        }));
    } catch (e) {
        instance._handleError(e);
    }
};

export class CheckoutPlugin {
    // Workaround for accessing original instance's
    // State and props within the event handler function
    originalInstance = null;

    isIframeVisible = false;

    isPaymentProcessing = false;

    componentDidMount = (args, callback, instance) => {
        this.originalInstance = instance;

        window.addEventListener('message', this.bridgerPayEventListener);
        window.addEventListener('keydown', this.escapeKeyListener);
        callback(...args);
    };

    componentWillUnmount = (args, callback) => {
        window.removeEventListener('message', this.bridgerPayEventListener);
        window.removeEventListener('keydown', this.escapeKeyListener);
        callback(...args);
    };

    __construct() {
        this.bridgerPayEventListener = this.bridgerPayEventListener.bind(this);
        this.escapeKeyListener = this.escapeKeyListener.bind(this);
    }

    escapeKeyListener = (event) => {
        // we don't want the possibility destroy iframe while payment is processing
        if (event?.keyCode === ESCAPE_KEY_CODE && this.isIframeVisible && !this.isPaymentProcessing) {
            this.destroyIframe();
        }
    };

    destroyIframe() {
        document.getElementById('bridgerpay-iframe-wrapper').remove();
        this.isIframeVisible = false;
    }

    bridgerPayEventListener = async (event) => {
        if (!this.originalInstance) {
            return;
        }

        const { bridgerpay_embed_url } = this.originalInstance.props;

        if (event?.origin !== bridgerpay_embed_url) {
            return;
        }

        if (event?.data?.event === '[bp]:init' && event?.data?.type === 'success') {
            this.isIframeVisible = true;
            const wrapper = document.getElementById('bridgerpay-iframe-wrapper');

            wrapper.addEventListener('click', () => {
                if (!this.isPaymentProcessing) {
                    this.destroyIframe();
                }
            });
        }

        if (event?.data?.event === '[bp]:processingDeposit' && event?.data?.value === true) {
            this.isPaymentProcessing = true;
        }

        if (event?.data?.event?.includes(':redirect') && event?.data?.url.includes('declined')) {
            this.isPaymentProcessing = false;
            this.destroyIframe();
            history.replace('/');
            this.originalInstance?.props?.showCheckoutPopup(CHECKOUT_ORDER_POPUP_TYPES.failed);
        }

        if (event?.data?.event === '[bp]:redirect' && event?.data?.url.includes('success')) {
            this.isPaymentProcessing = false;
            this.destroyIframe();

            const params = (new URL(event.data.url)).searchParams;
            const bgp_order_id = getSearchParam('orderId', params);
            const paymentInformation = {
                paymentMethod: {
                    code: BRIDGER_PAY_PAYMENT_CODE,
                    additional_data: { bgp_order_id },
                    purchase_order_number: ''
                }
            };

            await savePaymentMethod(paymentInformation, this.originalInstance);

            this.originalInstance.setState({ isLoading: true });
            const guest_cart_id = this.originalInstance?.state?.cartId || getCartId();

            await this.originalInstance.placeOrder(guest_cart_id);
        }
    };
}

export const injectIframeIntoCheckout = (cashierToken, cashierKey, embedUrl) => {
    const element = document.createElement('iframe');
    const script = document.getElementsByTagName('body')[0];

    const wrapper = document.createElement('div');
    wrapper.className = 'BridgerPayWrapper';
    wrapper.id = 'bridgerpay-iframe-wrapper';

    element.src = `${embedUrl}/v2/?cashierKey=${cashierKey}&cashierToken=${cashierToken}`;
    element.height = '595px';
    element.width = '800px';
    element.title = 'BridgerPayIframe';
    element.id = 'bridgerpay-iframe-content';
    element.className = 'BridgerPayIframe';

    wrapper.appendChild(element);

    script.parentNode.insertBefore(wrapper, script);
};

export const requestBridgerPayCashierKeys = async (instance) => {
    const { showErrorNotification, bridgerpay_embed_url } = instance.props;
    const guest_cart_id = instance?.state?.cartId || getCartId();
    instance.setState({ isLoading: true });

    try {
        const {
            generateBridgerPayToken: tokens
        } = await fetchMutation(BridgerPayKeysQuery.getCashierKeysMutation(guest_cart_id));

        injectIframeIntoCheckout(
            tokens?.bridgerPayCashierToken,
            tokens?.bridgerPayCashierKey,
            bridgerpay_embed_url
        );
    } catch (e) {
        showErrorNotification(getErrorMessage(e));
    } finally {
        instance.setState({ isLoading: false });
    }
};

export const savePaymentInformation = async (args, callback, instance) => {
    const [paymentInformation] = args;
    const { paymentMethod: { code } } = paymentInformation;

    if (code !== BRIDGER_PAY_PAYMENT_CODE) {
        callback(...args);
        return;
    }

    const { totals: { is_virtual } } = instance.props;
    const {
        billing_address: {
            firstname: billingFirstName,
            lastname: billingLastName
        },
        billing_address: billingAddress
    } = paymentInformation;

    /**
     * If cart contains only virtual products then set firstname & lastname
     * from billing step into shippingAddress for user creating.
     */
    if (is_virtual) {
        instance.setState({
            shippingAddress: {
                firstname: billingFirstName,
                lastname: billingLastName
            }
        });
    }

    instance.setState({ isLoading: true, billingAddress });

    if (!isSignedIn()) {
        if (!await instance.createUserOrSaveGuest()) {
            instance.setState({ isLoading: false });

            return;
        }
    }

    await instance.saveBillingAddress(paymentInformation).then(
        /** @namespace Route/Checkout/Container/CheckoutContainer/savePaymentInformation/saveBillingAddress/then */
        async () => {
            await requestBridgerPayCashierKeys(instance);
        },
        instance._handleError
    );
};

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        bridgerpay_embed_url: state.ConfigReducer.bridgerpay_embed_url
    };
};

const { componentDidMount, componentWillUnmount } = new CheckoutPlugin();

export default {
    'Route/Checkout/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/Checkout/Container': {
        'member-function': {
            savePaymentInformation,
            componentDidMount,
            componentWillUnmount
        }
    }
};
