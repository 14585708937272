const _getStoreConfigFields = (args, callback) => [
    ...callback(...args),
    'bridgerpay_embed_url'
];

export default {
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields
        }
    }
};
