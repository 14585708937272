/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    cloneElement, useEffect, useRef, useState
} from 'react';

import getStore from 'Util/Store';

import { fireImpressionEvent, fireProductClickEvent } from '../event/list';
import { registerCallback, unregisterCallback } from '../util/observer';
import { getElPosition } from '../util/position';
import { waitForCallback } from '../util/wait';

export const VISIBILITY_TIMEOUT_MS = 150;
// ! vvv should be configured per project to keep productClick event more accurate
// ! vvv and beware that it is case sensitive
export const clickableElementsClassNames = [
    'ProductWishlistButton-Button', // add to wishlist button class name
    'ProductAttributeValue', // product attributes button class name
    'ProductCard-AddToCart', // add to cart button class name
    'ProductCompareButton-Button' // add to compare button class name
];

/** @namespace Scandiweb/Gtm/Component/ProductListEntryTracker/isClickableElement */

export const isClickableElement = (element, clickableElementsClassNames) => {
    // eslint-disable-next-line fp/no-let
    let buttonElement;
    const MAX_NUMBER_OF_TRIES = 5;
    const searchForParentButton = (element, i) => {
        if (!element) {
            return;
        }

        // eslint-disable-next-line max-len
        if (element?.tagName === 'BUTTON' || (element?.tagName === 'SPAN' && element?.classList.contains('ProductAttributeValue')) || i > MAX_NUMBER_OF_TRIES) {
            buttonElement = element;

            return;
        }
        // eslint-disable-next-line no-param-reassign
        searchForParentButton(element?.parentElement, i + 1);
    };

    searchForParentButton(element, 0);

    if (!buttonElement) {
        return;
    }
    // eslint-disable-next-line fp/no-loops, fp/no-let
    for (let a = 0; a < clickableElementsClassNames.length; a++) {
        if (buttonElement.classList.contains(clickableElementsClassNames[a])) {
            // eslint-disable-next-line consistent-return
            return true;
        }
    }
};

/** @namespace Scandiweb/Gtm/Component/ProductListEntryTracker/ProductListEntryTracker */
export function ProductListEntryTracker(props) {
    // eslint-disable-next-line react/prop-types
    const { children, product, list } = props;
    const productRef = useRef(null);
    const [position, setPosition] = useState(-1);

    useEffect(() => {
        const { current: productEl } = productRef;
        setPosition(getElPosition(productEl));
        // ^^^ Cache position, we need to do it synchronously,
        // as at the moment of click, page may re-render to new one

        const onClick = async (e) => {
            // vvv if we click on a clickable element like 'add to wish list'
            if (isClickableElement(e.target, clickableElementsClassNames)) {
                return;
            }

            await waitForCallback(
                () => getStore().getState().UrlRewritesReducer?.urlRewrite?.type
            );
            await waitForCallback(
                () => getStore().getState().CategoryReducer?.category?.id
            );
            // ^^^ We're getting these info here because it will be more accurate.

            fireProductClickEvent(
                product,
                position !== -1 ? position : getElPosition(productEl),
                // ^^^ Try cached position, else calculate
                list,
                window.location.pathname,
                getStore().getState().UrlRewritesReducer,
                getStore().getState().CategoryReducer?.category
            );
        };

        const visibilityTimeout = setTimeout(() => {
            registerCallback(productEl, async () => {
                if (!product.id) {
                    return;
                }

                // ! vvv for these we had to have async function and it will cause some delays
                await waitForCallback(
                    () => getStore().getState().UrlRewritesReducer?.urlRewrite
                        ?.type
                );
                await waitForCallback(
                    () => getStore().getState().CategoryReducer?.category?.id
                );
                // We're getting these info here because it will be more accurate.

                fireImpressionEvent(
                    product,
                    position !== -1 ? position : getElPosition(productEl),
                    // ^^^ Try cached position, else calculate
                    list,
                    window.location.pathname,
                    getStore().getState().UrlRewritesReducer,
                    getStore().getState().CategoryReducer?.category
                );

                unregisterCallback(productEl);
            });
        }, VISIBILITY_TIMEOUT_MS);

        // third argument, useCapture: true allows productClick to trigger before the PDP redirect, otherwise the
        // redirect triggers first unmounting the component and removing the event listener before productClick executes
        productEl.addEventListener('click', onClick, true);

        return () => {
            const { current: productEl } = productRef;

            clearTimeout(visibilityTimeout);

            if (!productEl) {
                return;
            }

            unregisterCallback(productEl);

            productEl.removeEventListener('click', onClick);
        };
    }, []);

    return cloneElement(
        children,
        {
            ref: productRef,
            ...children.props
        },
        children.props.children
    );
}
