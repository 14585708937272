/**
 * Amasty Special Promotions Pro compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import BannerQuery from '../query/Banner.query';

export const addBannersToProductInterface = (args, callback, instance) => {
    const fields = callback(...args);
    const [isVariant] = args;
    const { isRedirectOnly, isSingleProduct } = instance.options;

    if (!isRedirectOnly && isSingleProduct && !isVariant) {
        fields.push(BannerQuery.getQuery());
    }

    return fields;
};

export default {
    'Query/ProductList/Query': {
        'member-function': {
            _getProductInterfaceFields: addBannersToProductInterface
        }
    }
};
