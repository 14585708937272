/**
 * Mageplaza Affiliate compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const MY_AFFILIATE = 'my-affiliate';
export const MY_AFFILIATE_HOME = 'my-affiliate-home';
export const MY_AFFILIATE_HOME_URL = '/customer/affiliate';
export const MY_AFFILIATE_SIGNUP = 'my-affiliate-signup';
export const MY_AFFILIATE_SIGNUP_URL = '/customer/affiliate/signup';
export const MY_AFFILIATE_MY_CREDIT = 'my-affiliate-my-credit';
export const MY_AFFILIATE_MY_CREDIT_URL = '/customer/affiliate/my-credit';
export const MY_AFFILIATE_REFER_FRIEND = 'my-affiliate-refer-friend';
export const MY_AFFILIATE_REFER_FRIEND_URL = '/customer/affiliate/refer';
export const MY_AFFILIATE_SETTINGS = 'my-affiliate-setting';
export const MY_AFFILIATE_SETTINGS_URL = '/customer/affiliate/setting';
export const MY_AFFILIATE_REFER_HISTORY = 'my-affiliate-refer-history';
export const MY_AFFILIATE_REFER_HISTORY_URL = '/customer/affiliate/history';
export const MY_AFFILIATE_BANNERS = 'my-affiliate-banners';
export const MY_AFFILIATE_BANNERS_URL = '/customer/affiliate/banner';
export const URL_PREFIX = 'u';

export const URL_MAP = {
    [MY_AFFILIATE_HOME_URL]: MY_AFFILIATE_HOME,
    [MY_AFFILIATE_SIGNUP_URL]: MY_AFFILIATE_SIGNUP,
    [MY_AFFILIATE_MY_CREDIT_URL]: MY_AFFILIATE_MY_CREDIT,
    [MY_AFFILIATE_REFER_FRIEND_URL]: MY_AFFILIATE_REFER_FRIEND,
    [MY_AFFILIATE_SETTINGS_URL]: MY_AFFILIATE_SETTINGS,
    [MY_AFFILIATE_REFER_HISTORY_URL]: MY_AFFILIATE_REFER_HISTORY,
    [MY_AFFILIATE_BANNERS_URL]: MY_AFFILIATE_BANNERS
};
