/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

const addGtmDimensionsToProductInterface = (args, callback) => {
    const [isVariant] = args;

    const fields = [
        ...callback(...args),
        new Field('dimensions')
    ];

    if (isVariant) {
        // only add categories to parent product
        return fields;
    }

    return [
        ...fields,
        // vvv Only add category name, avoiding breadcrumbs
        new Field('categories')
            .addField(new Field('name'))
    ];
};

const addGtmDimensionsToCartProductVariant = (args, callback) => {
    const product = callback(...args);
    product.addField(new Field('dimensions'));

    return product;
};

export default {
    'Query/ProductList/Query': {
        'member-function': {
            // vvv Cart specific product fields
            _getCartProductInterfaceFields: addGtmDimensionsToProductInterface,
            _getCartProductField: addGtmDimensionsToCartProductVariant,
            // vvv General product fields
            _getProductInterfaceFields: addGtmDimensionsToProductInterface
        }
    }
};
