/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    CLEAR_WISHLIST,
    REMOVE_ITEM_FROM_WISHLIST,
    UPDATE_ALL_PRODUCTS_IN_WISHLIST
} from 'Store/Wishlist/Wishlist.action';

import {
    fireRemoveAllWishlistItemsEvent,
    removeItemFromWishlistEventHandler,
    wishlistUpdateHandler
} from '../../event/wishlist';

const addWishlistEventHandlers = (args, callback) => {
    const [state, action] = args;
    const { customerId, type } = action;

    // vvv for clear all button on MyAccount>Wishlist
    if (type === CLEAR_WISHLIST) {
        fireRemoveAllWishlistItemsEvent(customerId);
    }

    // vvv for heart button on PLP/PDP or etc.. and 'x' button on MyAccount>Wishlist
    if (type === REMOVE_ITEM_FROM_WISHLIST) {
        const { item_id: itemId } = action;
        const { productsInWishlist } = state;

        removeItemFromWishlistEventHandler(itemId, productsInWishlist, customerId);
    }

    // vvv for '+' and '-' button on MyAccount>Wishlist
    if (type === UPDATE_ALL_PRODUCTS_IN_WISHLIST) {
        const { products: newProductsInWishlist } = action;
        const { productsInWishlist } = state;

        wishlistUpdateHandler(newProductsInWishlist, productsInWishlist, customerId);
    }

    return callback(...args);
};

export default {
    'Store/Wishlist/Reducer/WishlistReducer': {
        function: addWishlistEventHandlers
    }
};
