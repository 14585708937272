import { decodeString } from 'Util/Common';

/** @namespace BridgerpayPayments/Util/SearchParam/getSearchParam */
export const getSearchParam = (variable, queryString) => {
    const query = decodeString(queryString);
    const vars = query.split('&');

    return vars.reduce((acc, item) => {
        const splitIdx = item.indexOf('=');
        const [k, v] = [item.slice(0, splitIdx), item.slice(splitIdx + 1)];

        return k === variable ? v.replace(/=/g, ':') : acc;
    }, false);
};
