/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

// vvv Event was causing other form fields to validate
const preventDefault = (args, callback) => {
    const event = args[0];

    event.preventDefault();
    callback(...args);
};

export default {
    'Component/CarouselScrollArrow/Container': {
        'member-function': {
            onClick: preventDefault
        }
    }

};
