/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'Util/Store';

import { waitForCallback } from '../util/wait';
import { getProductData } from './product';

/** @namespace Scandiweb/Gtm/Data/Cart/getCartItemData */
export const getCartItemData = async (item) => {
    const {
        quantity,
        prices: {
            price: {
                value
            } = {}
        } = {},
        product,
        lottery_options,
        sweepstake_options
    } = item;

    if (Object.keys(lottery_options ?? {}).length) {
        const {
            product: {
                lottery_game_data: {
                    lotterygame: {
                        drawOptions: {
                            single: {
                                costPerLine
                            } = {}
                        } = {}
                    } = {}
                } = {}
            } = {}
        } = item;
        const { draws, numbers } = lottery_options;
        const numberOfTickets = numbers.length * draws;
        const pricePerTicket = value / numberOfTickets;
        const formattedPrice = Number(pricePerTicket.toFixed(2));
        const discount = Number((costPerLine - formattedPrice).toFixed(2));

        if (discount === 0) {
            return {
                ...await getProductData(product),
                price: costPerLine,
                quantity: numberOfTickets * quantity,
                item_variant: (draws === 1) ? 'Single Draw' : `Multiple Draws - ${draws} draws`
            };
        }

        return {
            ...await getProductData(product),
            price: costPerLine,
            discounted_price: formattedPrice,
            discount,
            quantity: numberOfTickets * quantity,
            item_variant: (draws === 1) ? 'Single Draw' : `Multiple Draws - ${draws} draws`
        };
    }

    if (Object.keys(sweepstake_options ?? {}).length) {
        const {
            product: {
                sweepstake_game_data: {
                    sweepstakegame: {
                        drawOptions: {
                            single: {
                                costPerLine
                            } = {}
                        } = {}
                    } = {}
                } = {}
            } = {}
        } = item;
        const { number_of_entries } = sweepstake_options;
        const pricePerEntry = value / number_of_entries;
        const formattedPrice = Number(pricePerEntry.toFixed(2));
        const discount = Number((costPerLine - formattedPrice).toFixed(2));

        if (discount === 0) {
            return {
                ...await getProductData(product),
                price: costPerLine,
                quantity: number_of_entries
            };
        }

        return {
            ...await getProductData(product),
            price: costPerLine,
            discounted_price: formattedPrice,
            discount,
            quantity: number_of_entries
        };
    }

    return {
        ...await getProductData(product),
        price: value,
        quantity
    };
};

/** @namespace Scandiweb/Gtm/Data/Cart/getCartItemsData */
export const getCartItemsData = async () => {
    // vvv Wait for cart id
    await waitForCallback(() => getStore().getState().CartReducer?.cartTotals?.id);

    const {
        cartTotals: {
            items = []
        }
    } = getStore().getState().CartReducer;

    if (items.length === 0) {
        return {};
    }

    return Promise.all(items.map(getCartItemData));
};

/** @namespace Scandiweb/Gtm/Data/Cart/getRemoveFromCartData */
export const getRemoveFromCartData = async (item, currencyCode) => ({
    ecommerce: {
        currencyCode,
        // ^^^ We can not read currencyCode via getStore (Redux limitation) => forced to pass
        items: [await getCartItemData(item)]
    }
});

/** @namespace Scandiweb/Gtm/Data/Cart/getAddToCartData */
export const getAddToCartData = async (item, currencyCode) => ({
    ecommerce: {
        currencyCode,
        // ^^^ We can not read currencyCode via getStore (Redux limitation) => forced to pass
        items: [await getCartItemData(item)]
    }
});

/** @namespace Scandiweb/Gtm/Data/Cart/getViewCartData */
export const getViewCartData = async (items) => ({
    items: await Promise.all(items.map(async (item) => getCartItemData(item)))

});
