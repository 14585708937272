/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './AppliedGiftCards.style';

/** @namespace Scandiweb/AmastyGiftCards/Component/AppliedGiftCards/Component */
export class AppliedGiftCardsComponent extends PureComponent {
    static propTypes = {
        handleRemove: PropTypes.func.isRequired,
        code: PropTypes.string.isRequired
    };

    renderAppliedCodes() {
        const { handleRemove, code } = this.props;

        return (
            <div block="AppliedGiftCards">
                <span block="AppliedGiftCards" elem="AppliedCode">
                    { code }
                </span>
                <button block="AppliedGiftCards" elem="LinkButton" onClick={ handleRemove }>
                    { __('Remove') }
                </button>
            </div>
        );
    }

    render() {
        return (
            <div block="AppliedGiftCards">
                { this.renderAppliedCodes() }
            </div>
        );
    }
}

export default AppliedGiftCardsComponent;
