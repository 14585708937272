/**
 * Magefan Blog compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const SPECIAL_OFFERS_CAROUSEL = 'SpecialOffersCarousel';
export const NEWS_CAROUSEL = 'NewsCarousel';
export const PUBLISH_TIME_SORT_FIELD = 'publish_time';
export const DESCENDING_SORT_OPTION = 'DESC';
export const SPECIAL_OFFER_SLIDES_PER_VIEW = {
    initial: 1.5,
    480: 2
};
export const DEFAULT_SLIDES_PER_VIEW = {
    initial: 2.15,
    480: 3.2
};
export const MOBILE_SPACE_BETWEEN = 16;
export const DEFAULT_SPACE_BETWEEN = 30;
