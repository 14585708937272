/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PRODUCT_TYPE from 'Component/Product/Product.config';

import {
    GTM_EVENT_KEY_SELECT_ITEM,
    GTM_EVENT_KEY_VIEW_ITEM_LIST
} from '../../util/events';
import { pushToDataLayer } from '../../util/push';

const viewItemList = (args, callback) => {
    const gameData = args[0];
    const {
        config: {
            carousel: {
                gameList
            } = {}
        } = {}
    } = gameData || {};

    if (!gameList || !gameList.length) {
        return callback(...args);
    }

    const items = gameList.map((item, index) => {
        const { brandLotteryId, name } = item;

        return {
            item_id: brandLotteryId.toString(),
            item_name: name,
            item_category: PRODUCT_TYPE.lottery,
            index
        };
    });
    const [{ lotteryCurrencyCode }] = gameList;

    pushToDataLayer({
        event: GTM_EVENT_KEY_VIEW_ITEM_LIST,
        ecommerce: {
            item_list_name: 'Cards Carousel Widget',
            item_list_id: 'cards_carousel_widget',
            currency: lotteryCurrencyCode,
            items
        }
    });

    return callback(...args);
};

const selectItem = (args, callback) => {
    const brandLotteryId = args[0];
    const item_name = args[1];
    const currency = args[2];

    pushToDataLayer({
        event: GTM_EVENT_KEY_SELECT_ITEM,
        ecommerce: {
            item_list_name: 'Cards Carousel Widget',
            item_list_id: 'cards_carousel_widget',
            currency,
            items: [{
                item_id: brandLotteryId.toString(),
                item_name,
                item_category: PRODUCT_TYPE.lottery
            }]
        }
    });

    return callback(...args);
};

export default {
    'Component/CardsCarouselWidget/Container': {
        'member-function': {
            _customizeGameConfig: viewItemList,
            onCarouselItemClick: selectItem
        }
    }
};
