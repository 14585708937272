/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';

import './SocialLoginButton.style';

/** @namespace Scandiweb/MageplazaSocialLogin/Component/SocialLoginButton/Component */
export class SocialLoginButtonComponent extends PureComponent {
    static propTypes = {
        label: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        icon: PropTypes.string
    };

    static defaultProps = {
        icon: ''
    };

    renderButton() {
        const { type, onClick, icon } = this.props;

        return (
            <div block="SocialLoginButton">
                <button
                  block="SocialLoginButton"
                  mods={ { type, hasIcon: !!icon } }
                  onClick={ onClick }
                >
                    { this.renderContent() }
                </button>
            </div>
        );
    }

    /**
     * Added helper function to render icon if available
     */
    renderContent() {
        const { label, icon } = this.props;

        if (icon) {
            return (
                <Image
                  mix={ { block: 'SocialLoginButton', elem: 'Icon' } }
                  src={ icon || '' }
                  ratio="custom"
                  objectFit="cover"
                />
            );
        }

        return label;
    }

    render() {
        return (
            <div block="SocialLoginButton">
                { this.renderButton() }
            </div>
        );
    }
}

export default SocialLoginButtonComponent;
