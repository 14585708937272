/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ExpandableContent from 'Component/ExpandableContent';

import GiftCardInput from '../GiftCardInput';

import './GiftCardExpandable.style';

/** @namespace Scandiweb/AmastyGiftCards/Component/GiftCardExpandable/Component */
export class GiftCardExpandableComponent extends PureComponent {
    static propTypes = {
        mix: PropTypes.shape({
            block: PropTypes.string,
            elem: PropTypes.string
        })
    };

    static defaultProps = {
        mix: {}
    };

    render() {
        const { mix } = this.props;

        return (
            <div block="GiftCardExpandable">
                <ExpandableContent
                  heading={ __('Have a Gift Card?') }
                  mix={ mix }
                  isArrow
                >
                    <GiftCardInput isExpandable mix={ mix } />
                </ExpandableContent>
            </div>
        );
    }
}

export default GiftCardExpandableComponent;
