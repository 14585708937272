/**
 * Amasty Special Promotions Pro compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastySpecialPromotionsPro/Query/Banner/Query */
export class BannerQuery {
    getQuery() {
        return new Field('banners')
            .addFieldList(this._getBannersList());
    }

    _getBannersList() {
        return [
            'image',
            'link',
            'altText',
            'hoverText',
            'position'
        ];
    }
}

export default new BannerQuery();
