/**
 * Amasty Special Promotions Pro compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import DiscountRule from './DiscountRule.component';

/** @namespace Scandiweb/AmastySpecialPromotionsPro/Component/DiscountRule/Container */
export class DiscountRuleContainer extends PureComponent {
    static propTypes = {
        price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        currency: PropTypes.string,
        itemsQty: PropTypes.number,
        title: PropTypes.string.isRequired,
        coupon_code: PropTypes.string,
        amastyDiscounts: PropTypes.arrayOf(
            PropTypes.shape({
                rule_id: PropTypes.number.isRequired,
                discount_amount: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired
            })
        ),
        isSuccessPage: PropTypes.bool
    };

    static defaultProps = {
        itemsQty: 0,
        coupon_code: '',
        currency: 'USD',
        amastyDiscounts: [],
        isSuccessPage: false
    };

    containerFunctions = {
        handleShowAllButtonClick: this.handleShowAllButtonClick.bind(this)
    };

    componentDidMount() {
        if (this.expandableRef.current) {
            this.getExpandableContentHeight();
        }
    }

    /**
     * Make component recalculate the height only when rules count changes
     * Moved button to parent component
     */
    componentDidUpdate(prevProps, prevState) {
        const { amastyDiscounts: prevAmastyDiscounts } = prevProps;
        const { amastyDiscounts: nextAmastyDiscounts } = this.props;

        const { isOpen: prevIsOpen } = prevState;

        if (prevAmastyDiscounts !== nextAmastyDiscounts) {
            if (this.expandableRef.current) {
                this.getExpandableContentHeight(prevIsOpen);
            }
        }

        const { isExpanding } = this.state;

        if (isExpanding) {
            const ONE_SECOND_IN_MS = 1000;
            const transitionDurationCSStoMS = window
                .getComputedStyle(this.expandableRef.current)
                .getPropertyValue('transition-duration')
                .slice(0, -1) * ONE_SECOND_IN_MS;

            setTimeout(
                () => this.setState({ isExpanding: false }),
                transitionDurationCSStoMS
            );
        }
    }

    __construct(props) {
        super.__construct(props);

        this.expandableRef = createRef();

        this.state = {
            isExpanding: false,
            expandableContentHeight: 'auto',
            isOpen: false
        };
    }

    handleShowAllButtonClick() {
        const { isExpanding } = this.state;

        if (!isExpanding) {
            this.setState(({ isOpen }) => ({ isOpen: !isOpen, isExpanding: true }));
        }
    }

    /**
     *  Persist state of expandable context during rule length change
     */
    getExpandableContentHeight(wasOpen = false) {
        this.setState({ isOpen: true, expandableContentHeight: 'auto' }, () => {
            if (this.expandableRef.current) {
                this.setState({
                    expandableContentHeight: this.expandableRef.current.getBoundingClientRect().height,
                    isOpen: wasOpen
                });
            }
        });
    }

    containerProps() {
        const { isOpen, expandableContentHeight } = this.state;
        const {
            price,
            title,
            coupon_code,
            currency,
            amastyDiscounts,
            isSuccessPage
        } = this.props;

        return {
            isOpen,
            price,
            title,
            coupon_code,
            currency,
            amastyDiscounts,
            expandableRef: this.expandableRef,
            expandableContentHeight,
            isSuccessPage
        };
    }

    render() {
        return <DiscountRule { ...this.containerProps() } { ...this.containerFunctions } />;
    }
}

export default DiscountRuleContainer;
