/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { lazy } from 'react';

import {
    SECOND_SECTION
} from 'Type/Account.type';

import {
    GIFT_CARDS,
    GIFTCARD_PURCHASES,
    GIFTCARD_PURCHASES_URL,
    GIFTCARDS_URL,
    RECIEVED_GIFTCARDS,
    RECIEVED_GIFTCARDS_URL
} from '../../config';

export const MyAccountGiftCards = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account-address" */
    '../../component/MyAccountGiftCards'
));

export const MyAccountRecievedGiftCards = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account-address" */
    '../../component/MyAccountRecievedGiftCards'
));

const addGiftCardsToRenderMap = (originalMember) => {
    const giftCardMap = {
        [GIFT_CARDS]: MyAccountGiftCards,
        [GIFTCARD_PURCHASES]: MyAccountGiftCards,
        [RECIEVED_GIFTCARDS]: MyAccountRecievedGiftCards
    };

    return { ...originalMember, ...giftCardMap };
};

const addGiftCardsToTabContent = (args, callback, instance) => {
    const {
        activeTab,
        location: { pathname }
    } = instance.props;

    if (
        activeTab === GIFT_CARDS
        && pathname.includes(GIFTCARDS_URL)
    ) {
        return instance.renderMap[GIFT_CARDS];
    }

    if (
        activeTab === GIFTCARD_PURCHASES
        && pathname.includes(GIFTCARD_PURCHASES_URL)
    ) {
        return instance.renderMap[GIFTCARD_PURCHASES];
    }

    if (
        activeTab === RECIEVED_GIFTCARDS
        && pathname.includes(RECIEVED_GIFTCARDS_URL)
    ) {
        return instance.renderMap[RECIEVED_GIFTCARDS];
    }

    return callback(...args);
};

export default {
    'Route/MyAccount/Component': {
        'member-property': {
            renderMap: addGiftCardsToRenderMap
        },
        'member-function': {
            getTabContent: addGiftCardsToTabContent
        }
    }
};
