/**
 * Mageplaza Affiliate compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

export const AffiliateConfigType = PropTypes.shape({
    isSignedUp: PropTypes.bool,
    customerReferBy: PropTypes.string,
    isEnableTermsAndConditions: PropTypes.bool,
    termsAndConditionsTitle: PropTypes.string,
    termsAndConditionsHtml: PropTypes.string,
    isCheckedEmailNotification: PropTypes.bool,
    termsAndConditionsCheckboxText: PropTypes.string,
    defaultPage: PropTypes.string,
    codeLength: PropTypes.number,
    isActiveAffiliateAccount: PropTypes.bool,
    couponPrefix: PropTypes.string,
    referringPage: PropTypes.string,
    sharingUrl: PropTypes.string,
    sharingParam: PropTypes.string,
    sharingEmail: PropTypes.string,
    sharingCode: PropTypes.string,
    defaultEmailSubject: PropTypes.string,
    emailContent: PropTypes.string,
    availableBalance: PropTypes.number,
    emailNotification: PropTypes.bool,
    isDiscountCodeFieldVisible: PropTypes.bool,
    affiliateEnableBanner: PropTypes.bool,
    isEnableReferHistory: PropTypes.bool
});

export const AffiliateCampaign = PropTypes.shape({
    commission: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    discountDescription: PropTypes.string,
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
    couponCode: PropTypes.string
});

export const AffiliateCampaignData = PropTypes.shape({
    campaigns: PropTypes.arrayOf(AffiliateCampaign),
    hasCouponCode: PropTypes.bool,
    couponPrefix: PropTypes.string
});

export const AffiliateTransaction = PropTypes.shape({
    number: PropTypes.string,
    title: PropTypes.string,
    amount: PropTypes.string,
    customerOrdered: PropTypes.string,
    status: PropTypes.string,
    createdDate: PropTypes.string
});

export const AffiliateTransactionData = PropTypes.shape({
    transactions: PropTypes.arrayOf(AffiliateTransaction),
    availableBalance: PropTypes.number,
    holdingBalance: PropTypes.number,
    totalPages: PropTypes.number
});

export const AffiliateReferHistoryItem = PropTypes.shape({
    id: PropTypes.string,
    customerName: PropTypes.string,
    customerEmailAddress: PropTypes.string,
    date: PropTypes.string
});

export const AffiliateReferHistoryData = PropTypes.shape({
    items: PropTypes.arrayOf(AffiliateReferHistoryItem),
    totalPages: PropTypes.number
});

export const AffiliateBanner = PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    link: PropTypes.string,
    impressions: PropTypes.number,
    clicks: PropTypes.number,
    imageUrl: PropTypes.string,
    isRelNofollow: PropTypes.bool
});

export const AffiliateBannerData = PropTypes.shape({
    banners: PropTypes.arrayOf(AffiliateBanner),
    isEnableBannerReport: PropTypes.bool
});
