/**
 * Mageplaza Affiliate compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

import MyAffiliateQuery from '../query/MyAffiliate.query';

const addAffiliateConfigField = (args, callback) => [
    ...callback(...args),
    new Field('affiliate_config')
        .addFieldList(MyAffiliateQuery.getConfigFields())
];

export default {
    'Query/MyAccount/Query': {
        'member-function': {
            _getCustomerFields: addAffiliateConfigField
        }
    }
};
