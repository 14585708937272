/**
 * Magefan Blog compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { lazy } from 'react';

import {
    NEWS_CAROUSEL,
    SPECIAL_OFFERS_CAROUSEL
} from '../component/BlogPostsWidget/BlogPostsWidget.config';

export const BlogPostsWidget = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-blog-posts" */
    '../component/BlogPostsWidget'
));

export const renderMap = (originalMembers) => ({
    ...originalMembers,
    [SPECIAL_OFFERS_CAROUSEL]: {
        component: BlogPostsWidget
    },
    [NEWS_CAROUSEL]: {
        component: BlogPostsWidget
    }
});

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            renderMap
        }
    }
};
