/**
 * Amasty Gift Cards compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const GIFTCARDS_URL = '/customer/account/gift-cards';
export const GIFT_CARDS = 'gift-cards';
export const GIFTCARD_PURCHASES = 'purchased-giftcards';
export const GIFTCARD_PURCHASES_URL = '/customer/account/purchased-gift-cards';
export const RECIEVED_GIFTCARDS = 'recieved-giftcards';
export const RECIEVED_GIFTCARDS_URL = '/customer/account/recieved-gift-cards';

export const CHECKOUT_VIEW_DROPDOWN = '1';
export const CHECKOUT_VIEW_INPUT = '0';

export const BILLING_STEP = 'BILLING_STEP';
export const GIFT_CARDS_TAB = 'giftCardsTab';
export const CART_PAGE = 'cart';
export const UNLIMITED_VALIDITY = 'unlimited';

export const GIFTCARD_AMOUNT = 'am_giftcard_amount';
export const GIFTCARD_SENDER_NAME = 'am_giftcard_sender_name';
export const GIFTCARD_RECIPIENT_NAME = 'am_giftcard_recipient_name';
export const GIFTCARD_RECIPIENT_EMAIL = 'am_giftcard_recipient_email';
export const GIFTCARD_MESSAGE = 'am_giftcard_message';
export const GIFTCARD_MOBILE_NUMBER = 'mobilenumber';

export const MEGABYTE_BYTE = 1000000;

export const GIFTCARD_PREVIEW_POPUP_ID = 'GiftCardEmailPreview';
